import React from "react";
import Company from "../components/Company/Company";
import Layout from "../components/Layout/Layout";

export default function CompanyPage() {
  return (
    <Layout>
      <Company />
    </Layout>
  );
}
