import { Fragment, useEffect, useState } from "react";
import { EconomicData } from "./EconomicData/EconomicData";
import { SelectData } from "./SelectData/SelectData";
import { IndustryData } from "./IndustryData/IndustryData";
import { BusinessData } from "./BusinessData/BusinessData";
import { AddDashboardData } from "../AddDashboardData/AddDashboardData";
import classes from "./dataEntry.module.css";
import { DeleteDashboardData } from "./DeleteDashboardData/DeleteDashboardData";
import { api } from "../../utils/api";

export function DataEntryPanel() {
  const [activeModel, setActiveModel] = useState(false);
  const [dashboardNumber, setDashboardNumber] = useState("");

  const [queryEconomic, setQueryEconomic] = useState();
  const [queryBusiness, setQueryBusiness] = useState();
  const [queryIndustry, setQueryIndustry] = useState();

  const [economicIndicator, setEconomicIndicator] = useState([]);
  const [industryIndicator, setIndustryIndicator] = useState([]);
  const [businessCompany, setBusinessCompany] = useState([]);

  const [reRenderData, setRerenderData] = useState(false);

  const [activeDeleteData, setActiveDeleteData] = useState(false);
  const [dataId, setDataId] = useState("");
  const [dashboardPosition, setDashboardPosition] = useState("");

  useEffect(() => {
    const getInfo = async () => {
      let ecoIndicator = [];

      // fetch list of economic indicators
      const economic_Indicator = await fetch(
        `${api}/Data/Economic_Data/listofindicators`
      );
      const economicIndicator = await economic_Indicator.json();
      await economicIndicator.map((e) =>
        ecoIndicator.push({ label: e, value: e })
      );
      setEconomicIndicator(ecoIndicator);
      reRenderData && setRerenderData(false);
    };
    getInfo();
  }, [reRenderData]);

  useEffect(() => {
    const getInfo = async () => {
      let indIndicator = [];

      // fetch list of industry indicators
      const industry_Indicator = await fetch(
        `${api}/Data/Industry_Data/listofindicators`
      );
      const industryIndicator = await industry_Indicator.json();
      await industryIndicator.map((e) =>
        indIndicator.push({ label: e, value: e })
      );
      setIndustryIndicator(indIndicator);
      reRenderData && setRerenderData(false);
    };
    getInfo();
  }, [reRenderData]);

  useEffect(() => {
    const getInfo = async () => {
      let busCompany = [];

      // fetch list of business company
      const business_Company = await fetch(
        `${api}/Data/Business_Data/listofcompanies`
      );
      const businessCompany = await business_Company.json();
      await businessCompany.map((e) => busCompany.push({ label: e, value: e }));
      setBusinessCompany(busCompany);
      reRenderData && setRerenderData(false);
    };
    getInfo();
  }, [reRenderData]);

  const handleEntryModel = () => {
    setActiveModel(!activeModel);
    setRerenderData(true);
  };

  const handleDeleteData = () => {
    setActiveDeleteData(!activeDeleteData);
    setRerenderData(true);
  };

  const selectQueryEconomic = (query) => {
    if (query) {
      setQueryEconomic(query.value);
    } else {
      setQueryEconomic(null);
    }
  };

  const selectQueryBusiness = (query) => {
    if (query) {
      setQueryBusiness(query.value);
    } else {
      setQueryBusiness(null);
    }
  };

  const selectQueryIndustry = (query) => {
    if (query) {
      setQueryIndustry(query.value);
    } else {
      setQueryIndustry(null);
    }
  };

  return (
    <Fragment>
      <DeleteDashboardData
        activeDeleteData={activeDeleteData}
        handleDeleteData={handleDeleteData}
        dashboardPosition={dashboardPosition}
        dataId={dataId}
      />
      {/* Economic Data Table ======================================*/}
      <div className={classes.AllData}>
        <h5>Economic Dashboard</h5>
        <div className={classes.selectDataLayout}>
          <SelectData
            search_by={"search by indicator"}
            options={economicIndicator}
            selectOnChange={selectQueryEconomic}
          />
        </div>
        <EconomicData
          eQuery={queryEconomic}
          setDashboardPosition={setDashboardPosition}
          setActiveDeleteData={setActiveDeleteData}
          dataId={dataId}
          setDataId={setDataId}
        />
        <button
          className={classes.buttonCreate}
          onClick={() => {
            setDashboardNumber(1);
            setActiveModel(true);
          }}
        >
          + Add Data
        </button>
        {dashboardNumber === 1 ? (
          <AddDashboardData
            active={activeModel}
            handleEntryModel={handleEntryModel}
            dashboardNumber={dashboardNumber}
          />
        ) : null}
      </div>

      {/* Industry Data Table ======================================*/}
      <div className={classes.AllData}>
        <h5>Industry Dashboard</h5>
        <div className={classes.selectDataLayout}>
          <SelectData
            search_by={"search by indicator"}
            options={industryIndicator}
            selectOnChange={selectQueryIndustry}
          />
        </div>
        <IndustryData
          iQuery={queryIndustry}
          setDashboardPosition={setDashboardPosition}
          setActiveDeleteData={setActiveDeleteData}
          dataId={dataId}
          setDataId={setDataId}
        />
        <button
          className={classes.buttonCreate}
          onClick={() => {
            setDashboardNumber(2);
            setActiveModel(true);
          }}
        >
          + Add Data
        </button>
        {dashboardNumber === 2 ? (
          <AddDashboardData
            active={activeModel}
            handleEntryModel={handleEntryModel}
            dashboardNumber={dashboardNumber}
          />
        ) : null}
      </div>

      {/* Business Data Table ======================================*/}
      <div className={classes.AllData}>
        <h5>Company Dashboard</h5>
        <div className={classes.selectDataLayout}>
          <SelectData
            search_by={"search by company"}
            options={businessCompany}
            selectOnChange={selectQueryBusiness}
          />
        </div>
        <BusinessData
          bQuery={queryBusiness}
          setDashboardPosition={setDashboardPosition}
          setActiveDeleteData={setActiveDeleteData}
          dataId={dataId}
          setDataId={setDataId}
        />
        <button
          className={classes.buttonCreate}
          onClick={() => {
            setDashboardNumber(3);
            setActiveModel(true);
          }}
        >
          + Add Data
        </button>
        {dashboardNumber === 3 ? (
          <AddDashboardData
            active={activeModel}
            handleEntryModel={handleEntryModel}
            dashboardNumber={dashboardNumber}
          />
        ) : null}
      </div>
    </Fragment>
  );
}
