import React, { Fragment } from "react";
import Select from "react-select";
export const SelectData = ({ options, selectOnChange, search_by }) => {
  if (options.lenght !== 0) {
    console.log(options);
  } else {
    console.log(options);
  }
  return (
    <Fragment>
      <Select
        maxMenuHeight={"15rem"}
        isClearable
        isSearchable
        placeholder={search_by}
        onChange={selectOnChange}
        options={options}
        isLoading={options.length === 0 ? true : false}
      />
      <br></br>
    </Fragment>
  );
};
