import axios from "axios";
import React from "react";
import { api } from "../../../utils/api";
import classes from "./DeleteProfile.module.css";

export const DeleteProfile = ({
  activeDeleteModel,
  handleDeleteModel,
  leadPosition,
  email,
}) => {
  if (activeDeleteModel) {
    document.body.classList.add(classes.activeModel);
  } else {
    document.body.classList.remove(classes.activeModel);
  }

  const handleDeleteProfile = async (email) => {
    var url = "";
    if (leadPosition === 1) {
      url = `${api}/SuperAdmin/admin/delete_superadmin/${email}`;
    } else if (leadPosition === 2) {
      url = `${api}/Admin/admin/delete_admin/${email}`;
    } else if (leadPosition === 3) {
      url = `${api}/DataOperator/operator/delete_operator/${email}`;
    }
    const response = await axios.delete(url);

    if (!response) {
      alert("something went wrong when removing profile");
    } else {
      handleDeleteModel();
    }
  };

  return activeDeleteModel ? (
    <div className={classes.model}>
      <div className={classes.overlay} onClick={handleDeleteModel}></div>
      <div className={classes.modelContent}>
        <p>Are you confirm?</p>
        <button
          className={classes.buttonDelete}
          onClick={() => {
            handleDeleteProfile(email);
          }}
        >
          Yes
        </button>
        <button className={classes.buttonCancel} onClick={handleDeleteModel}>
          X
        </button>
      </div>
    </div>
  ) : (
    ""
  );
};
