import React from "react";
import { SelectField } from "../SelectField/SelectField";
import classes from "./InputField.module.css";

export default function InputField(props) {
  return (
    <div className={classes.wrapper}>
      {props.type === "textArea" ? (
        <textarea
          disabled={props.disabled}
          required={props.required}
          type={props.type}
          placeholder={props.placeholder}
          value={props.value ? props.value : ""}
          onChange={props.onChange}
        />
      ) : props.type === "select" ? (
        <div className={classes.selectField}>
          <SelectField search_by="-- Select MOQ --" />
        </div>
      ) : props.inputUnit ? (
        <>
          <div className={classes.inputCurrency}>
            <div>{props.unit}</div>
            <input
              disabled={props.disabled}
              required={props.required}
              type={props.type}
              placeholder={props.placeholder}
              value={props.value ? props.value : ""}
              onChange={props.onChange}
            />
          </div>
        </>
      ) : (
        <input
          disabled={props.disabled}
          required={props.required}
          type={props.type}
          placeholder={props.placeholder}
          value={props.value ? props.value : ""}
          onChange={props.onChange}
        />
      )}
    </div>
  );
}
