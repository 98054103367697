import { Fragment, useEffect, useState } from "react";
import classes from "../../../DashboardTable/DashboardTable.module.css";
import EconomicDataClasses from "../EconomicData.module.css";
import { sortAscending } from "../../../../functions/SortAscending";
import { sortDescending } from "../../../../functions/SortDescending";
import {
  dataEntrySelectUpdate,
  dataEntryUpdate,
  variableToString,
} from "../../../../functions/dataEntryUpdate";
import axios from "axios";
import moment from "moment";
import { api } from "../../../../utils/api";

export function AllEconomicDataTable(props) {
  const {
    month,
    economicData,
    setDashboardPosition,
    setActiveDeleteData,
    dataId,
    setDataId,
    radio,
    getRefresh,
  } = props;

  var today = new Date();
  var dateToday = moment(today).format("D MMMM YYYY");

  const [newBudget, setNewBudget] = useState("");
  const [newCalendar, setNewCalendar] = useState("");
  const [economicIndicatorOptions, setEconomicIndicatorOptions] = useState({
    ecoUnit: "",
    ecoSource: "",
  });
  const [newJan, setNewJan] = useState("");
  const [newFeb, setNewFeb] = useState("");
  const [newMar, setNewMar] = useState("");
  const [newApr, setNewApr] = useState("");
  const [newMay, setNewMay] = useState("");
  const [newJun, setNewJun] = useState("");
  const [newJul, setNewJul] = useState("");
  const [newAug, setNewAug] = useState("");
  const [newSep, setNewSep] = useState("");
  const [newOct, setNewOct] = useState("");
  const [newNov, setNewNov] = useState("");
  const [newDec, setNewDec] = useState("");

  const [allData, setAllData] = useState({
    indicator: "",
    unit: "",
    source: "",
    budget_year: "",
    calendar_year: "",
    frequency_update: "",
    link: "",
  });

  const [id, setId] = useState("");
  const [hit, setHit] = useState(false);

  useEffect(() => {
    const getInfo = async () => {
      let ecoUnit = [];
      let ecoSource = [];

      // fetch unit
      const economic_Unit = await fetch(`${api}/Data/Economy/allunit`);
      const economicUnit = await economic_Unit.json();
      await economicUnit.map((e) => ecoUnit.push({ label: e, value: e }));

      // fetch source
      const economic_Source = await fetch(`${api}/Data/Economy/allsource`);
      const economicSource = await economic_Source.json();
      await economicSource.map((e) => ecoSource.push({ label: e, value: e }));
      setEconomicIndicatorOptions({
        ecoUnit: ecoUnit,
        ecoSource: ecoSource,
      });
    };
    getInfo();
  });

  const handleUpdate = async () => {
    if (dataId && allData.indicator) {
      const response = await axios.patch(
        `${api}/DataEntry/economic/edit_data/${dataId}`,
        {
          indicator: allData.indicator,
          unit: allData.unit,
          source: allData.source,
          budget_year:
            allData.budget_year === "None" || allData.budget_year === "nan"
              ? null
              : allData.budget_year,
          calendar_year:
            allData.calendar_year === "None" || allData.calendar_year === "nan"
              ? null
              : allData.calendar_year,
          frequency_update: allData.frequency_update,
          link: allData.link,
          budget_value:
            newBudget === "None" || newBudget === "nan" || newBudget === ""
              ? null
              : parseFloat(newBudget),
          calendar_value:
            newCalendar === "None" ||
            newCalendar === "nan" ||
            newCalendar === ""
              ? null
              : parseFloat(newCalendar),
          jan:
            newJan === "None" || newJan === "nan" ? null : parseFloat(newJan),
          feb:
            newFeb === "None" || newJan === "nan" ? null : parseFloat(newFeb),
          mar:
            newMar === "None" || newJan === "nan" ? null : parseFloat(newMar),
          apr:
            newApr === "None" || newJan === "nan" ? null : parseFloat(newApr),
          may:
            newMay === "None" || newJan === "nan" ? null : parseFloat(newMay),
          jun:
            newJun === "None" || newJan === "nan" ? null : parseFloat(newJun),
          jul:
            newJul === "None" || newJan === "nan" ? null : parseFloat(newJul),
          aug:
            newAug === "None" || newJan === "nan" ? null : parseFloat(newAug),
          sep:
            newSep === "None" || newJan === "nan" ? null : parseFloat(newSep),
          oct:
            newOct === "None" || newJan === "nan" ? null : parseFloat(newOct),
          nov:
            newNov === "None" || newJan === "nan" ? null : parseFloat(newNov),
          dec:
            newDec === "None" || newJan === "nan" ? null : parseFloat(newDec),
          last_checked: dateToday,
          last_updated: dateToday,
        }
      );
      if (response) {
        setHit(false);
        getRefresh();
      } else {
        alert("Data is unable to update!");
      }
    } else {
      alert("Data id or indicator not found!");
    }
  };

  return (
    <Fragment>
      <table id="ecoAll">
        <thead>
          <tr>
            <th>Sl.</th>
            <th>Indicator</th>
            <th>Unit</th>
            <th>Source</th>
            {radio === "AllEco" || radio === "BudgetEco" ? (
              <>
                <th>
                  Budget year
                  <div className={EconomicDataClasses.sortButton}>
                    <button onClick={() => sortAscending("ecoAll", 4)}>
                      ↓
                    </button>
                    <button onClick={() => sortDescending("ecoAll", 4)}>
                      ↑
                    </button>
                  </div>
                </th>
                <th>Budget value</th>
              </>
            ) : (
              ""
            )}
            {radio === "AllEco" || radio === "CalendarEco" ? (
              <>
                <th>
                  Calendar year{" "}
                  <div className={EconomicDataClasses.sortButton}>
                    <button onClick={() => sortAscending("ecoAll", 6)}>
                      ↓
                    </button>
                    <button onClick={() => sortDescending("ecoAll", 6)}>
                      ↑
                    </button>
                  </div>
                </th>
                <th>Calendar value</th>
                {month.map((e, key) => (
                  <th key={key}>{e}</th>
                ))}
              </>
            ) : (
              ""
            )}
            <th>Frequency Update</th>
            <th>Last Updated</th>
            <th>Link</th>
            <th>Last Checked</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {economicData.map((e, sl) => (
            <tr key={e.id}>
              <td>{sl + 1}</td>
              <td>
                {e.Indicator === "nan" || e.Indicator === "None"
                  ? ""
                  : e.Indicator}
              </td>
              <td>{e.Unit === "nan" || e.Unit === "None" ? "" : e.Unit}</td>
              <td>
                {e.Source === "nan" || e.Source === "None" ? "" : e.Source}
              </td>
              {radio === "AllEco" || radio === "BudgetEco" ? (
                <>
                  <td>
                    {e["Budget Year"] === "nan" || e["Budget Year"] === "None"
                      ? ""
                      : e["Budget Year"]}
                  </td>
                  <td>
                    {e["Calendar Year"] === "nan" ||
                    e["Calendar Year"] === "None"
                      ? dataEntryUpdate(
                          hit,
                          sl,
                          id,
                          e["Budget Value"],
                          "set new budget value",
                          newBudget,
                          setNewBudget
                        )
                      : ""}
                  </td>
                </>
              ) : null}
              {radio === "AllEco" || radio === "CalendarEco" ? (
                <>
                  <td>
                    {e["Calendar Year"] === "nan" ||
                    e["Calendar Year"] === "None"
                      ? ""
                      : e["Calendar Year"]}
                  </td>
                  <td>
                    {e["Year"] !== "nan" || e["Year"] !== "None"
                      ? dataEntryUpdate(
                          hit,
                          sl,
                          id,
                          e["Calendar Value"],
                          "set new calendar value",
                          newCalendar,
                          setNewCalendar
                        )
                      : ""}
                  </td>
                  {month.map((m, key) => (
                    <td key={key}>
                      {/* {e[m] === "nan" || e[m] === "None" ? "" : e[m]} */}
                      {e["Year"] !== "nan" || e["Year"] !== "None"
                        ? dataEntryUpdate(
                            hit,
                            sl,
                            id,
                            e[m],
                            "set new value",
                            variableToString({ newJan }) === `new${m}`
                              ? newJan
                              : variableToString({ newFeb }) === `new${m}`
                              ? newFeb
                              : variableToString({ newMar }) === `new${m}`
                              ? newMar
                              : variableToString({ newApr }) === `new${m}`
                              ? newApr
                              : variableToString({ newMay }) === `new${m}`
                              ? newMay
                              : variableToString({ newJun }) === `new${m}`
                              ? newJun
                              : variableToString({ newJul }) === `new${m}`
                              ? newJul
                              : variableToString({ newAug }) === `new${m}`
                              ? newAug
                              : variableToString({ newSep }) === `new${m}`
                              ? newSep
                              : variableToString({ newOct }) === `new${m}`
                              ? newOct
                              : variableToString({ newNov }) === `new${m}`
                              ? newNov
                              : variableToString({ newDec }) === `new${m}`
                              ? newDec
                              : "something went wrong",
                            variableToString({ setNewJan }) === `setNew${m}`
                              ? setNewJan
                              : variableToString({ setNewFeb }) === `setNew${m}`
                              ? setNewFeb
                              : variableToString({ setNewMar }) === `setNew${m}`
                              ? setNewMar
                              : variableToString({ setNewApr }) === `setNew${m}`
                              ? setNewApr
                              : variableToString({ setNewMay }) === `setNew${m}`
                              ? setNewMay
                              : variableToString({ setNewJun }) === `setNew${m}`
                              ? setNewJun
                              : variableToString({ setNewJul }) === `setNew${m}`
                              ? setNewJul
                              : variableToString({ setNewAug }) === `setNew${m}`
                              ? setNewAug
                              : variableToString({ setNewSep }) === `setNew${m}`
                              ? setNewSep
                              : variableToString({ setNewOct }) === `setNew${m}`
                              ? setNewOct
                              : variableToString({ setNewNov }) === `setNew${m}`
                              ? setNewNov
                              : variableToString({ setNewDec }) === `setNew${m}`
                              ? setNewDec
                              : "something went wrong"
                          )
                        : ""}
                    </td>
                  ))}
                </>
              ) : null}
              <td>{e["Frequency Update"]}</td>
              <td>{e["Last Updated"]}</td>
              <td>{e["Link"]}</td>
              <td>{e["Last Checked"]}</td>
              <td>
                <button
                  className={classes.buttonUpdate}
                  onClick={() => {
                    if (hit) {
                      if (sl === id) {
                        handleUpdate();
                      }
                    } else {
                      setId(sl);
                      setHit(true);
                      setDataId(e.id);
                      setAllData({
                        indicator: e.Indicator,
                        unit: e.Unit,
                        source: e.Source,
                        budget_year: e["Budget Year"],
                        calendar_year: e["Calendar Year"],
                        frequency_update: e["Frequency Update"],
                        link: e.Link,
                      });

                      setNewBudget(e["Budget Value"]);
                      setNewCalendar(e["Calendar Value"]);
                      setNewJan(e.Jan);
                      setNewFeb(e.Feb);
                      setNewMar(e.Mar);
                      setNewApr(e.Apr);
                      setNewMay(e.May);
                      setNewJun(e.Jun);
                      setNewJul(e.Jul);
                      setNewAug(e.Aug);
                      setNewSep(e.Sep);
                      setNewOct(e.Oct);
                      setNewNov(e.Nov);
                      setNewDec(e.Dec);
                    }
                  }}
                >
                  {hit ? (sl === id ? "Save" : "Edit") : "Edit"}
                </button>
                {hit ? (
                  sl === id ? (
                    <button
                      className={classes.buttonDelete}
                      onClick={(e) => {
                        setHit(false);
                      }}
                    >
                      Cancel
                    </button>
                  ) : (
                    <button
                      className={classes.buttonDelete}
                      onClick={() => {
                        setDashboardPosition(1);
                        setActiveDeleteData(true);
                        setDataId(e.id);
                      }}
                    >
                      Delete
                    </button>
                  )
                ) : (
                  <button
                    className={classes.buttonDelete}
                    onClick={() => {
                      setDashboardPosition(1);
                      setActiveDeleteData(true);
                      setDataId(e.id);
                    }}
                  >
                    Delete
                  </button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Fragment>
  );
}
