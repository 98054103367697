import React from "react";
import { TiTick } from "react-icons/ti";
import classes from "./AlertBox.module.css";

export default function AlertBox(props) {
  return (
    <div className={`${classes.notification} ${classes.animateOpen}`}>
      <TiTick className={classes.tickIcon} /> {props.alertMessage}
    </div>
  );
}
