import React, { Fragment } from "react";
import CreatableSelect from "react-select/creatable";

export const SelectDataEntry = ({
  options,
  selectOnChange,
  search_by,
  handleInputChange,
}) => {
  return (
    <Fragment>
      <CreatableSelect
        maxMenuHeight={"10rem"}
        isSearchable
        isClearable
        placeholder={search_by}
        options={options}
        onChange={selectOnChange}
        onInputChange={handleInputChange}
      />
      <br></br>
    </Fragment>
  );
};
