// import React, { useContext } from "react";
import { Fragment, useEffect, useState } from "react";
import { SelectData } from "../../DataEntryPanel/SelectData/SelectData";
import { SelectDataEntry } from "../SelectDataEntry/SelectDataEntry";
import classes from "../AddDashboardData.module.css";
// import { StateProvider, StateContext } from "../../../context/state";

export function AddEconomicData(props) {
  const {
    api,
    month,
    indicator,
    budgetYear,
    budgetValue,
    calendarYear,
    calendarValue,
    jan,
    feb,
    mar,
    apr,
    may,
    jun,
    jul,
    aug,
    sep,
    oct,
    nov,
    dec,
    lastUpdate,
    link,
    lastChecked,
    setIndicator,
    setUnit,
    setSource,
    setBudgetYear,
    setBudgetValue,
    setCalendarYear,
    setCalendarValue,
    setJan,
    setFeb,
    setMar,
    setApr,
    setMay,
    setJun,
    setJul,
    setAug,
    setSep,
    setOct,
    setNov,
    setDec,
    setFrequencyUpdate,
    setLink,
  } = props;

  const [economicIndicator, setEconomicIndicator] = useState([]);
  const [economicUnit, setEconomicUnit] = useState([]);
  const [economicSource, setEconomicSource] = useState([]);
  const [budgetYearOptions, setBudgetYearOptions] = useState([]);
  const [calendarYearOptions, setCalendarYearOptions] = useState([]);
  const [frequencyUpdateOptions, setFrequencyUpdateOptions] = useState([]);
  const [restrict, setRestrict] = useState(3);
  const [checkBudgetValue, setCheckBudgetValue] = useState("");
  const [checkCalendarValue, setCheckCalendarValue] = useState("");

  useEffect(() => {
    const getInfo = async () => {
      let ecoIndicator = [];
      let ecoUnit = [];
      let ecoSource = [];
      let budgetyear = [];
      let calendaryear = [];

      const frequencyUpdateOptionsAll = [
        { value: "Monthly", label: "Monthly" },
        { value: "Quarterly", label: "Quarterly" },
        { value: "Yearly", label: "Yearly" },
        { value: "Budget Yearly", label: "Budget Yearly" },
      ];
      setFrequencyUpdateOptions(frequencyUpdateOptionsAll);

      for (let i = 2000; i < 2070; i++) {
        const x = i;
        const y = i + 1;
        budgetyear.push({ label: x + "-" + y, value: x + "-" + y });
      }
      for (let i = 2000; i < 2050; i++) {
        const m = i;
        const n = i;
        calendaryear.push({ label: m, value: n });
      }

      if (!calendarYear && budgetYear) {
        setCalendarYearOptions([]);
        setRestrict(1);
      } else if (calendarYear && !budgetYear) {
        setBudgetYearOptions([]);
        setRestrict(2);
      } else {
        setBudgetYearOptions(budgetyear);
        setCalendarYearOptions(calendaryear);
        setRestrict(3);
      }
      // fetch list of economic indicators
      const economic_Indicator = await fetch(
        `${api}/Data/Economic_Data/listofindicators`
      );
      const economicIndicator = await economic_Indicator.json();
      await economicIndicator.map((e) =>
        ecoIndicator.push({ label: e, value: e })
      );
      setEconomicIndicator(ecoIndicator);

      if (indicator) {
        // fetch unit
        const economic_Unit = await fetch(`${api}/Data/Economy/allunit`);
        const economicUnit = await economic_Unit.json();

        // fetch source
        const economic_Source = await fetch(`${api}/Data/Economy/allsource`);
        const economicSource = await economic_Source.json();

        // fetch unit by indicator
        const economic_Unit_by_indicator = await fetch(
          `${api}/Data/Economic/listofunit/${indicator}`
        );
        const economicUnit_by_indicator =
          await economic_Unit_by_indicator.json();

        // fetch source by indicator
        const economic_Source_by_indicator = await fetch(
          `${api}/Data/Economic/listofsource/${indicator}`
        );
        const economicSource_by_indicator =
          await economic_Source_by_indicator.json();

        // fetch budget value by indicator and budget year
        if (indicator && budgetYear) {
          const check_BudgetValue = await fetch(
            `${api}/DataCheck/Economic/searchdata/${indicator}/${budgetYear}`
          );
          const checkBudget_Value = await check_BudgetValue.json();
          setCheckBudgetValue(checkBudget_Value);
        }

        // fetch calendar value by indicator and calendar year
        if (indicator && calendarYear) {
          const check_CalendarValue = await fetch(
            `${api}/DataCheck/Economic/searchdata2/${indicator}/${calendarYear}`
          );
          const checkCalendar_Value = await check_CalendarValue.json();
          setCheckCalendarValue(checkCalendar_Value);
        }

        if (
          economicUnit_by_indicator.length !== 0 &&
          economicSource_by_indicator.length !== 0
        ) {
          // set unit
          await economicUnit_by_indicator.map((e) =>
            ecoUnit.push({ label: e, value: e })
          );
          setEconomicUnit(ecoUnit);

          // set source
          await economicSource_by_indicator.map((e) =>
            ecoSource.push({ label: e, value: e })
          );
          setEconomicSource(ecoSource);
        } else if (
          economicUnit_by_indicator.length === 0 &&
          economicSource_by_indicator.length === 0 &&
          economicUnit.length !== 0 &&
          economicSource.length !== 0
        ) {
          // set unit
          await economicUnit.map((e) => ecoUnit.push({ label: e, value: e }));
          setEconomicUnit(ecoUnit);

          // set source
          await economicSource.map((e) =>
            ecoSource.push({ label: e, value: e })
          );
          setEconomicSource(ecoSource);
        } else {
          setEconomicUnit([]);
          setUnit("");
          setEconomicSource([]);
          setSource("");
        }
      }
    };
    getInfo();
  }, [
    indicator,
    budgetYear,
    calendarYear,
    setSource,
    setUnit,
    checkBudgetValue,
    checkCalendarValue,
    api,
  ]);

  // const selectQueryEconomic = (query) => {
  // query ? setIndicator(query.value) : console.log(null);
  //   if (query) {
  //     console.log("select query economic");
  //     console.log(query);
  //     setIndicator(query.value);
  //     console.log(indicator);
  //   } else {
  //     console.log(null);
  //   }
  // };

  // const handleInputChange = (inputValue) => {
  //   console.log("Handle input change:");
  //   if (inputValue) {
  //     console.log(inputValue);
  //     setIndicator(inputValue);
  //   }
  //   console.log(indicator);
  // };

  return (
    <>
      <table>
        <thead>
          <tr>
            <th>Indicator</th>
            <th>Unit</th>
            <th>Source</th>
            {restrict === 1 || restrict === 3 ? <th>Budget Year</th> : ""}
            {restrict === 1 || restrict === 3 ? <th>Budget Value</th> : ""}
            {restrict === 2 || restrict === 3 ? <th>Calendar Year</th> : ""}
            {restrict === 2 || restrict === 3 ? <th>Calendar Value</th> : ""}
            {restrict === 2 || restrict === 3
              ? month.map((e, key) => <th key={key}>{e}</th>)
              : ""}
            <th>Frequency Update</th>
            <th>Last Update</th>
            <th>Link</th>
            <th>Last Checked</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <SelectDataEntry
                search_by={"Indicator"}
                options={economicIndicator}
                selectOnChange={(e) =>
                  e ? setIndicator(e.value) : setIndicator(null)
                }
                handleInputChange={(e) => {
                  if (e) {
                    setIndicator(e);
                  }
                }}
              />
            </td>
            <td>
              <SelectDataEntry
                search_by={"Unit"}
                options={economicUnit}
                selectOnChange={(e) => (e ? setUnit(e.value) : setUnit(null))}
                handleInputChange={(e) => {
                  if (e) {
                    setUnit(e);
                  }
                }}
              />
            </td>
            <td>
              <SelectDataEntry
                search_by={"Source"}
                options={economicSource}
                selectOnChange={(e) =>
                  e ? setSource(e.value) : setSource(null)
                }
                handleInputChange={(e) => {
                  if (e) {
                    setSource(e);
                  }
                }}
              />
            </td>
            {restrict === 1 || restrict === 3 ? (
              <td>
                <SelectData
                  search_by={"Budget Year"}
                  options={budgetYearOptions}
                  selectOnChange={(e) =>
                    e ? setBudgetYear(e.value) : setBudgetYear(null)
                  }
                />
              </td>
            ) : (
              ""
            )}
            {restrict === 1 || restrict === 3 ? (
              <td>
                <input
                  className={
                    checkBudgetValue && budgetYear ? classes.redAlert : null
                  }
                  type="number"
                  placeholder="Budget Value"
                  value={budgetValue}
                  onChange={(e) => {
                    checkBudgetValue
                      ? setBudgetValue([])
                      : setBudgetValue(e.target.value);
                  }}
                />
              </td>
            ) : (
              ""
            )}
            {restrict === 2 || restrict === 3 ? (
              <td>
                <SelectData
                  search_by={"Calendar Year"}
                  options={calendarYearOptions}
                  selectOnChange={(e) =>
                    e ? setCalendarYear(e.value) : setCalendarYear(null)
                  }
                />
              </td>
            ) : (
              ""
            )}
            {restrict === 2 || restrict === 3 ? (
              <td>
                <input
                  className={
                    checkCalendarValue && calendarYear ? classes.redAlert : null
                  }
                  type="number"
                  placeholder="Calender Value"
                  value={calendarValue}
                  onChange={(e) => {
                    checkCalendarValue
                      ? setCalendarValue([])
                      : setCalendarValue(e.target.value);
                  }}
                />
              </td>
            ) : (
              ""
            )}
            {restrict === 2 || restrict === 3 ? (
              <Fragment>
                <td>
                  <input
                    className={
                      checkCalendarValue && calendarYear
                        ? classes.redAlert
                        : null
                    }
                    type="number"
                    placeholder="January"
                    value={jan}
                    onChange={(e) => {
                      checkCalendarValue && calendarYear
                        ? setJan([])
                        : setJan(e.target.value);
                    }}
                  />
                </td>
                <td>
                  <input
                    className={
                      checkCalendarValue && calendarYear
                        ? classes.redAlert
                        : null
                    }
                    type="number"
                    placeholder="February"
                    value={feb}
                    onChange={(e) => {
                      checkCalendarValue && calendarYear
                        ? setFeb([])
                        : setFeb(e.target.value);
                    }}
                  />
                </td>
                <td>
                  <input
                    className={
                      checkCalendarValue && calendarYear
                        ? classes.redAlert
                        : null
                    }
                    type="number"
                    placeholder="March"
                    value={mar}
                    onChange={(e) => {
                      checkCalendarValue && calendarYear
                        ? setMar([])
                        : setMar(e.target.value);
                    }}
                  />
                </td>
                <td>
                  <input
                    className={
                      checkCalendarValue && calendarYear
                        ? classes.redAlert
                        : null
                    }
                    type="number"
                    placeholder="April"
                    value={apr}
                    onChange={(e) => {
                      checkCalendarValue && calendarYear
                        ? setApr([])
                        : setApr(e.target.value);
                    }}
                  />
                </td>
                <td>
                  <input
                    className={
                      checkCalendarValue && calendarYear
                        ? classes.redAlert
                        : null
                    }
                    type="number"
                    placeholder="May"
                    value={may}
                    onChange={(e) => {
                      checkCalendarValue && calendarYear
                        ? setMay([])
                        : setMay(e.target.value);
                    }}
                  />
                </td>
                <td>
                  <input
                    className={
                      checkCalendarValue && calendarYear
                        ? classes.redAlert
                        : null
                    }
                    type="number"
                    placeholder="Jun"
                    value={jun}
                    onChange={(e) => {
                      checkCalendarValue && calendarYear
                        ? setJun([])
                        : setJun(e.target.value);
                    }}
                  />
                </td>
                <td>
                  <input
                    className={
                      checkCalendarValue && calendarYear
                        ? classes.redAlert
                        : null
                    }
                    type="number"
                    placeholder="July"
                    value={jul}
                    onChange={(e) => {
                      checkCalendarValue && calendarYear
                        ? setJul([])
                        : setJul(e.target.value);
                    }}
                  />
                </td>
                <td>
                  <input
                    className={
                      checkCalendarValue && calendarYear
                        ? classes.redAlert
                        : null
                    }
                    type="number"
                    placeholder="August"
                    value={aug}
                    onChange={(e) => {
                      checkCalendarValue && calendarYear
                        ? setAug([])
                        : setAug(e.target.value);
                    }}
                  />
                </td>
                <td>
                  <input
                    className={
                      checkCalendarValue && calendarYear
                        ? classes.redAlert
                        : null
                    }
                    type="number"
                    placeholder="September"
                    value={sep}
                    onChange={(e) => {
                      checkCalendarValue && calendarYear
                        ? setSep([])
                        : setSep(e.target.value);
                    }}
                  />
                </td>
                <td>
                  <input
                    className={
                      checkCalendarValue && calendarYear
                        ? classes.redAlert
                        : null
                    }
                    type="number"
                    placeholder="October"
                    value={oct}
                    onChange={(e) => {
                      checkCalendarValue && calendarYear
                        ? setOct([])
                        : setOct(e.target.value);
                    }}
                  />
                </td>
                <td>
                  <input
                    className={
                      checkCalendarValue && calendarYear
                        ? classes.redAlert
                        : null
                    }
                    type="number"
                    placeholder="November"
                    value={nov}
                    onChange={(e) => {
                      checkCalendarValue && calendarYear
                        ? setNov([])
                        : setNov(e.target.value);
                    }}
                  />
                </td>
                <td>
                  <input
                    className={
                      checkCalendarValue && calendarYear
                        ? classes.redAlert
                        : null
                    }
                    type="number"
                    placeholder="December"
                    value={dec}
                    onChange={(e) => {
                      checkCalendarValue && calendarYear
                        ? setDec([])
                        : setDec(e.target.value);
                    }}
                  />
                </td>
              </Fragment>
            ) : (
              ""
            )}
            <td>
              <SelectData
                search_by={"Frequency Update"}
                options={frequencyUpdateOptions}
                selectOnChange={(e) =>
                  e ? setFrequencyUpdate(e.value) : setFrequencyUpdate(null)
                }
              />
            </td>
            <td>
              <div className={classes.lastUpdate}>{lastUpdate}</div>
            </td>
            <td>
              <input
                type="text"
                placeholder="Link"
                value={link}
                onChange={(e) => setLink(e.target.value)}
              />
            </td>
            <td>
              <div className={classes.lastUpdate}>{lastChecked}</div>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
}
