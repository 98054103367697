import React, { useEffect, useState } from "react";
import { SelectDataEntry } from "../../AddDashboardData/SelectDataEntry/SelectDataEntry";
import classes from "./HrInformation.module.css";

export default function HrInformation(props) {
  const { hrInfo, setHrInfo } = props;
  const [opt, setOpt] = useState([]);

  useEffect(() => {
    const optAll = [
      { value: "CEO", label: "CEO" },
      { value: "MD", label: "MD" },
      { value: "SE", label: "SE" },
    ];
    setOpt(optAll);
  }, []);

  const handleInputChange = (e, index) => {
    const list = [...hrInfo];
    e
      ? (list[index][e.target.name] = e.target.value)
      : (list[index][e.target.name] = "");
    setHrInfo(list);
  };

  const handleInputChange_select = (e, index) => {
    const list = [...hrInfo];
    e
      ? (list[index]["designation"] = e.value)
      : (list[index]["designation"] = "");
    setHrInfo(list);
  };

  const handleAddClick = () => {
    setHrInfo([
      ...hrInfo,
      {
        name: "",
        designation: "",
        official_email: "",
        personal_email: "",
        official_phone: "",
        personal_phone: "",
      },
    ]);
  };

  const handleRemoveClick = (index) => {
    const list = [...hrInfo];
    list.splice(index, 1);
    setHrInfo(list);
  };

  const handleSendClick = () => {
    console.log("hrInfo", hrInfo);
  };
  return (
    <>
      <div className={classes.AllData}>
        <h5>HR Information</h5>
        <div className={classes.tableWrapper}>
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Designation</th>
                <th>Official Email</th>
                <th>Personal Email</th>
                <th>Official Phone Number</th>
                <th>Personal Phone Number</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {hrInfo.map((data, index) => (
                <tr key={index}>
                  <td>
                    <input
                      type="text"
                      name="name"
                      placeholder="Name"
                      value={data.name}
                      onChange={(e) => {
                        handleInputChange(e, index);
                      }}
                    />
                  </td>
                  <td>
                    <div className={classes.selectDataLayout}>
                      <SelectDataEntry
                        search_by={"Designation"}
                        options={opt}
                        selectOnChange={(e) =>
                          handleInputChange_select(e, index)
                        }
                        handleInputChange={(e) => {
                          if (e) {
                            handleInputChange_select(e, index);
                          }
                        }}
                      />
                    </div>
                  </td>
                  <td>
                    <input
                      type="email"
                      name="official_email"
                      placeholder="Official Email"
                      value={data.official_email}
                      onChange={(e) => {
                        handleInputChange(e, index);
                      }}
                    />
                  </td>
                  <td>
                    <input
                      type="email"
                      name="personal_email"
                      placeholder="Personal Email"
                      value={data.personal_email}
                      onChange={(e) => {
                        handleInputChange(e, index);
                      }}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="official_phone"
                      placeholder="Official Phone Number"
                      value={data.official_phone}
                      onChange={(e) => {
                        handleInputChange(e, index);
                      }}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="personal_phone"
                      placeholder="Personal Phone Number"
                      value={data.personal_phone}
                      onChange={(e) => {
                        handleInputChange(e, index);
                      }}
                    />
                  </td>
                  <td>
                    <button
                      type="button"
                      onClick={() => handleRemoveClick(index)}
                    >
                      Remove
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <br></br>
        <button
          type="button"
          disabled={hrInfo.length > 4 ? true : false}
          onClick={handleAddClick}
        >
          + Add
        </button>

        <button type="button" onClick={handleSendClick}>
          Send
        </button>
      </div>
    </>
  );
}
