import axios from "axios";
import { Fragment, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { api } from "../../utils/api";
import { Loading } from "../Resource";
import classes from "./Overview.module.css";

export function Overview(props) {
  return (
    <>
      <h6>Subscription Overview</h6>
      <div className={classes.overviewDiv}>
        <NavLink className={classes.div11} to="/admin-portal/subscriber-list">
          <p className={classes.overviewString}>TOTAL SUBSCRIPTIONS</p>
          <div className={classes.overviewNumber}>
            {props.date.countTotalSubscriber ? (
              props.date.countTotalSubscriber
            ) : (
              <Loading size={10} color={"var(--white)"} />
            )}
          </div>
        </NavLink>
        <NavLink className={classes.div12} to="/admin-portal/subscriber-list">
          <p className={classes.overviewString}>LAST 24 HOURS</p>
          <div className={classes.overviewNumber}>
            {props.date.today ? (
              props.date.today
            ) : (
              <Loading size={10} color={"var(--white)"} />
            )}
          </div>
        </NavLink>
        <NavLink className={classes.div13} to="/admin-portal/subscriber-list">
          <p className={classes.overviewString}>THIS WEEK</p>
          <div className={classes.overviewNumber}>
            {props.date.week ? (
              props.date.week
            ) : (
              <Loading size={10} color={"var(--white)"} />
            )}
          </div>
        </NavLink>
        <NavLink className={classes.div14} to="/admin-portal/subscriber-list">
          <p className={classes.overviewString}>THIS MONTH</p>
          <div className={classes.overviewNumber}>
            {props.date.month ? (
              props.date.month
            ) : (
              <Loading size={10} color={"var(--white)"} />
            )}
          </div>
        </NavLink>
        <NavLink className={classes.div15} to="/admin-portal/subscriber-list">
          <p className={classes.overviewString}>THIS YEAR</p>
          <div className={classes.overviewNumber}>
            {props.date.year ? (
              props.date.year
            ) : (
              <Loading size={10} color={"var(--white)"} />
            )}
          </div>
        </NavLink>
      </div>
    </>
  );
}
