import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import classes from "./Company.module.css";

export default function Company() {
  const location = useLocation();

  return (
    <>
      <div className={classes.AllData}>
        <NavLink to={"/admin-portal/data-entry-panel/company-database"}>
          Go back to search
        </NavLink>
        <h5>LOGO {location.state.name}</h5>
        <p>Description:</p>
        <p>Sector:</p>
        <p>Sub Sector:</p>
        <p>Country Origin:</p>
        <p>Owner:</p>
        <p>Company Type:</p>
        <p>Country Origin:</p>
        <p>Manpower:</p>
        <p>Products/Services:</p>
        <p>Year of Inception:</p>
        <p>Business Partner:</p>
        <p>Major Brands:</p>
        <p>DSC/CSE Listing:</p>
        <p>DSC/CSE Listing Year:</p>
      </div>
    </>
  );
}
