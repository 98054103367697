import React from "react";
import BeatLoader from "react-spinners/BeatLoader";
// import LoadingSmall from "react-spinners/PulseLoader";
import classes from "./Loading.module.css";

export default function Loading(props) {
  return (
    <div className={classes.loading}>
      <BeatLoader
        size={props.size ? props.size : 10}
        color={props.color ? props.color : "#1896d3"}
      />
    </div>
  );
}
