import React from "react";
import { DataEntryPanel } from "../components/DataEntryPanel/DataEntryPanel";
import Layout from "../components/Layout/Layout";

export default function DataEntryPanelPage() {
  return (
    <Layout>
      <DataEntryPanel />
    </Layout>
  );
}
