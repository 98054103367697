import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Dashboards } from "../components/Dashboards/Dashboards";
import Layout from "../components/Layout/Layout";
import { Overview } from "../components/Overview/Overview";
import { Auth, UserInfo } from "../contexts/allContext";
import { api } from "../utils/api";

export default function DashboardSummeryPage() {
  const { stateAuth, dispatchAuth } = useContext(Auth);
  const { stateUser, dispatchUser } = useContext(UserInfo);
  const token = stateAuth.token;
  const loggedUserInfo = stateUser.info;
  const [date, setDate] = useState({
    countTotalSubscriber: "",
    today: "",
    week: "",
    month: "",
    year: "",
  });

  useEffect(() => {
    const getInfo = async () => {
      // Overview data
      const countData = await axios.get(`${api}/Data/CountData`);
      const Count_Data = await countData.data;
      setDate((prev) => {
        return { ...prev, countTotalSubscriber: Count_Data };
      });

      const lst_today = await axios.get(
        `${api}/Data/NumberOfUserInLast24Hours`
      );
      const today = await lst_today.data;
      setDate((prev) => {
        return { ...prev, today: today };
      });

      const lst_week = await axios.get(`${api}/Data/NumberOfUserInThisWeek`);
      const week = await lst_week.data;
      setDate((prev) => {
        return { ...prev, week: week };
      });

      const lst_month = await axios.get(`${api}/Data/NumberOfUserInThisMonth`);
      const month = await lst_month.data;
      setDate((prev) => {
        return { ...prev, month: month };
      });

      const lst_year = await axios.get(`${api}/Data/NumberOfUserInThisYear`);
      const year = await lst_year.data;
      setDate((prev) => {
        return { ...prev, year: year };
      });
    };

    getInfo();
  }, []);

  // Dashboard
  // const [adminInfo, setAdminInfo] = useState("");

  // const [totalEconomyIndicator, setEconomyIndicator] = useState("");
  // const [totalBusinessIndicator, setBusinessIndicator] = useState("");
  // const [totalIndustryIndicator, setIndustryIndicator] = useState("");

  // const [uniqueEconomyIndicator, setUniqueEconomyIndicator] = useState("");
  // const [uniqueBusinessIndicator, setUniqueBusinessIndicator] = useState("");
  // const [uniqueIndustryIndicator, setUniqueIndustryIndicator] = useState("");

  // const [businessSector, setBusinessSector] = useState("");
  // const [industrySector, setIndustrySector] = useState("");

  // const [businessCompanyTotal, setBusinessCompanyTotal] = useState("");

  const [data, setData] = useState({
    totalIndicator: 0,
    totalEconomyIndicator: 0,
    totalBusinessIndicator: 0,
    totalIndustryIndicator: 0,
    totalUniqueIndicator: 0,
    uniqueEconomyIndicator: 0,
    uniqueBusinessIndicator: 0,
    uniqueIndustryIndicator: 0,
    businessSector: 0,
    industrySector: 0,
    businessCompanyTotal: 0,
  });

  useEffect(() => {
    const getInfo = async () => {
      // Dashboards summary data

      const unique_economyIndicator = await axios.get(
        `${api}/Data/EconomyIndicatorTotal`
      );
      const unique_economy_Indicator = await unique_economyIndicator.data;
      // setUniqueEconomyIndicator(unique_economy_Indicator);
      setData((prev) => {
        return { ...prev, uniqueEconomyIndicator: unique_economy_Indicator };
      });

      //
      const unique_businessIndicator = await axios.get(
        `${api}/Data/BusinessIndicatorTotal`
      );
      const unique_business_Indicator = await unique_businessIndicator.data;
      // setUniqueBusinessIndicator(unique_business_Indicator);
      setData((prev) => {
        return { ...prev, uniqueBusinessIndicator: unique_business_Indicator };
      });

      //
      const businessSector = await axios.get(`${api}/Data/BusinessSectorTotal`);
      const business_Sector = await businessSector.data;
      // setBusinessSector(business_Sector);
      setData((prev) => {
        return { ...prev, businessSector: business_Sector };
      });

      //
      const unique_industryIndicator = await axios.get(
        `${api}/Data/IndustryIndicatorTotal`
      );
      const unique_industry_Indicator = await unique_industryIndicator.data;
      // setUniqueIndustryIndicator(unique_industry_Indicator);
      setData((prev) => {
        return { ...prev, uniqueIndustryIndicator: unique_industry_Indicator };
      });

      //
      const industrySector = await axios.get(`${api}/Data/IndustrySectorTotal`);
      const industry_Sector = await industrySector.data;
      // setIndustrySector(industry_Sector);
      setData((prev) => {
        return { ...prev, industrySector: industry_Sector };
      });

      //
      const BusinessCompanyTotal = await axios.get(
        `${api}/Data/BusinessCompanyTotal`
      );
      const Business_CompanyTotal = await BusinessCompanyTotal.data;
      // setBusinessCompanyTotal(Business_CompanyTotal);
      setData((prev) => {
        return { ...prev, businessCompanyTotal: Business_CompanyTotal };
      });

      //
      const total_EconomyIndicator = await axios.get(
        `${api}/Data/NumberOfTotalIndicatorInED`
      );
      const total_Economy_Indicator = await total_EconomyIndicator.data;
      // setEconomyIndicator(total_Economy_Indicator);
      setData((prev) => {
        return { ...prev, totalEconomyIndicator: total_Economy_Indicator };
      });

      //
      const total_BusinessIndicator = await axios.get(
        `${api}/Data/NumberOfTotalIndicatorInBD`
      );
      const total_Business_Indicator = await total_BusinessIndicator.data;
      // setBusinessIndicator(total_Business_Indicator);
      setData((prev) => {
        return { ...prev, totalBusinessIndicator: total_Business_Indicator };
      });

      //
      const total_IndustryIndicator = await axios.get(
        `${api}/Data/NumberOfTotalIndicatorInID`
      );
      const total_Industry_Indicator = await total_IndustryIndicator.data;
      // setIndustryIndicator(total_Industry_Indicator);
      setData((prev) => {
        return { ...prev, totalIndustryIndicator: total_Industry_Indicator };
      });
    };

    getInfo();
  }, []);

  useEffect(() => {
    // Total Indicator
    setData((prev) => {
      return {
        ...prev,
        totalIndicator:
          data.totalEconomyIndicator +
          data.totalIndustryIndicator +
          data.totalBusinessIndicator,
      };
    });
  }, [
    data.totalEconomyIndicator,
    data.totalIndustryIndicator,
    data.totalBusinessIndicator,
  ]);

  useEffect(() => {
    // Total Unique Indicator
    setData((prev) => {
      return {
        ...prev,
        totalUniqueIndicator:
          data.uniqueEconomyIndicator +
          data.uniqueBusinessIndicator +
          data.uniqueIndustryIndicator,
      };
    });
  }, [
    data.uniqueEconomyIndicator,
    data.uniqueBusinessIndicator,
    data.uniqueIndustryIndicator,
  ]);

  return (
    <>
      <Layout>
        <Overview date={date} />
        <Dashboards loggedUserInfo={loggedUserInfo} data={data} />
      </Layout>
    </>
  );
}
