import { useEffect, useState } from "react";
import BarLoader from "react-spinners/BarLoader";
import { api } from "../../../utils/api";
// import { Pagination } from "../Pagination/Pagination";
import classesDashboardTable from "../../DashboardTable/DashboardTable.module.css";
import { AllBusinessDataTable } from "./AllBusinessDataTable/AllBusinessDataTable";
import classes from "./BusinessData.module.css";

const month = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

let BUSINESS_DATA_API = "/Data/BusinessData/";

export const BusinessData = ({
  bQuery,
  setDashboardPosition,
  setActiveDeleteData,
  dataId,
  setDataId,
}) => {
  const [businessData, setBusinessData] = useState("");
  const [radio, setRadio] = useState("AllBus");
  const [loading, setLoading] = useState(true);

  const fetchApiData = async (url) => {
    const response = await fetch(url);
    const business_data = await response.json();
    setBusinessData(business_data);
    setLoading(false);
  };

  const getRefresh = async () => {
    if (bQuery) {
      await fetchApiData(`${api}${BUSINESS_DATA_API}${bQuery}`);
    }
  };

  useEffect(() => {
    fetchApiData(`${api}${BUSINESS_DATA_API}${bQuery}`);
  }, [bQuery, api]);

  return (
    <>
      {!bQuery ? (
        ""
      ) : loading ? (
        <>
          <BarLoader className="loading" size={15} color={"#123abc"} />
        </>
      ) : (
        <div className={classesDashboardTable.DataEntryTableLayout}>
          <div className={classes.radio}>
            <input
              type="radio"
              name="AllBus"
              value="AllBus"
              onChange={(e) => setRadio("AllBus")}
              checked={radio === "AllBus"}
            />
            <label>All</label>
            <input
              type="radio"
              name="BudgetBus"
              value="BudgetBus"
              onChange={(e) => setRadio("BudgetBus")}
              checked={radio === "BudgetBus"}
            />
            <label>Budget Year</label>
            <input
              type="radio"
              name="CalendarBus"
              value="CalendarBus"
              onChange={(e) => setRadio("CalendarBus")}
              checked={radio === "CalendarBus"}
            />
            <label>Calendar Year</label>
          </div>
          <div
            className={`${classesDashboardTable.tableWrapper} ${classesDashboardTable.tableWrapperDashboardData}`}
          >
            {businessData ? (
              <AllBusinessDataTable
                month={month}
                businessData={businessData}
                setDashboardPosition={setDashboardPosition}
                setActiveDeleteData={setActiveDeleteData}
                dataId={dataId}
                setDataId={setDataId}
                radio={radio}
                getRefresh={getRefresh}
              />
            ) : (
              "unable to catch data"
            )}
          </div>
        </div>
      )}
    </>
  );
};
