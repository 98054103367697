let year = [];

for (let i = 1900; i <= 2022; i++) {
  const m = i;
  const n = i;
  year.push({ label: m, value: n });
}

export const yearOrigin = year;
export const yearInception = year;
export const dsc_cse_listing_year = year;
