import axios from "axios";
import React, { useContext, useEffect } from "react";
import { useState } from "react";
import Layout from "../components/Layout/Layout";
import { Subscribers } from "../components/Subscribers/Subscribers";
import { Auth } from "../contexts/allContext";
import { api, frontEnd } from "../utils/api";

export default function SubscribersListPage() {
  const { stateAuth } = useContext(Auth);
  let token = stateAuth.token;

  // const [loaded, setLoaded] = useState(false);
  const [countData, setCountData] = useState("");
  const [data, setData] = useState([]);
  const [check, setCheck] = useState(false);

  useEffect(() => {
    const getInfo = async () => {
      const allUser_Data = await axios.get(`${api}/Data/AllUserData`);
      const all_user_data = await allUser_Data.data;
      setData(all_user_data);
      setCountData(all_user_data.length);
      // setLoaded(true);
      check && setCheck(false);
    };
    getInfo();
  }, [check]);

  const [loading, setLoading] = useState(false);
  const [popUp, setPopUp] = useState(false);
  const [enableEdit, setEnableEdit] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSuccess, setAlertSuccess] = useState(false);
  const [userData, setUserData] = useState({
    id: "",
    name: "",
    phone: "",
    email: "",
    company: "",
    country: "",
    designation: "",
    role: "",
    regitime: "",
    endtime: "",
    restriction: 0,
    download: 0,
    downloadLimit: 0,
    newPassword: "",
  });

  const handleUpdateUser = async () => {
    setLoading(true);
    const details = {
      name: userData.name,
      phone_number: userData.phone,
      regitime: userData.regitime,
      endtime: userData.endtime,
      role: userData.role,
      restriction: userData.restriction,
      download: userData.download,
      download_limit: userData.downloadLimit,
    };
    try {
      const response = await axios.patch(
        `${api}/user/update_user/${userData.id}`,
        details
      );

      console.log(response);
      if (response) {
        setLoading(false);
        setAlertMessage("The user info has been updated successfully!");
        setCheck(true);
        setAlertSuccess(true);
        setTimeout(function () {
          setAlertSuccess(false);
          setAlertMessage("");
        }, 5000);
        setEnableEdit(false);
        setPopUp(false);
        window.location.reload(false);
      } else {
        alert("User update failed!");
        setLoading(false);
      }
    } catch {
      alert("Please check your internet connection!");
      setLoading(false);
    }
  };

  const handleResetPassword = async () => {
    try {
      const response = await axios.patch(
        `${api}/user/reset_password/${userData.email}/${userData.newPassword}`,
        {
          headers: {
            "Access-Control-Allow-Origin": frontEnd,
            // Authorization: `Bearer ${token}`,
          },
          withCredentials: true,
        }
      );

      if (response) {
        setAlertMessage(response.data.message);
        setAlertSuccess(true);
        setUserData((prev) => {
          return {
            ...prev,
            newPassword: "",
          };
        });
        setTimeout(function () {
          setAlertSuccess(false);
          setAlertMessage("");
        }, 5000);
      } else {
        alert(
          "You are not authorized to remove this password! Please contact to admin."
        );
      }
    } catch {
      alert("Please check your internet connection!");
    }
  };

  const handleDelete = async (email) => {
    try {
      const resopnse = await axios.delete(`${api}/user/delete_user/${email}`, {
        headers: {
          "Access-Control-Allow-Origin": frontEnd,
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      });
      const data = await resopnse.data;
      if (data.log) {
        setCheck(true);
        setAlertMessage(data.message);
        setAlertSuccess(true);
        setTimeout(function () {
          setAlertSuccess(false);
          setAlertMessage("");
        }, 5000);
      } else {
        alert(data.message);
      }
    } catch {
      alert("Please check your internet connection!");
    }
  };

  return (
    <Layout>
      <Subscribers
        data={data}
        countData={countData}
        // update
        userData={userData}
        setUserData={setUserData}
        handleUpdateUser={handleUpdateUser}
        handleResetPassword={handleResetPassword}
        alertSuccess={alertSuccess}
        alertMessage={alertMessage}
        setAlertMessage={setAlertMessage}
        check={check}
        setCheck={setCheck}
        enableEdit={enableEdit}
        setEnableEdit={setEnableEdit}
        popUp={popUp}
        setPopUp={setPopUp}
        loading={loading}
        // delete
        handleDelete={handleDelete}
      />
    </Layout>
  );
}
