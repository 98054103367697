import { Fragment, useEffect, useState } from "react";
import { api } from "../../utils/api";
import { BusinessCompanyList } from "./BusinessList/BusinessCompanyList/BusinessCompanyList";
import { BusinessIndicatorList } from "./BusinessList/BusinessIndicatorList/BusinessIndicatorList";
import { BusinessSectorList } from "./BusinessList/BusinessSectorList/BusinessSectorList";
import classes from "./DataList.module.css";
import { EconomicList } from "./EconomicList/EconomicList";
import { IndustryIndicatorList } from "./IndustryList/IndustryIndicatorList/IndustryIndicatorList";
import { IndustrySectorList } from "./IndustryList/IndustrySectorList/IndustrySectorLIst";

export function DataList(props) {
  const [reRenderData, setRerenderData] = useState(false);
  const [economicIndicatorList, setEconomicIndicatorList] = useState([]);
  const [industryIndicatorList, setIndustryIndicatorList] = useState([]);
  const [businessIndicatorList, setBusinessIndicatorList] = useState([]);
  const [industrySectorList, setIndustrySectorList] = useState([]);
  const [businessCompanyList, setBusinessCompanyList] = useState([]);
  const [businessSectorList, setBusinessSectorList] = useState([]);

  useEffect(() => {
    const getInfo = async () => {
      // fetch list of economic indicators
      const economic_Indicator = await fetch(
        `${api}/Data/Economic_Data/listofindicators`
      );
      const economicIndicatorList = await economic_Indicator.json();
      setEconomicIndicatorList(economicIndicatorList);
      reRenderData && setRerenderData(false);
    };

    getInfo();
  }, [reRenderData]);

  useEffect(() => {
    const getInfo = async () => {
      // fetch list of industry indicators
      const industry_Indicator = await fetch(
        `${api}/Data/Industry_Data/listofindicators`
      );
      const industryIndicatorList = await industry_Indicator.json();
      setIndustryIndicatorList(industryIndicatorList);

      // fetch list of industry sectors
      const industry_Sector = await fetch(
        `${api}/Data/Industry_Data/listofsectors`
      );
      const industry_SectorList = await industry_Sector.json();
      setIndustrySectorList(industry_SectorList);
      reRenderData && setRerenderData(false);
    };

    getInfo();
  }, [reRenderData]);

  useEffect(() => {
    const getInfo = async () => {
      // fetch list of business companies
      const business_Company = await fetch(
        `${api}/Data/Business_Data/listofcompanies`
      );
      const businessCompanyList = await business_Company.json();
      setBusinessCompanyList(businessCompanyList);

      // fetch list of business sectors
      const business_Sector = await fetch(
        `${api}/Data/Business_Data/listofsectors`
      );
      const businessSectorList = await business_Sector.json();
      setBusinessSectorList(businessSectorList);

      // fetch list of business indicator
      const business_Indicator = await fetch(
        `${api}/Data/Business/allindicator`
      );
      const businessIndicatorList = await business_Indicator.json();
      setBusinessIndicatorList(businessIndicatorList);
      reRenderData && setRerenderData(false);
    };

    getInfo();
  }, [reRenderData]);

  return (
    <Fragment>
      <div className={classes.AllData}>
        <h5>Economic Data</h5>
        <h6>Indicator List (Total: {economicIndicatorList.length})</h6>
        <div>
          <EconomicList
            economicIndicatorList={economicIndicatorList}
            setRerenderData={setRerenderData}
          />
        </div>
      </div>
      <div className={classes.AllData}>
        <h5>Industry Data</h5>
        <h6>Indicator List (Total: {industryIndicatorList.length})</h6>
        <div>
          <IndustryIndicatorList
            industryIndicatorList={industryIndicatorList}
            setRerenderData={setRerenderData}
          />
        </div>
        <h6>Sector List (Total: {industrySectorList.length})</h6>
        <div>
          <IndustrySectorList
            industrySectorList={industrySectorList}
            setRerenderData={setRerenderData}
          />
        </div>
      </div>
      <div className={classes.AllData}>
        <h5>Company Data</h5>
        <h6>Company List (Total: {businessCompanyList.length})</h6>
        <div>
          <BusinessCompanyList
            businessCompanyList={businessCompanyList}
            setRerenderData={setRerenderData}
          />
        </div>
        <h6>Sector List (Total: {businessSectorList.length})</h6>
        <div>
          <BusinessSectorList
            businessSectorList={businessSectorList}
            setRerenderData={setRerenderData}
          />
        </div>
        <h6>Indicator List (Total: {businessIndicatorList.length})</h6>
        <div>
          <BusinessIndicatorList
            businessIndicatorList={businessIndicatorList}
            setRerenderData={setRerenderData}
          />
        </div>
      </div>
    </Fragment>
  );
}
