import axios from "axios";
import { Fragment, useState } from "react";
import { api } from "../../../../utils/api";
import DashboardTableCSS from "../../../DashboardTable/DashboardTable.module.css";
import { Loading } from "../../../Resource";

export function IndustryIndicatorList(props) {
  const { industryIndicatorList } = props;

  const [newIndicator, setNewIndicator] = useState("");
  const [currentIndicator, setCurrentIndicator] = useState("");
  const [id, setId] = useState("");
  const [hit, setHit] = useState(false);

  const handleUpdate = async () => {
    if (newIndicator) {
      const response = await axios.patch(
        `${api}/Update/Industry/indicator/${currentIndicator}/${newIndicator}`
      );
      if (response) {
        setHit(false);
        setNewIndicator("");
        props.setRerenderData(true);
      } else {
        alert("Data is unable to update!");
      }
    } else {
      alert("Please fill out this field.");
    }
  };
  return (
    <Fragment>
      <div
        className={`${DashboardTableCSS.tableWrapper} ${DashboardTableCSS.tableWrapperDataList}`}
      >
        {industryIndicatorList.length !== 0 ? (
          <table>
            <thead>
              <tr>
                <th>Sl.</th>
                <th>Indicator</th>
                <th>Last Updated</th>
                <th>Added On</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {industryIndicatorList.map((indicator, sl) => (
                <tr key={sl}>
                  <td>{sl + 1}</td>
                  <td>
                    {hit ? (
                      sl === id ? (
                        <>
                          <p>Current: {indicator}</p>
                          <input
                            type="text"
                            placeholder="set new indicator"
                            value={newIndicator}
                            onChange={(e) => setNewIndicator(e.target.value)}
                            required
                          />
                        </>
                      ) : (
                        indicator
                      )
                    ) : (
                      indicator
                    )}
                  </td>
                  <td>{}</td>
                  <td>{}</td>
                  <td>
                    <button
                      className={DashboardTableCSS.buttonUpdate}
                      onClick={(e) => {
                        if (hit) {
                          if (sl === id) {
                            handleUpdate();
                          }
                        } else {
                          setId(sl);
                          setHit(true);
                          setCurrentIndicator(indicator);
                        }
                      }}
                    >
                      {hit ? (sl === id ? "Save" : "Update") : "Update"}
                    </button>
                    {hit ? (
                      sl === id ? (
                        <button
                          className={DashboardTableCSS.buttonDelete}
                          onClick={(e) => {
                            setHit(false);
                          }}
                        >
                          Cancel
                        </button>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <Loading size={15} color={"var(--home-color)"} />
        )}
      </div>
    </Fragment>
  );
}
