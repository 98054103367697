import axios from "axios";
import React from "react";
import { api } from "../../../utils/api";
import classes from "../../AdminSettings/DeleteProfile/DeleteProfile.module.css";

export const DeleteDashboardData = ({
  activeDeleteData,
  handleDeleteData,
  dashboardPosition,
  dataId,
}) => {
  if (activeDeleteData) {
    document.body.classList.add(classes.activeModel);
  } else {
    document.body.classList.remove(classes.activeModel);
  }

  const handleDeleteDashboardData = async (id) => {
    let url = "";
    if (dashboardPosition === 1) {
      url = `${api}/DataEntry/economic/delete_data/${id}`;
    } else if (dashboardPosition === 2) {
      url = `${api}/DataEntry/industry/delete_data/${id}`;
    } else if (dashboardPosition === 3) {
      url = `${api}/DataEntry/business/delete_data/${id}`;
    }
    const response = await axios.delete(url);

    if (!response) {
      alert("something went wrong when removing dashboard data");
    } else {
      handleDeleteData();
    }
  };

  return activeDeleteData ? (
    <div className={classes.model}>
      <div className={classes.overlay} onClick={handleDeleteData}></div>
      <div className={classes.modelContent}>
        <p>Confirm?</p>
        <button
          className={classes.buttonDelete}
          onClick={() => {
            handleDeleteDashboardData(dataId);
            window.location.reload(false);
          }}
        >
          Yes
        </button>
        <button className={classes.buttonCancel} onClick={handleDeleteData}>
          X
        </button>
      </div>
    </div>
  ) : (
    ""
  );
};
