import DashboardTableCSS from "../../DashboardTable/DashboardTable.module.css";

export function AdminList(props) {
  const { admin, setLeadPosition, setActiveDeleteModel, setEmail } = props;
  return (
    <>
      <div className={DashboardTableCSS.tableWrapper}>
        <table>
          <thead>
            <tr>
              <th>Sl.</th>
              <th>Name</th>
              <th>Email</th>
              <th>Designation</th>
              <th>Role</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {admin.map((e, sl) => (
              <tr key={e._id}>
                <td>{sl + 1}</td>
                <td>{e.Name}</td>
                <td>{e.Email}</td>
                <td>{e.Designation}</td>
                <td>{e.Role}</td>
                <td>
                  {/* <button className={DashboardTableCSS.buttonUpdate}>
                    Update
                  </button> */}
                  <button
                    className={DashboardTableCSS.buttonDelete}
                    onClick={() => {
                      setLeadPosition(2);
                      setActiveDeleteModel(true);
                      setEmail(e.Email);
                    }}
                  >
                    Delete
                  </button>
                  <button className={DashboardTableCSS.buttonReset}>
                    Reset Password
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
}
