import axios from "axios";
import React, { useState } from "react";
import classes from "./CompanyDatabase.module.css";
// import CompanyDatabaseTable from "./CompanyDatabaseTable/CompanyDatabaseTable";
import CompanyInformation from "./CompanyInformation/CompanyInformation";
import ContactInformation from "./ContactInformation/ContactInformation";
import HrInformation from "./HrInformation/HrInformation";
import ShareholderInformation from "./ShareholderInformation/ShareholderInformation";

const api = process.env.REACT_APP_BASE_URL;
const frontEnd = process.env.REACT_APP_CLIENT_URL;

export default function CompanyDatabase() {
  const [companyInfo, setCompanyInfo] = useState({
    name: "",
    industry: "",
    sub_industry: "",
    companyType: "",
    companyOrigin: "",
    countryOrigin: "",
    city: "",
    yearOrigin: "",
    yearInception: "",
    product_service: "",
    brands: "",
    founder: "",
    manpower: "",
    business_partner: "",
    dsc_listing_year: "",
    cse_listing_year: "",
    ref: "",
    description: "",
  });

  const [companyInfoSelect, setCompanyInfoSelect] = useState({
    industry: "",
    sub_industry: "",
    companyType: "",
    companyOrigin: "",
    countryOrigin: "",
    city: "",
    yearOrigin: "",
    yearInception: "",
    dsc_listing_year: "",
    cse_listing_year: "",
  });

  const [shareholderInfo, setShareholderInfo] = useState({
    authorised_capital: "",
    paidup_capital: "",
    number_of_securities: "",
  });

  const [inputPartnerInvestorShare, setInputPartnerInvestorShare] = useState([
    {
      partner: "",
      shareholding: "",
    },
  ]);

  const [hrInfo, setHrInfo] = useState([
    {
      name: "",
      designation: "",
      official_email: "",
      personal_email: "",
      official_phone: "",
      personal_phone: "",
    },
  ]);

  const [contactInfo, setContactInfo] = useState({
    address_corporate_office: "",
    address_main_factory: "",
    email: "",
    phone: "",
    website: "",
    other_info: "",
  });

  // console.log(companyInfo);
  // console.log(shareholderInfo);
  // console.log(inputPartnerInvestorShare);
  // console.log(hrInfo);
  // console.log(contactInfo);

  const clickSubmit = async (e) => {
    // alert("clicked submit");
    e.preventDefault();
    let url = `${api}/DataEntry/companydatabase/add_data`;
    const response = await axios.post(
      url,
      {
        company: companyInfo.name === "" ? NaN : companyInfo.name,
        industry: companyInfo.industry === "" ? NaN : companyInfo.industry,
        subindustry:
          companyInfo.sub_industry === "" ? NaN : companyInfo.sub_industry,
        company_type:
          companyInfo.companyType === "" ? NaN : companyInfo.companyType,
        company_origin:
          companyInfo.companyOrigin === "" ? NaN : companyInfo.companyOrigin,
        country_of_origin:
          companyInfo.countryOrigin === "" ? NaN : companyInfo.countryOrigin,
        city_or_division: companyInfo.city === "" ? NaN : companyInfo.city,
        year_of_origin:
          companyInfo.yearOrigin === "" ? NaN : companyInfo.yearOrigin,
        year_of_inception_of_bangladesh_operation:
          companyInfo.yearInception === "" ? NaN : companyInfo.yearInception,
        product_or_service_description:
          companyInfo.product_service === ""
            ? NaN
            : companyInfo.product_service,
        major_brands: companyInfo.brands === "" ? NaN : companyInfo.brands,
        company_description:
          companyInfo.description === "" ? NaN : companyInfo.description,
        founder: companyInfo.founder === "" ? NaN : companyInfo.founder,
        manpower: companyInfo.manpower === "" ? NaN : companyInfo.manpower,
        disributorship_or_business_partner:
          companyInfo.business_partner === ""
            ? NaN
            : companyInfo.business_partner,
        dse_listing_year:
          companyInfo.dsc_cse_listing === ""
            ? NaN
            : companyInfo.dsc_cse_listing,
        cse_listing_year:
          companyInfo.dsc_cse_listing_year === ""
            ? NaN
            : companyInfo.dsc_cse_listing_year,
        reference: companyInfo.ref === "" ? NaN : companyInfo.ref,
        authorised_capital:
          shareholderInfo.authorised_capital === ""
            ? NaN
            : shareholderInfo.authorised_capital,
        paid_up_capital:
          shareholderInfo.paidup_capital === ""
            ? NaN
            : shareholderInfo.paidup_capital,
        number_of_securities:
          shareholderInfo.number_of_securities === ""
            ? NaN
            : shareholderInfo.number_of_securities,
        partner_investor_1: inputPartnerInvestorShare[0].partner
          ? inputPartnerInvestorShare[0].partner === ""
            ? NaN
            : inputPartnerInvestorShare[0].partner
          : NaN,
        partner_investor_2:
          inputPartnerInvestorShare.length === 2
            ? inputPartnerInvestorShare[1].partner === ""
              ? NaN
              : inputPartnerInvestorShare[1].partner
            : NaN,
        partner_investor_3:
          inputPartnerInvestorShare.length === 3
            ? inputPartnerInvestorShare[2].partner === ""
              ? NaN
              : inputPartnerInvestorShare[2].partner
            : NaN,
        partner_investor_4:
          inputPartnerInvestorShare.length === 4
            ? inputPartnerInvestorShare[3].partner === ""
              ? NaN
              : inputPartnerInvestorShare[3].partner
            : "",
        partner_investor_5:
          inputPartnerInvestorShare.length === 5
            ? inputPartnerInvestorShare[4].partner === ""
              ? NaN
              : inputPartnerInvestorShare[4].partner
            : NaN,
        partner_investor_1_shareholding:
          inputPartnerInvestorShare.length === 1
            ? inputPartnerInvestorShare[0].shareholding === ""
              ? NaN
              : inputPartnerInvestorShare[0].shareholding
            : NaN,
        partner_investor_2_shareholding:
          inputPartnerInvestorShare.length === 2
            ? inputPartnerInvestorShare[1].shareholding === ""
              ? NaN
              : inputPartnerInvestorShare[1].shareholding
            : NaN,
        partner_investor_3_shareholding:
          inputPartnerInvestorShare.length === 3
            ? inputPartnerInvestorShare[2].shareholding === ""
              ? NaN
              : inputPartnerInvestorShare[2].shareholding
            : NaN,
        partner_investor_4_shareholding:
          inputPartnerInvestorShare.length === 4
            ? inputPartnerInvestorShare[3].shareholding === ""
              ? NaN
              : inputPartnerInvestorShare[3].shareholding
            : NaN,
        partner_investor_5_shareholding:
          inputPartnerInvestorShare.length === 5
            ? inputPartnerInvestorShare[4].shareholding === ""
              ? NaN
              : inputPartnerInvestorShare[4].shareholding
            : NaN,
        key_person_1:
          hrInfo.length === 1
            ? hrInfo[0].name === ""
              ? NaN
              : hrInfo[0].name
            : NaN,
        key_person_2:
          hrInfo.length === 2
            ? hrInfo[1].name === ""
              ? NaN
              : hrInfo[1].name
            : NaN,
        key_person_3:
          hrInfo.length === 3
            ? hrInfo[2].name === ""
              ? NaN
              : hrInfo[2].name
            : NaN,
        key_person_4:
          hrInfo.length === 4
            ? hrInfo[3].name === ""
              ? NaN
              : hrInfo[3].name
            : NaN,
        key_person_5:
          hrInfo.length === 5
            ? hrInfo[4].name === ""
              ? NaN
              : hrInfo[4].name
            : NaN,
        key_person_1_designation:
          hrInfo.length === 1
            ? hrInfo[0].designation === ""
              ? NaN
              : hrInfo[0].designation
            : NaN,
        key_person_2_designation:
          hrInfo.length === 2
            ? hrInfo[1].designation === ""
              ? NaN
              : hrInfo[1].designation
            : NaN,
        key_person_3_designation:
          hrInfo.length === 3
            ? hrInfo[2].designation === ""
              ? NaN
              : hrInfo[2].designation
            : NaN,
        key_person_4_designation:
          hrInfo.length === 4
            ? hrInfo[3].designation === ""
              ? NaN
              : hrInfo[3].designation
            : NaN,
        key_person_5_designation:
          hrInfo.length === 5
            ? hrInfo[4].designation === ""
              ? NaN
              : hrInfo[4].designation
            : NaN,
        key_person_1_official_email:
          hrInfo.length === 1
            ? hrInfo[0].official_email === ""
              ? NaN
              : hrInfo[0].official_email
            : NaN,
        key_person_2_official_email:
          hrInfo.length === 2
            ? hrInfo[1].official_email === ""
              ? NaN
              : hrInfo[1].official_email
            : NaN,
        key_person_3_official_email:
          hrInfo.length === 3
            ? hrInfo[2].official_email === ""
              ? NaN
              : hrInfo[3].official_email
            : NaN,
        key_person_4_official_email:
          hrInfo.length === 4
            ? hrInfo[3].official_email === ""
              ? NaN
              : hrInfo[3].official_email
            : NaN,
        key_person_5_official_email:
          hrInfo.length === 5
            ? hrInfo[4].official_email === ""
              ? NaN
              : hrInfo[4].official_email
            : NaN,
        key_person_1_personal_email:
          hrInfo.length === 1
            ? hrInfo[0].personal_email === ""
              ? NaN
              : hrInfo[0].personal_email
            : NaN,
        key_person_2_personal_email:
          hrInfo.length === 2
            ? hrInfo[1].personal_email === ""
              ? NaN
              : hrInfo[1].personal_email
            : NaN,
        key_person_3_personal_email:
          hrInfo.length === 3
            ? hrInfo[2].personal_email === ""
              ? NaN
              : hrInfo[3].personal_email
            : NaN,
        key_person_4_personal_email:
          hrInfo.length === 4
            ? hrInfo[3].personal_email === ""
              ? NaN
              : hrInfo[3].personal_email
            : NaN,
        key_person_5_personal_email:
          hrInfo.length === 5
            ? hrInfo[4].personal_email === ""
              ? NaN
              : hrInfo[4].personal_email
            : NaN,
        key_person_1_official_number:
          hrInfo.length === 1
            ? hrInfo[0].official_phone === ""
              ? NaN
              : hrInfo[0].official_phone
            : NaN,
        key_person_2_official_number:
          hrInfo.length === 2
            ? hrInfo[1].official_phone === ""
              ? NaN
              : hrInfo[1].official_phone
            : NaN,
        key_person_3_official_number:
          hrInfo.length === 3
            ? hrInfo[2].official_phone === ""
              ? NaN
              : hrInfo[2].official_phone
            : NaN,
        key_person_4_official_number:
          hrInfo.length === 4
            ? hrInfo[3].official_phone === ""
              ? NaN
              : hrInfo[3].official_phone
            : NaN,
        key_person_5_official_number:
          hrInfo.length === 5
            ? hrInfo[4].official_phone === ""
              ? NaN
              : hrInfo[4].official_phone
            : NaN,
        key_person_1_personal_number:
          hrInfo.length === 1
            ? hrInfo[0].personal_phone === ""
              ? NaN
              : hrInfo[0].personal_phone
            : NaN,
        key_person_2_personal_number:
          hrInfo.length === 2
            ? hrInfo[1].personal_phone === ""
              ? NaN
              : hrInfo[1].personal_phone
            : NaN,
        key_person_3_personal_number:
          hrInfo.length === 3
            ? hrInfo[2].personal_phone === ""
              ? NaN
              : hrInfo[2].personal_phone
            : NaN,
        key_person_4_personal_number:
          hrInfo.length === 4
            ? hrInfo[3].personal_phone === ""
              ? NaN
              : hrInfo[3].personal_phone
            : NaN,
        key_person_5_personal_number:
          hrInfo.length === 5
            ? hrInfo[4].personal_phone === ""
              ? NaN
              : hrInfo[4].personal_phone
            : NaN,
        address_corporate_office:
          contactInfo.address_corporate_office === ""
            ? NaN
            : contactInfo.address_corporate_office,
        address_main_factory_production:
          contactInfo.address_main_factory === ""
            ? NaN
            : contactInfo.address_main_factory,
        website: contactInfo.website === "" ? NaN : contactInfo.website,
        email_address: contactInfo.email === "" ? NaN : contactInfo.email,
        contact_number: contactInfo.phone === "" ? NaN : contactInfo.phone,
        other_info:
          contactInfo.other_info === "" ? NaN : contactInfo.other_info,
      },
      {
        headers: {
          "Access-Control-Allow-Origin": frontEnd,
        },
        withCredentials: true,
      }
    );
    if (!response) {
      console.log("Failed to submit");
    } else {
      console.log("Submitted");
    }
  };

  return (
    <>
      <div className={classes.companyDatabase}>
        {/* <CompanyDatabaseTable /> */}
        <form onSubmit={(e) => clickSubmit(e)}>
          <CompanyInformation
            companyInfo={companyInfo}
            setCompanyInfo={setCompanyInfo}
            companyInfoSelect={companyInfoSelect}
            setCompanyInfoSelect={setCompanyInfoSelect}
          />
          <ShareholderInformation
            shareholderInfo={shareholderInfo}
            setShareholderInfo={setShareholderInfo}
            inputPartnerInvestorShare={inputPartnerInvestorShare}
            setInputPartnerInvestorShare={setInputPartnerInvestorShare}
          />
          <HrInformation hrInfo={hrInfo} setHrInfo={setHrInfo} />
          <ContactInformation
            contactInfo={contactInfo}
            setContactInfo={setContactInfo}
          />

          <button className={classes.CreateButton} type="submit">
            + CREATE
          </button>
        </form>
      </div>
    </>
  );
}
