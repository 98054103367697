import React, { useEffect } from "react";
import { countryOrigin } from "../../../functions/variables";
import {
  dsc_cse_listing_year,
  yearInception,
  yearOrigin,
} from "../../../functions/yearVariable";
import { SelectDataEntry } from "../../AddDashboardData/SelectDataEntry/SelectDataEntry";
import { SelectData } from "../../DataEntryPanel/SelectData/SelectData";
import classes from "./CompanyInformation.module.css";

const api = process.env.REACT_APP_BASE_URL;
// const frontEnd = process.env.REACT_APP_CLIENT_URL;

export default function CompanyInformation(props) {
  const {
    companyInfo,
    setCompanyInfo,
    companyInfoSelect,
    setCompanyInfoSelect,
  } = props;

  useEffect(() => {
    const getInfo = async () => {
      let ind = [];
      let sub_ind = [];
      let companyType = [];
      let companyOrigin = [];

      // fetch industry
      const fetch_industry = await fetch(
        `${api}/Data/companydatabase/allindustry`
      );
      const industry = await fetch_industry.json();

      // set industry
      await industry.map((e) =>
        ind.push({ label: e.Industry, value: e.Industry })
      );

      // set sub industry
      if (industry && companyInfo.industry) {
        const All_subIndustry = industry.filter(
          (e) => e.Industry === companyInfo.industry
        )[0].Subindustry;

        await All_subIndustry.split(",").map((e) =>
          sub_ind.push({ label: e, value: e })
        );
      }

      // fetch company type
      const fetch_company_type = await fetch(`${api}/Data/Economy/allunit`);
      const company_type = await fetch_company_type.json();
      // set company type
      await company_type.map((e) => companyType.push({ label: e, value: e }));

      // fetch company origin
      const fetch_company_origin = await fetch(`${api}/Data/Economy/allunit`);
      const company_origin = await fetch_company_origin.json();
      // set company origin
      await company_origin.map((e) =>
        companyOrigin.push({ label: e, value: e })
      );

      const city = [
        { value: "Dhaka", label: "Dhaka" },
        { value: "Chittagong", label: "Chittagong" },
        { value: "Sylhet", label: "Sylhet" },
        { value: "Khulna", label: "Khulna" },
        { value: "Rajshahi", label: "Rajshahi" },
        { value: "Rangpur", label: "Rangpur" },
        { value: "Mymensingh", label: "Mymensingh" },
        { value: "Barisal", label: "Barisal" },
      ];

      setCompanyInfoSelect({
        industry: ind,
        sub_industry: sub_ind,
        companyType: companyType,
        companyOrigin: companyOrigin,
        countryOrigin: countryOrigin,
        city: city,
        yearOrigin: yearOrigin,
        yearInception: yearInception,
        dsc_listing_year: dsc_cse_listing_year,
        cse_listing_year: dsc_cse_listing_year,
      });
    };
    getInfo();
  }, [setCompanyInfoSelect, companyInfo]);

  return (
    <>
      <div className={classes.AllData}>
        <h5>Company Information</h5>
        <div className={classes.InputFieldDiv}>
          <div className={classes.InputFieldDivLeft}>
            <label>Company Name</label>
            <input
              type="text"
              placeholder="Company Name"
              value={companyInfo.name}
              onChange={(e) =>
                e
                  ? setCompanyInfo((prev) => {
                      return { ...prev, name: e.target.value };
                    })
                  : setCompanyInfo((prev) => {
                      return { ...prev, name: null };
                    })
              }
              required
            />
            <label>Industry</label>
            <div className={classes.selectDataLayout}>
              <SelectDataEntry
                search_by={"-- Select industry --"}
                options={companyInfoSelect.industry}
                selectOnChange={(e) =>
                  e
                    ? setCompanyInfo((prev) => {
                        return { ...prev, industry: e.value };
                      })
                    : setCompanyInfo((prev) => {
                        return { ...prev, industry: null };
                      })
                }
                handleInputChange={(e) => {
                  if (e) {
                    setCompanyInfo((prev) => {
                      return { ...prev, industry: e.value };
                    });
                  }
                }}
              />
            </div>
            <label>Sub Industry</label>
            <div className={classes.selectDataLayout}>
              <SelectDataEntry
                search_by={"-- Select Sub Industry --"}
                options={companyInfoSelect.sub_industry}
                selectOnChange={(e) =>
                  e
                    ? setCompanyInfo((prev) => {
                        return { ...prev, sub_industry: e.value };
                      })
                    : setCompanyInfo((prev) => {
                        return { ...prev, sub_industry: null };
                      })
                }
                handleInputChange={(e) => {
                  if (e) {
                    setCompanyInfo((prev) => {
                      return { ...prev, sub_industry: e.value };
                    });
                  }
                }}
              />
            </div>
            <label>Company type</label>
            <div className={classes.selectDataLayout}>
              <SelectData
                search_by={"-- Select Company Type --"}
                options={companyInfoSelect.companyType}
                selectOnChange={(e) =>
                  e
                    ? setCompanyInfo((prev) => {
                        return { ...prev, companyType: e.value };
                      })
                    : setCompanyInfo((prev) => {
                        return { ...prev, companyType: null };
                      })
                }
              />
            </div>
            <label>Company Origin</label>
            <div className={classes.selectDataLayout}>
              <SelectData
                search_by={"-- Select Company Origin --"}
                options={companyInfoSelect.companyOrigin}
                selectOnChange={(e) =>
                  e
                    ? setCompanyInfo((prev) => {
                        return { ...prev, companyOrigin: e.value };
                      })
                    : setCompanyInfo((prev) => {
                        return { ...prev, companyOrigin: null };
                      })
                }
              />
            </div>
            <label>Country Origin</label>
            <div className={classes.selectDataLayout}>
              <SelectData
                search_by={"-- Select Country Origin --"}
                options={companyInfoSelect.countryOrigin}
                selectOnChange={(e) =>
                  e
                    ? setCompanyInfo((prev) => {
                        return { ...prev, countryOrigin: e.value };
                      })
                    : setCompanyInfo((prev) => {
                        return { ...prev, countryOrigin: null };
                      })
                }
              />
            </div>
          </div>
          <div className={classes.InputFieldDivMid}>
            <label>City/Division</label>
            <div className={classes.selectDataLayout}>
              <SelectDataEntry
                search_by={"-- Select City/Division --"}
                options={companyInfoSelect.city}
                selectOnChange={(e) =>
                  e
                    ? setCompanyInfo((prev) => {
                        return { ...prev, city: e.value };
                      })
                    : setCompanyInfo((prev) => {
                        return { ...prev, city: null };
                      })
                }
                handleInputChange={(e) => {
                  if (e) {
                    setCompanyInfo((prev) => {
                      return { ...prev, city: e.value };
                    });
                  }
                }}
              />
            </div>
            <label>Year of Origin</label>
            <div className={classes.selectDataLayout}>
              <SelectData
                search_by={"-- Select Year of Origin --"}
                options={companyInfoSelect.yearOrigin}
                selectOnChange={(e) =>
                  e
                    ? setCompanyInfo((prev) => {
                        return { ...prev, yearOrigin: e.value };
                      })
                    : setCompanyInfo((prev) => {
                        return { ...prev, yearOrigin: null };
                      })
                }
              />
            </div>
            <label>Year of Inception of Bangadesh Operation</label>
            <div className={classes.selectDataLayout}>
              <SelectData
                search_by={"-- Select Year of Inception --"}
                options={companyInfoSelect.yearInception}
                selectOnChange={(e) =>
                  e
                    ? setCompanyInfo((prev) => {
                        return { ...prev, yearInception: e.value };
                      })
                    : setCompanyInfo((prev) => {
                        return { ...prev, yearInception: null };
                      })
                }
              />
            </div>
            <label>Product/Service</label>
            <textarea
              id={classes.textArea}
              name="product_service"
              value={companyInfo.product_service}
              onChange={(e) =>
                e
                  ? setCompanyInfo((prev) => {
                      return { ...prev, product_service: e.target.value };
                    })
                  : setCompanyInfo((prev) => {
                      return { ...prev, product_service: null };
                    })
              }
            />
            <br></br>
            <label>Major Brands</label>
            <input
              type="text"
              placeholder="Major Brands"
              value={companyInfo.brands}
              onChange={(e) =>
                e
                  ? setCompanyInfo((prev) => {
                      return { ...prev, brands: e.target.value };
                    })
                  : setCompanyInfo((prev) => {
                      return { ...prev, brands: null };
                    })
              }
            />
            <label>Founder</label>
            <input
              type="text"
              placeholder="Founder"
              value={companyInfo.founder}
              onChange={(e) =>
                e
                  ? setCompanyInfo((prev) => {
                      return { ...prev, founder: e.target.value };
                    })
                  : setCompanyInfo((prev) => {
                      return { ...prev, founder: null };
                    })
              }
            />
          </div>
          <div className={classes.InputFieldDivRight}>
            <label>Manpower</label>
            <input
              type="text"
              placeholder="Manpower"
              value={companyInfo.manpower}
              onChange={(e) =>
                e
                  ? setCompanyInfo((prev) => {
                      return { ...prev, manpower: e.target.value };
                    })
                  : setCompanyInfo((prev) => {
                      return { ...prev, manpower: null };
                    })
              }
            />
            <label>Business Partner</label>
            <input
              type="text"
              placeholder="Business Partner"
              value={companyInfo.business_partner}
              onChange={(e) =>
                e
                  ? setCompanyInfo((prev) => {
                      return { ...prev, business_partner: e.target.value };
                    })
                  : setCompanyInfo((prev) => {
                      return { ...prev, business_partner: null };
                    })
              }
            />
            <label>DSC Listing Year</label>
            <div className={classes.selectDataLayout}>
              <SelectData
                search_by={"-- Select DSC Listing Year --"}
                options={companyInfoSelect.dsc_listing_year}
                selectOnChange={(e) =>
                  e
                    ? setCompanyInfo((prev) => {
                        return { ...prev, dsc_listing_year: e.value };
                      })
                    : setCompanyInfo((prev) => {
                        return { ...prev, dsc_listing_year: null };
                      })
                }
              />
            </div>
            <label>CSE Listing Year</label>
            <div className={classes.selectDataLayout}>
              <SelectData
                search_by={"-- Select CSE Listing Year --"}
                options={companyInfoSelect.cse_listing_year}
                selectOnChange={(e) =>
                  e
                    ? setCompanyInfo((prev) => {
                        return { ...prev, cse_listing_year: e.value };
                      })
                    : setCompanyInfo((prev) => {
                        return { ...prev, cse_listing_year: null };
                      })
                }
              />
            </div>
            <label>Reference</label>
            <input
              type="text"
              placeholder="Reference"
              value={companyInfo.ref}
              onChange={(e) =>
                e
                  ? setCompanyInfo((prev) => {
                      return { ...prev, ref: e.target.value };
                    })
                  : setCompanyInfo((prev) => {
                      return { ...prev, ref: null };
                    })
              }
            />
            <label>Description</label>
            <textarea
              id={classes.textArea}
              name="description"
              value={companyInfo.description}
              onChange={(e) =>
                e
                  ? setCompanyInfo((prev) => {
                      return { ...prev, description: e.target.value };
                    })
                  : setCompanyInfo((prev) => {
                      return { ...prev, description: null };
                    })
              }
            />
            <br></br>
          </div>
        </div>
      </div>
    </>
  );
}
