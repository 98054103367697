import React from "react";
import { SelectDataEntry } from "../components/AddDashboardData/SelectDataEntry/SelectDataEntry";

export const dataEntryUpdate = (
  hit,
  sl,
  id,
  currentValue,
  placeholder,
  value,
  setValue
) => {
  return hit ? (
    sl === id ? (
      <>
        <input
          type="number"
          placeholder={placeholder}
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
      </>
    ) : currentValue === "nan" || currentValue === "None" ? (
      ""
    ) : (
      currentValue
    )
  ) : currentValue === "nan" || currentValue === "None" ? (
    ""
  ) : (
    currentValue
  );
};

export const dataEntrySelectUpdate = (
  hit,
  sl,
  id,
  currentValue,
  search_by,
  options,
  setOption
) => {
  return hit ? (
    sl === id ? (
      <>
        <SelectDataEntry
          search_by={search_by}
          options={options}
          selectOnChange={(e) => (e ? setOption(e.value) : setOption(null))}
          handleInputChange={(e) => {
            if (e) {
              setOption(e);
            }
          }}
        />
      </>
    ) : currentValue === "nan" || currentValue === "None" ? (
      ""
    ) : (
      currentValue
    )
  ) : currentValue === "nan" || currentValue === "None" ? (
    ""
  ) : (
    currentValue
  );
};

export const variableToString = (varObj) => Object.keys(varObj)[0];
