import axios from "axios";
import { CSVLink } from "react-csv";
import { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import BarLoader from "react-spinners/BarLoader";
// import { Pagination } from "./Pagination/Pagination";
// import { SubscribersList } from "./SubscribersList/SubscribersList";
import { TiTick } from "react-icons/ti";
import classes from "./Subscribers.module.css";
import exportCsv from "../../asset/images/exportCsv.png";
import SubscribersLists from "./SubscribersList/SubscribersLists";
import { AlertBox, Loading, PopUpTemplate, Table } from "../Resource";
import Details from "./Details/Details";
import { api } from "../../utils/api";
import ConfirmAlert from "./ConfirmAlert/ConfirmAlert";

export function Subscribers(props) {
  var date = new Date();

  const [action, setAction] = useState(0);
  const [selectItem, setSelectItem] = useState([]);

  return props.data ? (
    <>
      <div className={classes.subscribersLayout}>
        <div>Total subscriptions: {props.data.length}</div>
        <div className={classes.active}>
          Active:{" "}
          {props.data
            ? props.data.filter((e) => e.endtime > date.toISOString()).length
            : ""}
        </div>
        <div className={classes.inactive}>
          Inactive:{" "}
          {props.data
            ? props.data.filter((e) => e.endtime < date.toISOString()).length
            : ""}
        </div>
        <div className={classes.exportCsv}>
          {props.data ? (
            <>
              <CSVLink data={props.data} className={classes.btn_export}>
                <img src={exportCsv} alt="" />
                Export All
              </CSVLink>
              <CSVLink
                data={props.data.filter((e) => e.endtime > date.toISOString())}
                className={classes.btn_export_active}
              >
                <img src={exportCsv} alt="" />
                Export Active Profile
              </CSVLink>
              <CSVLink
                data={props.data.filter((e) => e.endtime < date.toISOString())}
                className={classes.btn_export_inactive}
              >
                <img src={exportCsv} alt="" />
                Export Inactive Profile
              </CSVLink>
            </>
          ) : (
            ""
          )}
        </div>

        <SubscribersLists
          data={props.data}
          setSelectItem={setSelectItem}
          popUp={props.popUp}
          setPopUp={props.setPopUp}
          userData={props.userData}
          setUserData={props.setUserData}
          check={props.check}
          setCheck={props.setCheck}
          setAction={setAction}
        />
      </div>

      {props.popUp && action === 1 && (
        <PopUpTemplate>
          <Details
            data={selectItem}
            setPopUp={props.setPopUp}
            userData={props.userData}
            setUserData={props.setUserData}
            handleUpdateUser={props.handleUpdateUser}
            handleResetPassword={props.handleResetPassword}
            enableEdit={props.enableEdit}
            setEnableEdit={props.setEnableEdit}
            loading={props.loading}
            setAction={setAction}
          />
        </PopUpTemplate>
      )}

      {props.popUp && action === 2 && (
        <PopUpTemplate>
          <ConfirmAlert
            data={selectItem}
            setPopUp={props.setPopUp}
            loading={props.loading}
            setAction={setAction}
            // delete
            handleDelete={props.handleDelete}
          />
        </PopUpTemplate>
      )}
      {props.alertSuccess && <AlertBox alertMessage={props.alertMessage} />}
    </>
  ) : (
    <Loading size={10} color={"var(--home)"} />
  );
}
