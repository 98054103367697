import { useEffect, useState } from "react";
import BarLoader from "react-spinners/BarLoader";
import classesDashboardTable from "../../DashboardTable/DashboardTable.module.css";
import classes from "./EconomicData.module.css";
import { AllEconomicDataTable } from "./AllEconomicDataTable/AllEconomicDataTable";
import { api } from "../../../utils/api";

let ECONOMIC_DATA_API = "/Data/EconomicData/";

export const EconomicData = (props) => {
  const {
    eQuery,
    setDashboardPosition,
    setActiveDeleteData,
    dataId,
    setDataId,
  } = props;
  const [economicData, setEconomicData] = useState("");
  const [radio, setRadio] = useState("AllEco");
  const [loading, setLoading] = useState(true);
  // const [slno, setSlno] = useState(1);
  // const [pageSkip, setPageSkip] = useState(0);

  const month = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const fetchApiData = async (url) => {
    try {
      const response = await fetch(url);
      const economic_data = await response.json();
      setEconomicData(economic_data);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const getRefresh = async () => {
    if (eQuery) {
      await fetchApiData(`${api}${ECONOMIC_DATA_API}${eQuery}`);
    }
  };

  useEffect(() => {
    try {
      fetchApiData(`${api}${ECONOMIC_DATA_API}${eQuery}`);
    } catch (error) {
      console.log(error);
    }
  }, [eQuery]);

  return (
    <>
      {!eQuery ? (
        ""
      ) : loading ? (
        <>
          <BarLoader className="loading" size={15} color={"#123abc"} />
        </>
      ) : (
        <div className={classesDashboardTable.DataEntryTableLayout}>
          <div className={classes.radio}>
            <input
              type="radio"
              name="AllEco"
              value="AllEco"
              onChange={(e) => setRadio("AllEco")}
              checked={radio === "AllEco"}
            />
            <label>All</label>
            <input
              type="radio"
              name="BudgetEco"
              value="BudgetEco"
              onChange={(e) => setRadio("BudgetEco")}
              checked={radio === "BudgetEco"}
            />
            <label>Budget Year</label>
            <input
              type="radio"
              name="CalendarEco"
              value="CalendarEco"
              onChange={(e) => setRadio("CalendarEco")}
              checked={radio === "CalendarEco"}
            />
            <label>Calendar Year</label>
          </div>
          <div
            className={`${classesDashboardTable.tableWrapper} ${classesDashboardTable.tableWrapperDashboardData}`}
          >
            {economicData ? (
              <AllEconomicDataTable
                month={month}
                economicData={economicData}
                setDashboardPosition={setDashboardPosition}
                setActiveDeleteData={setActiveDeleteData}
                dataId={dataId}
                setDataId={setDataId}
                radio={radio}
                getRefresh={getRefresh}
              />
            ) : (
              "unable to catch the data"
            )}
          </div>
          {/* <Pagination
            slno={slno}
            setSlno={setSlno}
            pageSkip={pageSkip}
            setPageSkip={setPageSkip}
          /> */}
        </div>
      )}
    </>
  );
};
