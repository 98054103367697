import axios from "axios";
import React, { useState } from "react";
import { api, frontEnd } from "../../utils/api";
import classes from "../CreateProfile/CreateProfile.module.css";

export const CreateProfile = ({
  active,
  handleModel,
  errorMessage,
  setErrorMessage,
  leadPosition,
}) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [designation, setDesignation] = useState("");

  if (active) {
    document.body.classList.add(classes.activeModel);
  } else {
    document.body.classList.remove(classes.activeModel);
  }

  const cleanFormData = () => {
    setName("");
    setEmail("");
    setPassword("");
    setDesignation("");
  };

  const handleCreateProfile = async () => {
    var url = "";
    if (leadPosition === 1) {
      url = `${api}/SuperAdmin/admin/add_SuperAdmin`;
    } else if (leadPosition === 2) {
      url = `${api}/Admin/admin/add_admin`;
    } else if (leadPosition === 3) {
      url = `${api}/DataOperator/operator/add_operator`;
    }
    const response = await axios.post(
      url,
      {
        Name: name,
        Email: email,
        password: password,
        Designation: designation,
      },
      {
        headers: {
          "Access-Control-Allow-Origin": frontEnd,
        },
        withCredentials: true,
      }
    );

    if (!response) {
      setErrorMessage("something went wrong when creating super admin");
      document.getElementById("error_messages").innerHTML = errorMessage;
    } else {
      cleanFormData();
      handleModel();
    }
  };

  return active ? (
    <div className={classes.model}>
      <div className={classes.overlay} onClick={handleModel}></div>
      <div className={classes.modelContent}>
        <header className={classes.modelCardHead}>
          <h3 className={classes.modelCardTitle}>
            {leadPosition === 1
              ? "Create Super Admin"
              : leadPosition === 2
              ? "Create Admin"
              : leadPosition === 3
              ? "Create Operator"
              : "Something went wrong"}
          </h3>
        </header>
        <p id="error_messages"></p>
        <section className={classes.modelCardBody}>
          <form onSubmit={(e) => handleCreateProfile(e)}>
            <div className={classes.field}>
              <div>
                <input
                  type="text"
                  placeholder="Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                ></input>
              </div>
            </div>
            <div className={classes.field}>
              <div>
                <input
                  type="email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                ></input>
              </div>
            </div>
            <div className={classes.field}>
              <div>
                <input
                  type="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                ></input>
              </div>
            </div>
            <div className={classes.field}>
              <div>
                <input
                  type="text"
                  placeholder="Designation"
                  value={designation}
                  onChange={(e) => setDesignation(e.target.value)}
                  required
                ></input>
              </div>
            </div>
            <div className={classes.buttonCreateClickDiv}>
              <button type="submit" className={classes.buttonCreateClick}>
                Create
              </button>
            </div>
          </form>
        </section>
        <button className={classes.closeModel} onClick={handleModel}>
          X
        </button>
        <footer className={classes.modelCardFoot}></footer>
      </div>
    </div>
  ) : (
    ""
  );
};
