import axios from "axios";
import moment from "moment";
import React, { useState } from "react";
import { api, frontEnd } from "../../utils/api";
import classes from "../AddDashboardData/AddDashboardData.module.css";
import { AddBusinessData } from "./AddBusinessData/AddBusinessData";
import { AddEconomicData } from "./AddEconomicData/AddEconomicData";
import { AddIndustryData } from "./AddIndustryData/AddIndustryData";

export const AddDashboardData = ({
  active,
  handleEntryModel,
  dashboardNumber,
}) => {
  const month = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  var today = new Date();
  var dateToday = moment(today).format("D MMMM YYYY");

  const [company, setCompany] = useState("");
  const [sector, setSector] = useState("");
  const [indicator, setIndicator] = useState("");
  const [unit, setUnit] = useState("");
  const [source, setSource] = useState("");
  const [budgetYear, setBudgetYear] = useState("");
  const [budgetValue, setBudgetValue] = useState("");
  const [calendarYear, setCalendarYear] = useState("");
  const [calendarValue, setCalendarValue] = useState("");
  const [jan, setJan] = useState("");
  const [feb, setFeb] = useState("");
  const [mar, setMar] = useState("");
  const [apr, setApr] = useState("");
  const [may, setMay] = useState("");
  const [jun, setJun] = useState("");
  const [jul, setJul] = useState("");
  const [aug, setAug] = useState("");
  const [sep, setSep] = useState("");
  const [oct, setOct] = useState("");
  const [nov, setNov] = useState("");
  const [dec, setDec] = useState("");
  const [frequencyUpdate, setFrequencyUpdate] = useState("");
  const [lastUpdate, setLastUpdate] = useState(dateToday);
  const [link, setLink] = useState("");
  const [lastChecked, setLastChecked] = useState(dateToday);
  const [indicatorUpdate, setIndicatorUpdate] = useState(dateToday);

  const cleanFormData = () => {
    setCompany("");
    setSector("");
    setIndicator("");
    setUnit("");
    setSource("");
    setBudgetYear("");
    setBudgetValue("");
    setCalendarYear("");
    setCalendarValue("");
    setJan("");
    setFeb("");
    setMar("");
    setApr("");
    setMay("");
    setJun("");
    setJul("");
    setAug("");
    setSep("");
    setOct("");
    setNov("");
    setDec("");
    setFrequencyUpdate("");
    setLastUpdate(dateToday);
    setLink("");
    setLastChecked(dateToday);
    setIndicatorUpdate(dateToday);
    handleEntryModel();
  };

  const handleDashboardData = async (e) => {
    e.preventDefault();
    if (
      dashboardNumber === 1 &&
      indicator &&
      source &&
      (budgetYear || calendarYear) &&
      (budgetValue ||
        calendarValue ||
        jan ||
        feb ||
        mar ||
        apr ||
        may ||
        jun ||
        jul ||
        aug ||
        sep ||
        oct ||
        nov ||
        dec)
    ) {
      let url = `${api}/DataEntry/economic/add_data`;
      const response = await axios.post(
        url,
        {
          indicator: indicator === "" ? NaN : indicator,
          unit: unit === "" ? NaN : unit,
          source: source === "" ? NaN : source,
          budget_year: budgetYear === "" ? NaN : budgetYear,
          budget_value: budgetValue === "" ? NaN : budgetValue,
          calendar_year: calendarYear === "" ? NaN : calendarYear,
          calendar_value: calendarValue === "" ? NaN : calendarValue,
          jan: jan === "" ? NaN : jan,
          feb: feb === "" ? NaN : feb,
          mar: mar === "" ? NaN : mar,
          apr: apr === "" ? NaN : apr,
          may: may === "" ? NaN : may,
          jun: jun === "" ? NaN : jun,
          jul: jul === "" ? NaN : jul,
          aug: aug === "" ? NaN : aug,
          sep: sep === "" ? NaN : sep,
          oct: oct === "" ? NaN : oct,
          nov: nov === "" ? NaN : nov,
          dec: dec === "" ? NaN : dec,
          frequency_update: frequencyUpdate,
          last_updated: lastUpdate,
          link: link,
          last_checked: lastChecked,
        },
        {
          headers: {
            "Access-Control-Allow-Origin": frontEnd,
          },
          withCredentials: true,
        }
      );
      if (!response) {
        alert("Unable to entry the data");
      } else {
        cleanFormData();
        handleEntryModel();
      }
    } else if (
      dashboardNumber === 2 &&
      sector &&
      indicator &&
      unit &&
      source &&
      (budgetYear || calendarYear) &&
      (budgetValue ||
        calendarValue ||
        jan ||
        feb ||
        mar ||
        apr ||
        may ||
        jun ||
        jul ||
        aug ||
        sep ||
        oct ||
        nov ||
        dec)
    ) {
      let url = `${api}/DataEntry/industry/add_data`;
      const response = await axios.post(
        url,
        {
          sector: sector === "" ? NaN : sector,
          indicator: indicator === "" ? NaN : indicator,
          unit: unit === "" ? NaN : unit,
          source: source === "" ? NaN : source,
          budget_year: budgetYear === "" ? NaN : budgetYear,
          budget_value: budgetValue === "" ? NaN : budgetValue,
          calendar_year: calendarYear === "" ? NaN : calendarYear,
          calendar_value: calendarValue === "" ? NaN : calendarValue,
          jan: jan === "" ? NaN : jan,
          feb: feb === "" ? NaN : feb,
          mar: mar === "" ? NaN : mar,
          apr: apr === "" ? NaN : apr,
          may: may === "" ? NaN : may,
          jun: jun === "" ? NaN : jun,
          jul: jul === "" ? NaN : jul,
          aug: aug === "" ? NaN : aug,
          sep: sep === "" ? NaN : sep,
          oct: oct === "" ? NaN : oct,
          nov: nov === "" ? NaN : nov,
          dec: dec === "" ? NaN : dec,
          frequency_update: frequencyUpdate,
          last_updated: lastUpdate,
          link: link,
          last_checked: lastChecked,
        },
        {
          headers: {
            "Access-Control-Allow-Origin": frontEnd,
          },
          withCredentials: true,
        }
      );

      if (!response) {
        alert("Unable to entry the data");
      } else {
        cleanFormData();
        handleEntryModel();
      }
    } else if (
      dashboardNumber === 3 &&
      company &&
      sector &&
      indicator &&
      unit &&
      source &&
      (budgetYear || calendarYear) &&
      (budgetValue ||
        calendarValue ||
        jan ||
        feb ||
        mar ||
        apr ||
        may ||
        jun ||
        jul ||
        aug ||
        sep ||
        oct ||
        nov ||
        dec)
    ) {
      let url = `${api}/DataEntry/business/add_data`;
      const response = await axios.post(
        url,
        {
          company: company,
          sector: sector === "" ? NaN : sector,
          indicator: indicator === "" ? NaN : indicator,
          unit: unit === "" ? NaN : unit,
          source: source === "" ? NaN : source,
          budget_year: budgetYear,
          budget_value: budgetValue === "" ? NaN : budgetValue,
          year: calendarYear === "" ? NaN : calendarYear,
          calendar_value: calendarValue === "" ? NaN : calendarValue,
          jan: jan === "" ? NaN : jan,
          feb: feb === "" ? NaN : feb,
          mar: mar === "" ? NaN : mar,
          apr: apr === "" ? NaN : apr,
          may: may === "" ? NaN : may,
          jun: jun === "" ? NaN : jun,
          jul: jul === "" ? NaN : jul,
          aug: aug === "" ? NaN : aug,
          sep: sep === "" ? NaN : sep,
          oct: oct === "" ? NaN : oct,
          nov: nov === "" ? NaN : nov,
          dec: dec === "" ? NaN : dec,
          frequency_update: frequencyUpdate === "" ? NaN : frequencyUpdate,
          last_updated: lastUpdate,
          link: link,
          last_checked: lastChecked,
          indicator_update: indicatorUpdate,
        },
        {
          headers: {
            "Access-Control-Allow-Origin": frontEnd,
          },
          withCredentials: true,
        }
      );

      if (!response) {
        alert("Unable to entry the data");
      } else {
        cleanFormData();
        handleEntryModel();
      }
    } else {
      alert("Please fill out this field properly!");
    }
  };

  return active ? (
    <div className={classes.model}>
      <header className={classes.modelCardHead}>
        <h5 className={classes.modelCardTitle}>
          {dashboardNumber === 1
            ? "ADD DATA FOR ECONOMIC DASHBOARD"
            : dashboardNumber === 2
            ? "ADD DATA FOR INDUSTRY DASHBOARD"
            : dashboardNumber === 3
            ? "ADD DATA FOR BUSINESS DASHBOARD"
            : "Something went wrong"}
        </h5>
        <p className={classes.error_messages}></p>
      </header>
      {dashboardNumber === 1 ? (
        <section className={classes.modelCardBody}>
          <form onSubmit={(e) => handleDashboardData(e)}>
            <div className={`${classes.tableWrapper} ${classes.tdColor}`}>
              <AddEconomicData
                api={api}
                month={month}
                indicator={indicator}
                // unit={unit}
                // source={source}
                budgetYear={budgetYear}
                budgetValue={budgetValue}
                calendarYear={calendarYear}
                calendarValue={calendarValue}
                jan={jan}
                feb={feb}
                mar={mar}
                apr={apr}
                may={may}
                jun={jun}
                jul={jul}
                aug={aug}
                sep={sep}
                oct={oct}
                nov={nov}
                dec={dec}
                // frequencyUpdate={frequencyUpdate}
                lastUpdate={lastUpdate}
                lastChecked={lastChecked}
                setIndicator={setIndicator}
                setUnit={setUnit}
                setSource={setSource}
                setBudgetYear={setBudgetYear}
                setBudgetValue={setBudgetValue}
                setCalendarYear={setCalendarYear}
                setCalendarValue={setCalendarValue}
                setJan={setJan}
                setFeb={setFeb}
                setMar={setMar}
                setApr={setApr}
                setMay={setMay}
                setJun={setJun}
                setJul={setJul}
                setAug={setAug}
                setSep={setSep}
                setOct={setOct}
                setNov={setNov}
                setDec={setDec}
                setFrequencyUpdate={setFrequencyUpdate}
                // setLastUpdate={setLastUpdate}
                setLink={setLink}
                // setLastChecked={setLastChecked}
              />
            </div>
            <br></br>
            <div className={classes.button}>
              <button className={classes.buttonCreate} type="submit">
                CREATE
              </button>
              <button
                className={classes.buttonClose}
                type="button"
                onClick={cleanFormData}
              >
                CANCEL
              </button>
            </div>
          </form>
        </section>
      ) : dashboardNumber === 2 ? (
        <section className={classes.modelCardBody}>
          <form onSubmit={(e) => handleDashboardData(e)}>
            <div className={`${classes.tableWrapper} ${classes.tdColor}`}>
              <AddIndustryData
                api={api}
                month={month}
                // sector={sector}
                indicator={indicator}
                // unit={unit}
                // source={source}
                budgetYear={budgetYear}
                budgetValue={budgetValue}
                calendarYear={calendarYear}
                calendarValue={calendarValue}
                jan={jan}
                feb={feb}
                mar={mar}
                apr={apr}
                may={may}
                jun={jun}
                jul={jul}
                aug={aug}
                sep={sep}
                oct={oct}
                nov={nov}
                dec={dec}
                // region={region}
                // frequencyUpdate={frequencyUpdate}
                lastUpdate={lastUpdate}
                link={link}
                lastChecked={lastChecked}
                setSector={setSector}
                setIndicator={setIndicator}
                setUnit={setUnit}
                setSource={setSource}
                setBudgetYear={setBudgetYear}
                setBudgetValue={setBudgetValue}
                setCalendarYear={setCalendarYear}
                setCalendarValue={setCalendarValue}
                setJan={setJan}
                setFeb={setFeb}
                setMar={setMar}
                setApr={setApr}
                setMay={setMay}
                setJun={setJun}
                setJul={setJul}
                setAug={setAug}
                setSep={setSep}
                setOct={setOct}
                setNov={setNov}
                setDec={setDec}
                setFrequencyUpdate={setFrequencyUpdate}
                // setLastUpdate={setLastUpdate}
                setLink={setLink}
                // setLastChecked={setLastChecked}
              />
            </div>
            <br></br>
            <div className={classes.button}>
              <button type="submit" className={classes.buttonCreate}>
                CREATE
              </button>
              <button
                type="button"
                className={classes.buttonClose}
                onClick={cleanFormData}
              >
                CANCEL
              </button>
            </div>
          </form>
        </section>
      ) : dashboardNumber === 3 ? (
        <section className={classes.modelCardBody}>
          <form onSubmit={(e) => handleDashboardData(e)}>
            <div className={`${classes.tableWrapper} ${classes.tdColor}`}>
              <AddBusinessData
                api={api}
                month={month}
                company={company}
                // sector={sector}
                indicator={indicator}
                // unit={unit}
                // source={source}
                budgetYear={budgetYear}
                budgetValue={budgetValue}
                calendarYear={calendarYear}
                calendarValue={calendarValue}
                jan={jan}
                feb={feb}
                mar={mar}
                apr={apr}
                may={may}
                jun={jun}
                jul={jul}
                aug={aug}
                sep={sep}
                oct={oct}
                nov={nov}
                dec={dec}
                lastUpdate={lastUpdate}
                link={link}
                lastChecked={lastChecked}
                indicatorUpdate={indicatorUpdate}
                setCompany={setCompany}
                setSector={setSector}
                setIndicator={setIndicator}
                setUnit={setUnit}
                setSource={setSource}
                setBudgetYear={setBudgetYear}
                setBudgetValue={setBudgetValue}
                setCalendarYear={setCalendarYear}
                setCalendarValue={setCalendarValue}
                setJan={setJan}
                setFeb={setFeb}
                setMar={setMar}
                setApr={setApr}
                setMay={setMay}
                setJun={setJun}
                setJul={setJul}
                setAug={setAug}
                setSep={setSep}
                setOct={setOct}
                setNov={setNov}
                setDec={setDec}
                setFrequencyUpdate={setFrequencyUpdate}
                // setLastUpdate={setLastUpdate}
                setLink={setLink}
                // setLastChecked={setLastChecked}
                // setIndicatorUpdate={setIndicatorUpdate}
              />
            </div>
            <br></br>
            <div className={classes.button}>
              <button type="submit" className={classes.buttonCreate}>
                CREATE
              </button>
              <button
                type="button"
                className={classes.buttonClose}
                onClick={cleanFormData}
              >
                CANCEL
              </button>
            </div>
          </form>
        </section>
      ) : null}
    </div>
  ) : (
    ""
  );
};
