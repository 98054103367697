import { Fragment, useState } from "react";
import { CreateProfile } from "../CreateProfile/CreateProfile";
import { SuperAdminList } from "./SuperAdminList/SuperAdminList";
import { AdminList } from "./AdminList/AdminList";
import { OperatorList } from "./OperatorList/OperatorList";
import classes from "./AdminSettings.module.css";
import { DeleteProfile } from "./DeleteProfile/DeleteProfile";
import { Loading } from "../Resource";

export function AdminSettings(props) {
  const [errorMessage, setErrorMessage] = useState("");
  const [leadPosition, setLeadPosition] = useState("");
  const [email, setEmail] = useState("");

  return props.superAdmin ? (
    <Fragment>
      <DeleteProfile
        activeDeleteModel={props.activeDeleteModel}
        handleDeleteModel={props.handleDeleteModel}
        leadPosition={leadPosition}
        email={email}
      />

      {props.loggedUserInfo && props.loggedUserInfo.role === "SuperAdmin" && (
        <>
          <div className={classes.adminListLayout}>
            <h4>Super Admin List</h4>
            <CreateProfile
              active={props.activeModel}
              handleModel={props.handleModel}
              setErrorMessage={setErrorMessage}
              errorMessage={errorMessage}
              leadPosition={leadPosition}
            />

            <SuperAdminList
              superAdmin={props.superAdmin}
              setLeadPosition={setLeadPosition}
              setActiveDeleteModel={props.setActiveDeleteModel}
              setEmail={setEmail}
            />
            <button
              className={classes.buttonCreate}
              onClick={() => {
                setLeadPosition(1);
                props.setActiveModel(true);
              }}
            >
              + Create Super Admin
            </button>
          </div>

          <div className={classes.adminListLayout}>
            <h4>Admin List</h4>
            <CreateProfile
              active={props.activeModel}
              handleModel={props.handleModel}
              setErrorMessage={setErrorMessage}
              errorMessage={errorMessage}
              leadPosition={leadPosition}
            />

            <AdminList
              admin={props.admin}
              setLeadPosition={setLeadPosition}
              setActiveDeleteModel={props.setActiveDeleteModel}
              setEmail={setEmail}
              // handleDeleteLead={handleDeleteLead}
            />

            <button
              className={classes.buttonCreate}
              onClick={() => {
                setLeadPosition(2);
                props.setActiveModel(true);
              }}
            >
              + Create Admin
            </button>
          </div>
        </>
      )}

      <div className={classes.adminListLayout}>
        <h4>Operator List</h4>
        <CreateProfile
          active={props.activeModel}
          handleModel={props.handleModel}
          setErrorMessage={setErrorMessage}
          errorMessage={errorMessage}
          leadPosition={leadPosition}
        />

        <OperatorList
          operator={props.operator}
          setLeadPosition={setLeadPosition}
          setActiveDeleteModel={props.setActiveDeleteModel}
          setEmail={setEmail}
          // handleDeleteLead={handleDeleteLead}
        />
        <button
          className={classes.buttonCreate}
          onClick={() => {
            setLeadPosition(3);
            props.setActiveModel(true);
          }}
        >
          + Create Operator
        </button>
      </div>
    </Fragment>
  ) : (
    <Loading size={15} color={"var(--home-color)"} />
  );
}
