import {
  UserDefStorage,
  UserGetStorage,
  UserSetStorage,
} from "../storage/storage";

export const userState = UserDefStorage("user", { info: {} });

export const userReducer = (state, action) => {
  switch (action.type) {
    case "get":
      UserSetStorage("user", { info: state.info });
      return UserGetStorage("user");
    case "set":
      UserSetStorage("user", { info: action.payload });
      return UserGetStorage("user");
    case "remove":
      UserSetStorage("user", { info: {} });
      return UserGetStorage("user");
    default:
      return state;
  }
};
