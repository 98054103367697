import indicator_icon from "../../asset/images/e1.png";
import sector_icon from "../../asset/images/e2.png";
import company_icon from "../../asset/images/e3.png";
import datapoint_icon from "../../asset/images/e4.png";
import total_indicator_icon from "../../asset/images/e5.png";
import { Loading } from "../Resource";
import classes from "./Dashboards.module.css";

export function Dashboards(props) {
  return props.loggedUserInfo.msg === "found_token" ? (
    <>
      <div className={classes.total_dashboard}>
        <h6>Total Dashboard Overview</h6>
        <div className={classes.body_dashboard}>
          <div>
            <div>
              <img src={datapoint_icon} alt="data_point" />
            </div>
            <p className={classes.p_title}>Data point</p>
            <p className={classes.p_number}>{". . ."}</p>
          </div>
          <div>
            <div>
              <img src={total_indicator_icon} alt="indicator" />
            </div>
            <p className={classes.p_title}>Total Indicator</p>
            <p className={classes.p_number}>
              {props.data.totalIndicator ? (
                props.data.totalIndicator
              ) : (
                <Loading size={10} color={"var(--home-hover)"} />
              )}
            </p>
          </div>
          <div>
            <div>
              <img src={indicator_icon} alt="indicator" />
            </div>
            <p className={classes.p_title}>Unique Indicator</p>
            <p className={classes.p_number}>
              {props.data.totalUniqueIndicator ? (
                props.data.totalUniqueIndicator
              ) : (
                <Loading size={10} color={"var(--home-hover)"} />
              )}
            </p>
          </div>
          <div>
            <div>
              <img src={sector_icon} alt="sector" />
            </div>
            <p className={classes.p_title}>Sector</p>
            <p className={classes.p_number}>
              {props.loggedUserInfo.msg === "found_token" &&
              props.data.businessSector &&
              props.data.industrySector ? (
                props.data.businessSector + props.data.industrySector
              ) : (
                <Loading size={10} color={"var(--home-hover)"} />
              )}
            </p>
          </div>
          <div>
            <div>
              <img src={company_icon} alt="company" />
            </div>
            <p className={classes.p_title}>Company</p>
            <p className={classes.p_number}>
              {props.loggedUserInfo.msg === "found_token" &&
              props.data.businessCompanyTotal ? (
                props.data.businessCompanyTotal
              ) : (
                <Loading size={10} color={"var(--home-hover)"} />
              )}
            </p>
          </div>
        </div>
      </div>

      {/* Economic and Industry */}
      <div className={classes.eco_bus_ind}>
        {/* Economic */}
        <div className={classes.eco_bus_ind1}>
          <h5>Economic Dashboard</h5>
          <div>
            <div>
              <div>
                <img src={total_indicator_icon} alt="indicator" />
              </div>
              <p className={classes.p_title}>Total Indicator</p>
              <p className={classes.p_number}>
                {props.data.totalEconomyIndicator ? (
                  props.data.totalEconomyIndicator
                ) : (
                  <Loading size={10} color={"var(--home-hover)"} />
                )}
              </p>
            </div>

            <div>
              <div>
                <img src={indicator_icon} alt="indicator" />
              </div>
              <p className={classes.p_title}>Unique Indicator</p>
              <p className={classes.p_number}>
                {props.data.uniqueEconomyIndicator ? (
                  props.data.uniqueEconomyIndicator
                ) : (
                  <Loading size={10} color={"var(--home-hover)"} />
                )}
              </p>
            </div>
          </div>
        </div>

        {/* Industry */}
        <div className={classes.eco_bus_ind2}>
          <h5>Industry Dashboard</h5>
          <div>
            <div>
              <div>
                <img src={total_indicator_icon} alt="indicator" />
              </div>
              <p className={classes.p_title}>Total Indicator</p>
              <p className={classes.p_number}>
                {props.data.totalIndustryIndicator ? (
                  props.data.totalIndustryIndicator
                ) : (
                  <Loading size={10} color={"var(--home-hover)"} />
                )}
              </p>
            </div>
            <div>
              <div>
                <img src={indicator_icon} alt="indicator" />
              </div>
              <p className={classes.p_title}>Unique Indicator</p>
              <p className={classes.p_number}>
                {props.data.uniqueIndustryIndicator ? (
                  props.data.uniqueIndustryIndicator
                ) : (
                  <Loading size={10} color={"var(--home-hover)"} />
                )}
              </p>
            </div>
            <div>
              <div>
                <img src={sector_icon} alt="indicator" />
              </div>
              <p className={classes.p_title}>Sector</p>
              <p className={classes.p_number}>
                {props.data.industrySector ? (
                  props.data.industrySector
                ) : (
                  <Loading size={10} color={"var(--home-hover)"} />
                )}
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Business */}
      <div className={classes.eco_bus_ind3}>
        <h5>Company Dashboard</h5>
        <div>
          <div>
            <div>
              <img src={total_indicator_icon} alt="indicator" />
            </div>
            <p className={classes.p_title}>Total Indicator</p>
            <p className={classes.p_number}>
              {props.data.totalBusinessIndicator ? (
                props.data.totalBusinessIndicator
              ) : (
                <Loading size={10} color={"var(--home-hover)"} />
              )}
            </p>
          </div>
          <div>
            <div>
              <img src={indicator_icon} alt="indicator" />
            </div>
            <p className={classes.p_title}>Unique Indicator</p>
            <p className={classes.p_number}>
              {props.data.uniqueBusinessIndicator ? (
                props.data.uniqueBusinessIndicator
              ) : (
                <Loading size={10} color={"var(--home-hover)"} />
              )}
            </p>
          </div>
          <div>
            <div>
              <img src={sector_icon} alt="indicator" />
            </div>
            <p className={classes.p_title}>Sector</p>
            <p className={classes.p_number}>
              {props.data.businessSector ? (
                props.data.businessSector
              ) : (
                <Loading size={10} color={"var(--home-hover)"} />
              )}
            </p>
          </div>
          <div>
            <div>
              <img src={company_icon} alt="indicator" />
            </div>
            <p className={classes.p_title}>Company</p>
            <p className={classes.p_number}>
              {props.data.businessCompanyTotal ? (
                props.data.businessCompanyTotal
              ) : (
                <Loading size={10} color={"var(--home-hover)"} />
              )}
            </p>
          </div>
        </div>
      </div>
    </>
  ) : (
    <Loading size={10} color={"var(--home-hover)"} />
  );
}
