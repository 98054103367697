import React from "react";
import classes from "./ContactInformation.module.css";

export default function ContactInformation(props) {
  const { contactInfo, setContactInfo } = props;
  return (
    <>
      <div className={classes.AllData}>
        <h5>Contact Information</h5>
        <div className={classes.InputFieldDiv}>
          <div className={classes.InputFieldDivLeft}>
            <label>ADDRESS - CORPORATE OFFICE</label>
            <input
              type="text"
              placeholder="ADDRESS - CORPORATE OFFICE              "
              value={contactInfo.address_corporate_office}
              onChange={(e) =>
                e
                  ? setContactInfo((prev) => {
                      return {
                        ...prev,
                        address_corporate_office: e.target.value,
                      };
                    })
                  : setContactInfo((prev) => {
                      return { ...prev, address_corporate_office: null };
                    })
              }
            />
            <label>ADDRESS - MAIN FACTORY</label>
            <input
              type="text"
              placeholder="ADDRESS - MAIN FACTORY"
              value={contactInfo.address_main_factory}
              onChange={(e) =>
                e
                  ? setContactInfo((prev) => {
                      return { ...prev, address_main_factory: e.target.value };
                    })
                  : setContactInfo((prev) => {
                      return { ...prev, address_main_factory: null };
                    })
              }
            />

            <label>Website</label>
            <input
              type="text"
              placeholder="Website"
              value={contactInfo.website}
              onChange={(e) =>
                e
                  ? setContactInfo((prev) => {
                      return { ...prev, website: e.target.value };
                    })
                  : setContactInfo((prev) => {
                      return { ...prev, website: null };
                    })
              }
            />
          </div>

          <div className={classes.InputFieldDivMid}>
            <label>E-Mail Address</label>
            <textarea
              id={classes.textArea}
              name="E-Mail Address"
              value={contactInfo.email}
              onChange={(e) =>
                e
                  ? setContactInfo((prev) => {
                      return { ...prev, email: e.target.value };
                    })
                  : setContactInfo((prev) => {
                      return { ...prev, email: null };
                    })
              }
            />
            <br></br>
            <label>Contact Number</label>
            <textarea
              id={classes.textArea}
              name="Contact Number"
              value={contactInfo.phone}
              onChange={(e) =>
                e
                  ? setContactInfo((prev) => {
                      return { ...prev, phone: e.target.value };
                    })
                  : setContactInfo((prev) => {
                      return { ...prev, phone: null };
                    })
              }
            />
            <br></br>
            <label>Other Info</label>
            <textarea
              id={classes.textArea}
              name="Other Info"
              value={contactInfo.other_info}
              onChange={(e) =>
                e
                  ? setContactInfo((prev) => {
                      return { ...prev, other_info: e.target.value };
                    })
                  : setContactInfo((prev) => {
                      return { ...prev, other_info: null };
                    })
              }
            />
          </div>
        </div>
      </div>
    </>
  );
}
