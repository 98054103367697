import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import DataTable from "react-data-table-component";
import { Dna } from "react-loader-spinner";
import moment from "moment";
import classes from "./SubscribersLists.module.css";
import { Loading } from "../../Resource";
import { MdDeleteForever } from "react-icons/md";
import { FaEdit } from "react-icons/fa";
import { GoDotFill } from "react-icons/go";
import { TiTick } from "react-icons/ti";

const SubscribersLists = (props) => {
  const [search, setSearch] = useState("");
  const [subscriberList, setSubscriberList] = useState([]);
  const [filteredData, setfilteredData] = useState([]);
  const [pending, setPending] = useState(true);

  var date = new Date();

  useEffect(() => {
    const getSubscriberList = async () => {
      // const allUser_Data = await axios.get(`${api}/Data/AllUserData`);
      // const all_user_data = await allUser_Data.data;
      setSubscriberList(props.data);
      setfilteredData(props.data);
      setPending(false);
    };
    getSubscriberList();
  }, [props.data]);

  useEffect(() => {
    var date = new Date();

    const result = props.data?.filter((data) => {
      const activeData = data.endtime > date.toISOString();
      const inactiveData = data.endtime < date.toISOString();
      const active = "Active";
      const inactive = "Inactive";
      return (
        data.name.toLowerCase().match(search.toLowerCase()) ||
        data.email.toLowerCase().match(search.toLowerCase()) ||
        data.company.toLowerCase().match(search.toLowerCase()) ||
        data.designation.toLowerCase().match(search.toLowerCase()) ||
        data.role.toLowerCase().match(search.toLowerCase()) ||
        (active.toLowerCase().match(search.toLowerCase())
          ? activeData
          : inactive.toLowerCase().match(search.toLowerCase())
          ? inactiveData
          : null)
      );
    });

    setfilteredData(result);
  }, [search, subscriberList, props.data]);

  // A super simple expandable component
  // const ExpandedComponent = ({ data }) => (
  //   <pre>
  //     {JSON.stringify(
  //       data,
  //       ["name", "designation", "company", "email", "phone", "role"],
  //       2
  //     )}
  //   </pre>
  // );

  const columns = [
    {
      name: "#",
      cell: (row, index) => index + 1,
      // render: function (data, type, row, meta) {
      //   return meta.row + meta.settings._iDisplayStart + 1;
      // },
      button: true,
      width: "3rem",
    },
    {
      name: "Name",
      selector: (row) => (
        <div
          onClick={() => {
            props.setAction(1);
            props.setPopUp(true);
            props.setSelectItem(row);
          }}
          className={classes.name}
        >
          {row.name}
        </div>
      ),
      width: "12rem",
    },
    {
      name: "Email",
      selector: (row) => row.email,
      width: "14rem",
    },
    {
      name: "Phone number",
      selector: (row) => row.phone || row.phone_number,
      width: "9rem",
    },
    {
      name: "Company",
      selector: (row) => row.company,
      width: "15rem",
    },
    {
      name: "Designation",
      selector: (row) => row.designation,
      width: "15rem",
    },
    {
      name: "Country",
      selector: (row) => row.country,
      width: "10rem",
    },
    {
      name: "Role",
      selector: (row) =>
        row.role === "individual_user"
          ? "Individual User"
          : row.role === "standard_m"
          ? "Standard Monthly"
          : row.role === "standard_y"
          ? "Standard Yearly"
          : row.role,
      width: "8rem",
    },
    {
      name: "Reg. start",
      selector: (row) => moment(row.regitime).format("D-MMM-YY"),
    },
    {
      name: "Reg. end",
      selector: (row) => moment(row.endtime).format("D-MMM-YY"),
    },
    {
      name: "Time left",
      selector: (row) => {
        const a = moment(row.endtime);
        const b = moment(date.toISOString());
        const left = a.diff(b, "days");
        if (row.endtime > date.toISOString()) {
          if (left < 0) {
            return (
              <span style={{ color: "rgb(168, 0, 0)", fontWeight: "bold" }}>
                {" "}
                Date expired
              </span>
            );
          } else if (left < 8) {
            return (
              <span style={{ color: "rgb(236, 181, 0)", fontWeight: "bold" }}>
                {left === 0 ? (
                  <span style={{ color: "#ff3700", fontWeight: "bold" }}>
                    Expire soon
                  </span>
                ) : left === 1 ? (
                  left + " day left"
                ) : (
                  left + " days left"
                )}
              </span>
            );
          }
          return (
            <span style={{ color: "#198754", fontWeight: "bold" }}>
              {left} days left
            </span>
          );
        } else {
          return (
            <span style={{ color: "rgb(168, 0, 0)", fontWeight: "bold" }}>
              {" "}
              Date expired
            </span>
          );
        }
      },
      width: "8rem",
    },
    {
      name: "Status",
      selector: (row) =>
        row.endtime > date.toISOString() ? (
          <span style={{ color: "rgb(0, 77, 0)", fontWeight: "bold" }}>
            Active
          </span>
        ) : (
          <span style={{ color: "rgb(168, 0, 0)", fontWeight: "bold" }}>
            Inactive
          </span>
        ),
      width: "6rem",
    },
    {
      name: "Access",
      selector: (row) => (
        <div className={classes.access}>
          {row.restriction === 1 ? (
            <TiTick className={classes.activeAccess} />
          ) : (
            <GoDotFill className={classes.delete} />
          )}
        </div>
      ),
      width: "5rem",
    },
    {
      name: "Clicked <|> Limit ",
      selector: (row) => (
        <div className={classes.limit}>
          {row.download !== undefined ? row.download : "--"}
          {" <|> "}
          {row.download_limit !== undefined ? row.download_limit : "--"}
        </div>
      ),
      width: "8rem",
    },
    {
      name: "Action",
      selector: (row) => (
        <div className={classes.action}>
          <FaEdit
            onClick={() => {
              props.setAction(1);
              props.setPopUp(true);
              props.setSelectItem(row);
            }}
            className={classes.edit}
          />
          <MdDeleteForever
            onClick={() => {
              props.setAction(2);
              props.setPopUp(true);
              props.setSelectItem(row);
            }}
            className={classes.delete}
          />
        </div>
      ),
      width: "5rem",
    },
  ];

  const customStyles = {
    headCells: {
      style: {
        backgroundColor: "#00005f",
        color: "#ffff",
      },
    },
  };

  useEffect(() => {}, [search]);

  return (
    <>
      <DataTable
        dense
        columns={columns}
        data={filteredData}
        pagination
        fixedHeader
        subHeader
        subHeaderComponent={
          <input
            type="text"
            placeholder="Search here ..."
            className={classes.searchBar}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        }
        customStyles={customStyles}
        noDataComponent={<Loading size={15} color={"var(--home)"} />}
        progressPending={pending}
        progressComponent={
          <Dna
            visible={true}
            height="80"
            width="80"
            ariaLabel="dna-loading"
            wrapperStyle={{}}
            wrapperClass="dna-wrapper"
          />
        }
      />
    </>
  );
};

export default SubscribersLists;
