import { Fragment, useEffect, useState } from "react";
import { SelectData } from "../../DataEntryPanel/SelectData/SelectData";
import { SelectDataEntry } from "../SelectDataEntry/SelectDataEntry";
import classes from "../AddDashboardData.module.css";

export function AddIndustryData(props) {
  const {
    api,
    month,
    indicator,
    budgetYear,
    budgetValue,
    calendarYear,
    calendarValue,
    jan,
    feb,
    mar,
    apr,
    may,
    jun,
    jul,
    aug,
    sep,
    oct,
    nov,
    dec,
    lastUpdate,
    link,
    lastChecked,
    setSector,
    setIndicator,
    setUnit,
    setSource,
    setBudgetYear,
    setBudgetValue,
    setCalendarYear,
    setCalendarValue,
    setJan,
    setFeb,
    setMar,
    setApr,
    setMay,
    setJun,
    setJul,
    setAug,
    setSep,
    setOct,
    setNov,
    setDec,
    setFrequencyUpdate,
    setLink,
    setLastChecked,
  } = props;

  const [industryIndicator, setIndustryIndicator] = useState([]);
  const [industrySector, setIndustrySector] = useState([]);
  const [industryUnit, setIndustryUnit] = useState([]);
  const [industrySource, setIndustrySource] = useState([]);
  const [budgetYearOptions, setBudgetYearOptions] = useState([]);
  const [calendarYearOptions, setCalendarYearOptions] = useState([]);
  const [frequencyUpdateOptions, setFrequencyUpdateOptions] = useState([]);
  const [restrict, setRestrict] = useState(3);
  const [checkBudgetValue, setCheckBudgetValue] = useState("");
  const [checkCalendarValue, setCheckCalendarValue] = useState("");

  useEffect(() => {
    const getInfo = async () => {
      let indIndicator = [];
      let indSector = [];
      let indUnit = [];
      let indSource = [];
      let budgetyear = [];
      let calendaryear = [];

      const frequencyUpdateOptionsAll = [
        { value: "Monthly", label: "Monthly" },
        { value: "Quarterly", label: "Quarterly" },
        { value: "Yearly", label: "Yearly" },
        { value: "Budget Yearly", label: "Budget Yearly" },
      ];
      setFrequencyUpdateOptions(frequencyUpdateOptionsAll);

      for (let i = 2000; i < 2070; i++) {
        const x = i;
        const y = i + 1;
        budgetyear.push({ label: x + "-" + y, value: x + "-" + y });
      }
      for (let i = 2000; i < 2050; i++) {
        const m = i;
        const n = i;
        calendaryear.push({ label: m, value: n });
      }

      if (!calendarYear && budgetYear) {
        setCalendarYearOptions([]);
        setRestrict(1);
      } else if (calendarYear && !budgetYear) {
        setBudgetYearOptions([]);
        setRestrict(2);
      } else {
        setBudgetYearOptions(budgetyear);
        setCalendarYearOptions(calendaryear);
        setRestrict(3);
      }

      // fetch list of industry indicators
      const industry_Indicator = await fetch(
        `${api}/Data/Industry_Data/listofindicators`
      );
      const industryIndicator = await industry_Indicator.json();
      await industryIndicator.map((e) =>
        indIndicator.push({ label: e, value: e })
      );
      setIndustryIndicator(indIndicator);

      if (indicator) {
        // fetch sector
        const industry_Sector = await fetch(
          `${api}/Data/Industry_Data/listofsectors`
        );
        const industrySector = await industry_Sector.json();

        // fetch unit
        const industry_Unit = await fetch(`${api}/Data/Industry/allunit`);
        const industryUnit = await industry_Unit.json();

        // fetch source
        const industry_Source = await fetch(`${api}/Data/Industry/allsource`);
        const industrySource = await industry_Source.json();

        // fetch sector by indicator
        const industry_Sector_by_indicator = await fetch(
          `${api}/Data/Industry/listofsector/${indicator}`
        );
        const industrySector_by_indicator =
          await industry_Sector_by_indicator.json();

        // fetch unit by indicator
        const industry_Unit_by_indicator = await fetch(
          `${api}/Data/Industry/listofunit/${indicator}`
        );
        const industryUnit_by_indicator =
          await industry_Unit_by_indicator.json();

        // fetch source by indicator
        const industry_Source_by_indicator = await fetch(
          `${api}/Data/Industry/listofsource/${indicator}`
        );
        const industrySource_by_indicator =
          await industry_Source_by_indicator.json();

        // fetch budget value by indicator and budget year
        if (indicator && budgetYear) {
          const check_BudgetValue = await fetch(
            `${api}/DataCheck/Industry/searchdata5/${indicator}/${budgetYear}`
          );
          const checkBudget_Value = await check_BudgetValue.json();
          setCheckBudgetValue(checkBudget_Value);
        }

        // fetch calendar value by indicator and calendar year
        if (indicator && calendarYear) {
          const check_CalendarValue = await fetch(
            `${api}/DataCheck/Industry/searchdata6/${indicator}/${calendarYear}`
          );
          const checkCalendar_Value = await check_CalendarValue.json();
          setCheckCalendarValue(checkCalendar_Value);
        }

        if (
          industrySector_by_indicator.length !== 0 &&
          industryUnit_by_indicator.length !== 0 &&
          industrySource_by_indicator.length !== 0
        ) {
          // set sector
          await industrySector_by_indicator.map((e) =>
            indSector.push({ label: e, value: e })
          );
          setIndustrySector(indSector);

          // set unit
          await industryUnit_by_indicator.map((e) =>
            indUnit.push({ label: e, value: e })
          );
          setIndustryUnit(indUnit);

          // set source
          await industrySource_by_indicator.map((e) =>
            indSource.push({ label: e, value: e })
          );
          setIndustrySource(indSource);
        } else if (
          industrySector_by_indicator.length === 0 &&
          industryUnit_by_indicator.length === 0 &&
          industrySource_by_indicator.length === 0 &&
          industrySector.length !== 0 &&
          industryUnit.length !== 0 &&
          industrySource.length !== 0
        ) {
          // set sector
          await industrySector.map((e) =>
            indSector.push({ label: e, value: e })
          );
          setIndustrySector(indSector);

          // set unit
          await industryUnit.map((e) => indUnit.push({ label: e, value: e }));
          setIndustryUnit(indUnit);

          // set source
          await industrySource.map((e) =>
            indSource.push({ label: e, value: e })
          );
          setIndustrySource(indSource);
        } else {
          setIndustrySector([]);
          setSector("");
          setIndustryUnit([]);
          setUnit("");
          setIndustrySource([]);
          setSource("");
        }
      }
    };
    getInfo();
  }, [indicator, budgetYear, calendarYear, setSector, setSource, setUnit, api]);

  return (
    <>
      <table>
        <thead>
          <tr>
            <th>Indicator</th>
            <th>Sector</th>
            <th>Unit</th>
            <th>Source</th>
            {restrict === 1 || restrict === 3 ? <th>Budget Year</th> : ""}
            {restrict === 1 || restrict === 3 ? <th>Budget Value</th> : ""}
            {restrict === 2 || restrict === 3 ? <th>Calendar Year</th> : ""}
            {restrict === 2 || restrict === 3 ? <th>Calendar Value</th> : ""}
            {restrict === 2 || restrict === 3
              ? month.map((e, key) => <th key={key}>{e}</th>)
              : ""}
            <th>Frequency Update</th>
            <th>Last Update</th>
            <th>Link</th>
            <th>Last Checked</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <SelectDataEntry
                search_by={"Indicator"}
                options={industryIndicator}
                selectOnChange={(e) =>
                  e ? setIndicator(e.value) : setIndicator(null)
                }
                handleInputChange={(e) => {
                  if (e) {
                    setIndicator(e);
                  }
                }}
              />
            </td>
            <td>
              <SelectDataEntry
                search_by={"Sector"}
                options={industrySector}
                selectOnChange={(e) =>
                  e ? setSector(e.value) : setSector(null)
                }
                handleInputChange={(e) => {
                  if (e) {
                    setSector(e);
                  }
                }}
              />
            </td>
            <td>
              <SelectDataEntry
                search_by={"Unit"}
                options={industryUnit}
                selectOnChange={(e) => (e ? setUnit(e.value) : setUnit(null))}
                handleInputChange={(e) => {
                  if (e) {
                    setUnit(e);
                  }
                }}
              />
            </td>
            <td>
              <SelectDataEntry
                search_by={"Source"}
                options={industrySource}
                selectOnChange={(e) =>
                  e ? setSource(e.value) : setSource(null)
                }
                handleInputChange={(e) => {
                  if (e) {
                    setSource(e);
                  }
                }}
              />
            </td>
            {restrict === 1 || restrict === 3 ? (
              <td>
                <SelectData
                  search_by={"Budget Year"}
                  options={budgetYearOptions}
                  selectOnChange={(e) =>
                    e ? setBudgetYear(e.value) : setBudgetYear(null)
                  }
                />
              </td>
            ) : (
              ""
            )}
            {restrict === 1 || restrict === 3 ? (
              <td>
                <input
                  className={
                    checkCalendarValue && calendarYear ? classes.redAlert : null
                  }
                  type="text"
                  placeholder="Budget Value"
                  value={budgetValue}
                  onChange={(e) => {
                    checkBudgetValue
                      ? setBudgetValue([])
                      : setBudgetValue(e.target.value);
                  }}
                />
              </td>
            ) : (
              ""
            )}
            {restrict === 2 || restrict === 3 ? (
              <td>
                <SelectData
                  search_by={"Calendar Year"}
                  options={calendarYearOptions}
                  selectOnChange={(e) =>
                    e ? setCalendarYear(e.value) : setCalendarYear(null)
                  }
                />
              </td>
            ) : (
              ""
            )}
            {restrict === 2 || restrict === 3 ? (
              <td>
                <input
                  className={
                    checkCalendarValue && calendarYear ? classes.redAlert : null
                  }
                  type="number"
                  placeholder="Calender Value"
                  value={calendarValue}
                  onChange={(e) => {
                    checkCalendarValue
                      ? setCalendarValue([])
                      : setCalendarValue(e.target.value);
                  }}
                />
              </td>
            ) : (
              ""
            )}
            {restrict === 2 || restrict === 3 ? (
              <Fragment>
                <td>
                  <input
                    className={
                      checkCalendarValue && calendarYear
                        ? classes.redAlert
                        : null
                    }
                    type="number"
                    placeholder="January"
                    value={jan}
                    onChange={(e) => {
                      checkCalendarValue && calendarYear
                        ? setJan([])
                        : setJan(e.target.value);
                    }}
                  />
                </td>
                <td>
                  <input
                    className={
                      checkCalendarValue && calendarYear
                        ? classes.redAlert
                        : null
                    }
                    type="number"
                    placeholder="February"
                    value={feb}
                    onChange={(e) => {
                      checkCalendarValue && calendarYear
                        ? setFeb([])
                        : setFeb(e.target.value);
                    }}
                  />
                </td>
                <td>
                  <input
                    className={
                      checkCalendarValue && calendarYear
                        ? classes.redAlert
                        : null
                    }
                    type="number"
                    placeholder="March"
                    value={mar}
                    onChange={(e) => {
                      checkCalendarValue && calendarYear
                        ? setMar([])
                        : setMar(e.target.value);
                    }}
                  />
                </td>
                <td>
                  <input
                    className={
                      checkCalendarValue && calendarYear
                        ? classes.redAlert
                        : null
                    }
                    type="number"
                    placeholder="April"
                    value={apr}
                    onChange={(e) => {
                      checkCalendarValue && calendarYear
                        ? setApr([])
                        : setApr(e.target.value);
                    }}
                  />
                </td>
                <td>
                  <input
                    className={
                      checkCalendarValue && calendarYear
                        ? classes.redAlert
                        : null
                    }
                    type="number"
                    placeholder="May"
                    value={may}
                    onChange={(e) => {
                      checkCalendarValue && calendarYear
                        ? setMay([])
                        : setMay(e.target.value);
                    }}
                  />
                </td>
                <td>
                  <input
                    className={
                      checkCalendarValue && calendarYear
                        ? classes.redAlert
                        : null
                    }
                    type="number"
                    placeholder="Jun"
                    value={jun}
                    onChange={(e) => {
                      checkCalendarValue && calendarYear
                        ? setJun([])
                        : setJun(e.target.value);
                    }}
                  />
                </td>
                <td>
                  <input
                    className={
                      checkCalendarValue && calendarYear
                        ? classes.redAlert
                        : null
                    }
                    type="number"
                    placeholder="July"
                    value={jul}
                    onChange={(e) => {
                      checkCalendarValue && calendarYear
                        ? setJul([])
                        : setJul(e.target.value);
                    }}
                  />
                </td>
                <td>
                  <input
                    className={
                      checkCalendarValue && calendarYear
                        ? classes.redAlert
                        : null
                    }
                    type="number"
                    placeholder="August"
                    value={aug}
                    onChange={(e) => {
                      checkCalendarValue && calendarYear
                        ? setAug([])
                        : setAug(e.target.value);
                    }}
                  />
                </td>
                <td>
                  <input
                    className={
                      checkCalendarValue && calendarYear
                        ? classes.redAlert
                        : null
                    }
                    type="number"
                    placeholder="September"
                    value={sep}
                    onChange={(e) => {
                      checkCalendarValue && calendarYear
                        ? setSep([])
                        : setSep(e.target.value);
                    }}
                  />
                </td>
                <td>
                  <input
                    className={
                      checkCalendarValue && calendarYear
                        ? classes.redAlert
                        : null
                    }
                    type="number"
                    placeholder="October"
                    value={oct}
                    onChange={(e) => {
                      checkCalendarValue && calendarYear
                        ? setOct([])
                        : setOct(e.target.value);
                    }}
                  />
                </td>
                <td>
                  <input
                    className={
                      checkCalendarValue && calendarYear
                        ? classes.redAlert
                        : null
                    }
                    type="number"
                    placeholder="November"
                    value={nov}
                    onChange={(e) => {
                      checkCalendarValue && calendarYear
                        ? setNov([])
                        : setNov(e.target.value);
                    }}
                  />
                </td>
                <td>
                  <input
                    className={
                      checkCalendarValue && calendarYear
                        ? classes.redAlert
                        : null
                    }
                    type="number"
                    placeholder="December"
                    value={dec}
                    onChange={(e) => {
                      checkCalendarValue && calendarYear
                        ? setDec([])
                        : setDec(e.target.value);
                    }}
                  />
                </td>
              </Fragment>
            ) : (
              ""
            )}
            <td>
              <SelectData
                search_by={"Frequency Update"}
                options={frequencyUpdateOptions}
                selectOnChange={(e) =>
                  e ? setFrequencyUpdate(e.value) : setFrequencyUpdate(null)
                }
              />
            </td>
            <td>
              <div className={classes.lastUpdate}>{lastUpdate}</div>
            </td>
            <td>
              <input
                type="text"
                placeholder="Link"
                value={link}
                onChange={(e) => setLink(e.target.value)}
              />
            </td>
            <td>
              <input
                type="text"
                placeholder="Last Checked"
                value={lastChecked}
                onChange={(e) => setLastChecked(e.target.value)}
              />
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
}
