import React from "react";
import { useState } from "react";
import { IoIosClose } from "react-icons/io";
import { LiaPenSolid } from "react-icons/lia";
import { MdOutlineLockReset } from "react-icons/md";
import { MdOutlineEditOff } from "react-icons/md";
import { InputField, SelectField } from "../../Resource";
import Loading from "react-spinners/ScaleLoader";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import classes from "./Details.module.css";

export default function Details(props) {
  const [regiTime, setRegiTime] = useState("");
  const [endTime, setEndTime] = useState("");

  const [clickPassword, setClickPassword] = useState(false);
  const [access, setAccess] = useState("");
  const accessOptions = [
    { label: "Yes", value: 1 },
    { label: "No", value: 0 },
  ];
  const [role, setRole] = useState("");
  const roleOptions = [
    { label: "Basic", value: "individual_user" },
    { label: "Standard Monthly", value: "standard_m" },
    { label: "Standard Yearly", value: "standard_y" },
  ];

  const handleEdit = () => {
    props.setUserData((prev) => {
      return {
        ...prev,
        id: props.data?._id,
        name: props.data?.name,
        phone: props.data?.phone_number || props.data?.phone,
        restriction: props.data?.restriction,
        download: props.data?.download,
        downloadLimit: props.data?.download_limit,
        role: props.data?.role,
        regitime: props.data?.regitime,
        endtime: props.data?.endtime,
      };
    });
    props.setEnableEdit(true);
    clickPassword && setClickPassword(false);
  };

  //   const handleSave = () => {
  //     props.setEnableEdit(false);
  //     props.handleUpdateUser();
  //   };

  const handleClickReset = () => {
    setClickPassword(true);
    props.setUserData((prev) => {
      return {
        ...prev,
        email: props.data?.email,
      };
    });
  };

  const handleResetSubmit = (e) => {
    e.preventDefault();
    setClickPassword(false);
    props.handleResetPassword();
  };

  return (
    <div className={classes.details}>
      <div>
        <div>Details</div>
        <div
          onClick={() =>
            !props.enableEdit ? handleEdit() : props.setEnableEdit(false)
          }
        >
          {!props.enableEdit ? (
            <>
              <LiaPenSolid className={classes.edit} />
              Edit
            </>
          ) : (
            <div className={classes.cancel}>
              <MdOutlineEditOff className={classes.cancelIcon} /> Cancel
            </div>
          )}
        </div>
      </div>
      <div className={classes.detailShow}>
        <div>
          Name{" "}
          <span>
            {props.enableEdit ? (
              <InputField
                placeholder=""
                disabled={false}
                required={false}
                type="text"
                value={props.userData.name}
                onChange={(e) =>
                  e
                    ? props.setUserData((prev) => {
                        return { ...prev, name: e.target.value };
                      })
                    : props.setUserData((prev) => {
                        return { ...prev, name: null };
                      })
                }
              />
            ) : (
              props.data?.name
            )}
          </span>
        </div>
        <div>
          Phone{" "}
          <span>
            {props.enableEdit ? (
              <InputField
                placeholder=""
                disabled={false}
                required={false}
                type="text"
                value={props.userData.phone}
                onChange={(e) =>
                  e
                    ? props.setUserData((prev) => {
                        return { ...prev, phone: e.target.value };
                      })
                    : props.setUserData((prev) => {
                        return { ...prev, phone: null };
                      })
                }
              />
            ) : (
              props.data?.phone || props.data?.phone_number || "Not Given"
            )}
          </span>
        </div>
        <div>
          {/* Email <span id={classes.email}>{props.data?.email}</span> */}
          Clicked download (csv)
          <span>
            {props.enableEdit ? (
              <InputField
                placeholder=""
                disabled={false}
                required={false}
                type="text"
                value={props.userData.download}
                onChange={(e) =>
                  e
                    ? props.setUserData((prev) => {
                        return {
                          ...prev,
                          download:
                            e.target.value === "0"
                              ? e.target.value
                              : Math.ceil(e.target.value.replace(/\D/g, "")),
                        };
                      })
                    : props.setUserData((prev) => {
                        return { ...prev, download: null };
                      })
                }
              />
            ) : (
              <>
                {props.data?.download !== undefined ? (
                  <>{props.data?.download} times</>
                ) : (
                  "Please set the value 0 first"
                )}{" "}
              </>
            )}
          </span>
        </div>
        <div>
          Download Limit{" "}
          <span>
            {props.enableEdit ? (
              <InputField
                placeholder=""
                disabled={false}
                required={false}
                type="text"
                value={props.userData.downloadLimit}
                onChange={(e) =>
                  e
                    ? props.setUserData((prev) => {
                        return {
                          ...prev,
                          downloadLimit:
                            e.target.value === "0"
                              ? e.target.value
                              : Math.ceil(e.target.value.replace(/\D/g, "")),
                        };
                      })
                    : props.setUserData((prev) => {
                        return { ...prev, downloadLimit: null };
                      })
                }
              />
            ) : (
              <>
                {props.data?.download_limit !== undefined ? (
                  <>{props.data?.download_limit} times</>
                ) : (
                  "Not given"
                )}{" "}
              </>
            )}
          </span>
        </div>
        <div>
          Download Access{" "}
          <span>
            {props.enableEdit ? (
              <SelectField
                search_by={props.data?.restriction === 1 ? "Yes" : "No"}
                searchable={false}
                clearable={true}
                options={accessOptions}
                value={access}
                selectOnChange={(e) => {
                  if (e) {
                    setAccess(e);
                    props.setUserData((prev) => {
                      return { ...prev, restriction: e.value };
                    });
                  } else {
                    setAccess("");
                    props.setUserData((prev) => {
                      return { ...prev, restriction: props.data?.restriction };
                    });
                  }
                }}
              />
            ) : props.data?.restriction === 1 ? (
              "Given"
            ) : (
              "Denied"
            )}
          </span>
        </div>
        <div>
          Subscription{" "}
          <span>
            {props.enableEdit ? (
              <SelectField
                search_by={
                  props.data?.role === "individual_user"
                    ? "Basic"
                    : props.data?.role === "standard_y"
                    ? "Standard Yearly"
                    : props.data?.role === "standard_m"
                    ? "Standard Monthly"
                    : ""
                }
                searchable={false}
                clearable={true}
                options={roleOptions}
                value={role}
                selectOnChange={(e) => {
                  if (e) {
                    setRole(e);
                    props.setUserData((prev) => {
                      return { ...prev, role: e.value };
                    });
                  } else {
                    setRole("");
                    props.setUserData((prev) => {
                      return { ...prev, role: props.data?.role };
                    });
                  }
                }}
              />
            ) : props.data?.role === "individual_user" ? (
              "Basic"
            ) : props.data?.role === "standard_y" ? (
              "Standard Yearly"
            ) : props.data?.role === "standard_m" ? (
              "Standard Monthly"
            ) : (
              ""
            )}
          </span>
        </div>
        <div>
          Registered on{" "}
          <span>
            {props.enableEdit ? (
              <>
                <>{moment(props.data?.regitime).format("D MMM, YY")}</>
                <InputField
                  //   placeholder={regiTime}
                  disabled={false}
                  required={false}
                  type="date"
                  value={regiTime}
                  onChange={(e) => {
                    if (e) {
                      setRegiTime(e.target.value);
                      props.setUserData((prev) => {
                        return {
                          ...prev,
                          regitime: new Date(e.target.value).toISOString(),
                        };
                      });
                    } else {
                      setRegiTime("");
                      props.setUserData((prev) => {
                        return { ...prev, regitime: null };
                      });
                    }
                  }}
                />
              </>
            ) : (
              moment(props.data?.regitime).format("D MMM, YY")
            )}
          </span>
        </div>
        <div>
          Registration End{" "}
          <span>
            {props.enableEdit ? (
              <>
                <>{moment(props.data?.endtime).format("D MMM, YY")}</>
                <InputField
                  // placeholder={endTime}
                  disabled={false}
                  required={false}
                  type="date"
                  value={endTime}
                  onChange={(e) => {
                    if (e) {
                      setEndTime(e.target.value);
                      props.setUserData((prev) => {
                        return {
                          ...prev,
                          endtime: new Date(e.target.value).toISOString(),
                        };
                      });
                    } else {
                      setEndTime("");
                      props.setUserData((prev) => {
                        return { ...prev, endtime: null };
                      });
                    }
                  }}
                />
              </>
            ) : (
              moment(props.data?.endtime).format("D MMM, YY")
            )}
          </span>
        </div>
      </div>
      {props.enableEdit &&
        (props.loading ? (
          <div className={classes.loading}>
            <Loading height={15} color="var(--home-color)" />
          </div>
        ) : (
          <button
            className={classes.save}
            onClick={() => props.handleUpdateUser()}
          >
            Save
          </button>
        ))}
      <div className={classes.resetPassword}>
        <div
          onClick={() =>
            !clickPassword ? handleClickReset() : setClickPassword(false)
          }
        >
          Reset Password <MdOutlineLockReset />
        </div>
        <form
          className={!clickPassword ? classes.resetFormHide : classes.resetForm}
          onSubmit={(e) => handleResetSubmit(e)}
        >
          <InputField
            placeholder={"Enter new password"}
            disabled={false}
            required={true}
            type="text"
            value={props.userData.newPassword}
            onChange={(e) =>
              e
                ? props.setUserData((prev) => {
                    return { ...prev, newPassword: e.target.value };
                  })
                : props.setUserData((prev) => {
                    return { ...prev, newPassword: "" };
                  })
            }
          />
          <button type="submit">Save</button>
        </form>
      </div>
      <IoIosClose
        onClick={() => {
          props.setPopUp(false);
          props.setAction(0);
          props.enableEdit && props.setEnableEdit(false);
        }}
        className={classes.closeIcon}
      />
    </div>
  );
}
