import { useEffect, useState } from "react";
import BarLoader from "react-spinners/BarLoader";
import { api } from "../../../utils/api";
import classesDashboardTable from "../../DashboardTable/DashboardTable.module.css";
import { AllIndustryDataTable } from "./AllIndustryDataTable/AllIndustryDataTable";
import classes from "./IndustryData.module.css";

const month = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

let INDUSTRY_DATA_API = "/Data/IndustryData/";

export const IndustryData = ({
  iQuery,
  setDashboardPosition,
  setActiveDeleteData,
  dataId,
  setDataId,
}) => {
  const [industryData, setIndustryData] = useState("");
  const [radio, setRadio] = useState("AllIndustry");
  const [loading, setLoading] = useState(true);

  const fetchApiData = async (url) => {
    const response = await fetch(url);
    const industry_data = await response.json();
    setIndustryData(industry_data);
    setLoading(false);
  };

  const getRefresh = async () => {
    if (iQuery) {
      await fetchApiData(`${api}${INDUSTRY_DATA_API}${iQuery}`);
    }
  };

  useEffect(() => {
    fetchApiData(`${api}${INDUSTRY_DATA_API}${iQuery}`);
  }, [iQuery, api]);

  return (
    <>
      {!iQuery ? (
        ""
      ) : loading ? (
        <>
          <BarLoader className="loading" size={15} color={"#123abc"} />
        </>
      ) : (
        <div className={classesDashboardTable.DataEntryTableLayout}>
          <div className={classes.radio}>
            <input
              type="radio"
              name="AllIndustry"
              value="AllIndustry"
              onChange={(e) => setRadio("AllIndustry")}
              checked={radio === "AllIndustry"}
            />
            <label>All</label>
            <input
              type="radio"
              name="BudgetInd"
              value="BudgetInd"
              onChange={(e) => setRadio("BudgetInd")}
              checked={radio === "BudgetInd"}
            />
            <label>Budget Year</label>
            <input
              type="radio"
              name="CalendarInd"
              value="CalendarInd"
              onChange={(e) => setRadio("CalendarInd")}
              checked={radio === "CalendarInd"}
            />
            <label>Calendar Year</label>
          </div>
          <div
            className={`${classesDashboardTable.tableWrapper} ${classesDashboardTable.tableWrapperDashboardData}`}
          >
            {industryData ? (
              <AllIndustryDataTable
                month={month}
                industryData={industryData}
                setDashboardPosition={setDashboardPosition}
                setActiveDeleteData={setActiveDeleteData}
                dataId={dataId}
                setDataId={setDataId}
                radio={radio}
                getRefresh={getRefresh}
              />
            ) : (
              "unable to catch data"
            )}
          </div>
        </div>
      )}
    </>
  );
};
