import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Auth } from "../../contexts/allContext";
import classes from "./Error.module.css";

export function Error() {
  const { stateAuth } = useContext(Auth);

  const navigate = useNavigate();
  useEffect(() => {
    if (stateAuth.auth) {
      navigate("/admin-portal/dashboard-summary");
    } else {
      navigate("/login");
    }
  }, [navigate, stateAuth]);

  return (
    <>
      <div className={classes.errorBackground}></div>
      <div className={classes.errorContainer}>
        <h3>BIZDATA ADMIN PORTAL</h3>
        <h4>Page Not Found!</h4>
      </div>
    </>
  );
}
