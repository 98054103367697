import { Fragment, useEffect, useState } from "react";
import { SelectData } from "../../DataEntryPanel/SelectData/SelectData";
import { SelectDataEntry } from "../SelectDataEntry/SelectDataEntry";
import classes from "../AddDashboardData.module.css";

export function AddBusinessData(props) {
  const {
    api,
    month,
    company,
    indicator,
    budgetYear,
    budgetValue,
    calendarYear,
    calendarValue,
    jan,
    feb,
    mar,
    apr,
    may,
    jun,
    jul,
    aug,
    sep,
    oct,
    nov,
    dec,
    lastUpdate,
    link,
    lastChecked,
    indicatorUpdate,
    setCompany,
    setSector,
    setIndicator,
    setUnit,
    setSource,
    setBudgetYear,
    setBudgetValue,
    setCalendarYear,
    setCalendarValue,
    setJan,
    setFeb,
    setMar,
    setApr,
    setMay,
    setJun,
    setJul,
    setAug,
    setSep,
    setOct,
    setNov,
    setDec,
    setFrequencyUpdate,
    setLink,
    setLastChecked,
  } = props;

  const [businessCompany, setBusinessCompany] = useState([]);
  const [businessIndicator, setBusinessIndicator] = useState([]);
  const [businessSector, setBusinessSector] = useState([]);
  const [businessUnit, setBusinessUnit] = useState([]);
  const [businessSource, setBusinessSource] = useState([]);
  const [budgetYearOptions, setBudgetYearOptions] = useState([]);
  const [calendarYearOptions, setCalendarYearOptions] = useState([]);
  const [frequencyUpdateOptions, setFrequencyUpdateOptions] = useState([]);
  const [restrict, setRestrict] = useState(3);
  const [checkBudgetValue, setCheckBudgetValue] = useState("");
  const [checkCalendarValue, setCheckCalendarValue] = useState("");

  useEffect(() => {
    const getInfo = async () => {
      let busCompany = [];
      let busSector = [];
      let busIndicator = [];
      let busUnit = [];
      let busSource = [];
      let budgetyear = [];
      let calendaryear = [];

      const frequencyUpdateOptionsAll = [
        { value: "Monthly", label: "Monthly" },
        { value: "Quarterly", label: "Quarterly" },
        { value: "Yearly", label: "Yearly" },
        { value: "Budget Yearly", label: "Budget Yearly" },
      ];
      setFrequencyUpdateOptions(frequencyUpdateOptionsAll);

      for (let i = 2000; i < 2070; i++) {
        const x = i;
        const y = i + 1;
        budgetyear.push({ label: x + "-" + y, value: x + "-" + y });
      }
      for (let i = 2000; i < 2050; i++) {
        const m = i;
        const n = i;
        calendaryear.push({ label: m, value: n });
      }

      if (!calendarYear && budgetYear) {
        setCalendarYearOptions([]);
        setRestrict(1);
      } else if (calendarYear && !budgetYear) {
        setBudgetYearOptions([]);
        setRestrict(2);
      } else {
        setBudgetYearOptions(budgetyear);
        setCalendarYearOptions(calendaryear);
        setRestrict(3);
      }

      // fetch list of business companies
      const business_Company = await fetch(
        `${api}/Data/Business_Data/listofcompanies`
      );
      const businessCompany = await business_Company.json();
      await businessCompany.map((e) => busCompany.push({ label: e, value: e }));
      setBusinessCompany(busCompany);

      if (company) {
        // fetch sector
        const business_Sector = await fetch(`${api}/Data/Business/allsector`);
        const businessSector = await business_Sector.json();

        // fetch indicator
        const business_Indicator = await fetch(
          `${api}/Data/Business/allindicator`
        );
        const businessIndicator = await business_Indicator.json();

        // fetch unit
        const business_Unit = await fetch(`${api}/Data/Business/allunit`);
        const businessUnit = await business_Unit.json();

        // fetch source
        const business_Source = await fetch(`${api}/Data/Business/allsource`);
        const businessSource = await business_Source.json();

        // fetch budget value by indicator and budget year
        if (company && indicator && budgetYear) {
          const check_BudgetValue = await fetch(
            `${api}/DataCheck/Business/searchdata3/${company}/${indicator}/${budgetYear}`
          );
          const checkBudget_Value = await check_BudgetValue.json();
          setCheckBudgetValue(checkBudget_Value);
        }

        // fetch calendar value by indicator and calendar year
        if (company && indicator && calendarYear) {
          const check_CalendarValue = await fetch(
            `${api}/DataCheck/Business/searchdata4/${company}/${indicator}/${calendarYear}`
          );
          const checkCalendar_Value = await check_CalendarValue.json();
          setCheckCalendarValue(checkCalendar_Value);
        }

        // fetch sector by company
        const business_Sector_by_company = await fetch(
          `${api}/Data/Business/listofsector/${company}`
        );
        const businessSector_by_company =
          await business_Sector_by_company.json();

        // fetch indicator by company
        const business_Indicator_by_company = await fetch(
          `${api}/Data/Business/listofindicator/${company}`
        );
        const businessIndicator_by_company =
          await business_Indicator_by_company.json();

        let businessUnit_by_company;
        if (indicator) {
          // fetch unit by company and indicator
          const business_Unit_by_company = await fetch(
            `${api}/Data/Business/listofunit/${company}/${indicator}`
          );
          businessUnit_by_company = await business_Unit_by_company.json();
        }

        // fetch source by company
        const business_Source_by_company = await fetch(
          `${api}/Data/Business/listofsource/${company}`
        );
        const businessSource_by_company =
          await business_Source_by_company.json();

        if (
          businessSector_by_company.length !== 0 &&
          businessIndicator_by_company.length !== 0 &&
          businessSource_by_company.length !== 0
        ) {
          // set indicator
          await businessIndicator_by_company.map((e) =>
            busIndicator.push({ label: e, value: e })
          );
          setBusinessIndicator(busIndicator);

          // set sector
          await businessSector_by_company.map((e) =>
            busSector.push({ label: e, value: e })
          );
          setBusinessSector(busSector);

          // set unit
          businessUnit_by_company && businessUnit_by_company.length !== 0
            ? await businessUnit_by_company.map((e) =>
                busUnit.push({ label: e, value: e })
              )
            : await businessUnit.map((e) =>
                busUnit.push({ label: e, value: e })
              );
          setBusinessUnit(busUnit);

          // set source
          await businessSource_by_company.map((e) =>
            busSource.push({ label: e, value: e })
          );
          setBusinessSource(busSource);
        } else if (
          businessSector_by_company.length === 0 &&
          businessIndicator_by_company.length === 0 &&
          businessSource_by_company.length === 0 &&
          businessUnit_by_company.length === 0 &&
          businessSector.length !== 0 &&
          businessIndicator.length !== 0 &&
          businessUnit.length !== 0 &&
          businessSource.length !== 0
        ) {
          // set indicator
          await businessIndicator.map((e) =>
            busIndicator.push({ label: e, value: e })
          );
          setBusinessIndicator(busIndicator);

          // set sector
          await businessSector.map((e) =>
            busSector.push({ label: e, value: e })
          );
          setBusinessSector(busSector);

          // set unit
          await businessUnit.map((e) => busUnit.push({ label: e, value: e }));
          setBusinessUnit(busUnit);

          // set source
          await businessSource.map((e) =>
            busSource.push({ label: e, value: e })
          );
          setBusinessSource(busSource);
        } else {
          setBusinessIndicator([]);
          setIndicator("");
          setBusinessSector([]);
          setSector("");
          setBusinessUnit([]);
          setUnit("");
          setBusinessSource([]);
          setSource("");
        }
      }
    };
    getInfo();
  }, [
    company,
    indicator,
    budgetYear,
    calendarYear,
    setIndicator,
    setSector,
    setSource,
    setUnit,
    checkBudgetValue,
    checkCalendarValue,
    api,
  ]);

  return (
    <>
      <table>
        <thead>
          <tr>
            <th>Company</th>
            <th>Sector</th>
            <th>Indicator</th>
            <th>Unit</th>
            <th>Source</th>
            {restrict === 1 || restrict === 3 ? <th>Budget Year</th> : ""}
            {restrict === 1 || restrict === 3 ? <th>Budget Value</th> : ""}
            {restrict === 2 || restrict === 3 ? <th>Calendar Year</th> : ""}
            {restrict === 2 || restrict === 3 ? <th>Calendar Value</th> : ""}
            {restrict === 2 || restrict === 3
              ? month.map((e, key) => <th key={key}>{e}</th>)
              : ""}
            <th>Frequency Update</th>
            <th>Last Update</th>
            <th>Link</th>
            <th>Last Checked</th>
            <th>Indicator Update</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <SelectDataEntry
                search_by={"Company"}
                options={businessCompany}
                selectOnChange={(e) =>
                  e ? setCompany(e.value) : setCompany(null)
                }
                handleInputChange={(e) => {
                  if (e) {
                    setCompany(e);
                  }
                }}
              />
            </td>
            <td>
              <SelectDataEntry
                search_by={"Sector"}
                options={businessSector}
                selectOnChange={(e) =>
                  e ? setSector(e.value) : setSector(null)
                }
                handleInputChange={(e) => {
                  if (e) {
                    setSector(e);
                  }
                }}
              />
            </td>
            <td>
              <SelectDataEntry
                search_by={"Indicator"}
                options={businessIndicator}
                selectOnChange={(e) =>
                  e ? setIndicator(e.value) : setIndicator(null)
                }
                handleInputChange={(e) => {
                  if (e) {
                    setIndicator(e);
                  }
                }}
              />
            </td>
            <td>
              <SelectDataEntry
                search_by={"Unit"}
                options={businessUnit}
                selectOnChange={(e) => (e ? setUnit(e.value) : setUnit(null))}
                handleInputChange={(e) => {
                  if (e) {
                    setUnit(e);
                  }
                }}
              />
            </td>
            <td>
              <SelectDataEntry
                search_by={"Source"}
                options={businessSource}
                selectOnChange={(e) =>
                  e ? setSource(e.value) : setSource(null)
                }
                handleInputChange={(e) => {
                  if (e) {
                    setSource(e);
                  }
                }}
              />
            </td>
            {restrict === 1 || restrict === 3 ? (
              <td>
                <SelectData
                  search_by={"Budget Year"}
                  options={budgetYearOptions}
                  selectOnChange={(e) =>
                    e ? setBudgetYear(e.value) : setBudgetYear(null)
                  }
                />
              </td>
            ) : (
              ""
            )}
            {restrict === 1 || restrict === 3 ? (
              <td>
                <input
                  className={
                    checkBudgetValue && budgetYear ? classes.redAlert : null
                  }
                  type="number"
                  placeholder="Budget Value"
                  value={budgetValue}
                  onChange={(e) => {
                    checkBudgetValue
                      ? setBudgetValue([])
                      : setBudgetValue(e.target.value);
                  }}
                />
              </td>
            ) : (
              ""
            )}
            {restrict === 2 || restrict === 3 ? (
              <td>
                <SelectData
                  search_by={"Calendar Year"}
                  options={calendarYearOptions}
                  selectOnChange={(e) =>
                    e ? setCalendarYear(e.value) : setCalendarYear(null)
                  }
                />
              </td>
            ) : (
              ""
            )}
            {restrict === 2 || restrict === 3 ? (
              <td>
                <input
                  className={
                    checkCalendarValue && calendarYear ? classes.redAlert : null
                  }
                  type="number"
                  placeholder="Calender Value"
                  value={calendarValue}
                  onChange={(e) => {
                    checkCalendarValue
                      ? setCalendarValue([])
                      : setCalendarValue(e.target.value);
                  }}
                />
              </td>
            ) : (
              ""
            )}
            {restrict === 2 || restrict === 3 ? (
              <Fragment>
                <td>
                  <input
                    className={
                      checkCalendarValue && calendarYear
                        ? classes.redAlert
                        : null
                    }
                    type="number"
                    placeholder="January"
                    value={jan}
                    onChange={(e) => {
                      checkCalendarValue && calendarYear
                        ? setJan([])
                        : setJan(e.target.value);
                    }}
                  />
                </td>
                <td>
                  <input
                    className={
                      checkCalendarValue && calendarYear
                        ? classes.redAlert
                        : null
                    }
                    type="number"
                    placeholder="February"
                    value={feb}
                    onChange={(e) => {
                      checkCalendarValue && calendarYear
                        ? setFeb([])
                        : setFeb(e.target.value);
                    }}
                  />
                </td>
                <td>
                  <input
                    className={
                      checkCalendarValue && calendarYear
                        ? classes.redAlert
                        : null
                    }
                    type="number"
                    placeholder="March"
                    value={mar}
                    onChange={(e) => {
                      checkCalendarValue && calendarYear
                        ? setMar([])
                        : setMar(e.target.value);
                    }}
                  />
                </td>
                <td>
                  <input
                    className={
                      checkCalendarValue && calendarYear
                        ? classes.redAlert
                        : null
                    }
                    type="number"
                    placeholder="April"
                    value={apr}
                    onChange={(e) => {
                      checkCalendarValue && calendarYear
                        ? setApr([])
                        : setApr(e.target.value);
                    }}
                  />
                </td>
                <td>
                  <input
                    className={
                      checkCalendarValue && calendarYear
                        ? classes.redAlert
                        : null
                    }
                    type="number"
                    placeholder="May"
                    value={may}
                    onChange={(e) => {
                      checkCalendarValue && calendarYear
                        ? setMay([])
                        : setMay(e.target.value);
                    }}
                  />
                </td>
                <td>
                  <input
                    className={
                      checkCalendarValue && calendarYear
                        ? classes.redAlert
                        : null
                    }
                    type="number"
                    placeholder="Jun"
                    value={jun}
                    onChange={(e) => {
                      checkCalendarValue && calendarYear
                        ? setJun([])
                        : setJun(e.target.value);
                    }}
                  />
                </td>
                <td>
                  <input
                    className={
                      checkCalendarValue && calendarYear
                        ? classes.redAlert
                        : null
                    }
                    type="number"
                    placeholder="July"
                    value={jul}
                    onChange={(e) => {
                      checkCalendarValue && calendarYear
                        ? setJul([])
                        : setJul(e.target.value);
                    }}
                  />
                </td>
                <td>
                  <input
                    className={
                      checkCalendarValue && calendarYear
                        ? classes.redAlert
                        : null
                    }
                    type="number"
                    placeholder="August"
                    value={aug}
                    onChange={(e) => {
                      checkCalendarValue && calendarYear
                        ? setAug([])
                        : setAug(e.target.value);
                    }}
                  />
                </td>
                <td>
                  <input
                    className={
                      checkCalendarValue && calendarYear
                        ? classes.redAlert
                        : null
                    }
                    type="number"
                    placeholder="September"
                    value={sep}
                    onChange={(e) => {
                      checkCalendarValue && calendarYear
                        ? setSep([])
                        : setSep(e.target.value);
                    }}
                  />
                </td>
                <td>
                  <input
                    className={
                      checkCalendarValue && calendarYear
                        ? classes.redAlert
                        : null
                    }
                    type="number"
                    placeholder="October"
                    value={oct}
                    onChange={(e) => {
                      checkCalendarValue && calendarYear
                        ? setOct([])
                        : setOct(e.target.value);
                    }}
                  />
                </td>
                <td>
                  <input
                    className={
                      checkCalendarValue && calendarYear
                        ? classes.redAlert
                        : null
                    }
                    type="number"
                    placeholder="November"
                    value={nov}
                    onChange={(e) => {
                      checkCalendarValue && calendarYear
                        ? setNov([])
                        : setNov(e.target.value);
                    }}
                  />
                </td>
                <td>
                  <input
                    className={
                      checkCalendarValue && calendarYear
                        ? classes.redAlert
                        : null
                    }
                    type="number"
                    placeholder="December"
                    value={dec}
                    onChange={(e) => {
                      checkCalendarValue && calendarYear
                        ? setDec([])
                        : setDec(e.target.value);
                    }}
                  />
                </td>
              </Fragment>
            ) : (
              ""
            )}
            <td>
              <SelectData
                search_by={"Frequency Update"}
                options={frequencyUpdateOptions}
                selectOnChange={(e) =>
                  e ? setFrequencyUpdate(e.value) : setFrequencyUpdate(null)
                }
              />
            </td>
            <td>
              <div className={classes.lastUpdate}>{lastUpdate}</div>
            </td>
            <td>
              <input
                type="text"
                placeholder="Link"
                value={link}
                onChange={(e) => setLink(e.target.value)}
              />
            </td>
            <td>
              {
                <input
                  type="text"
                  placeholder="Last Checked"
                  value={lastChecked}
                  onChange={(e) => setLastChecked(e.target.value)}
                />
              }
            </td>
            <td>
              <div className={classes.lastUpdate}>{indicatorUpdate}</div>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
}
