import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Auth, UserInfo } from "../../contexts/allContext";
import classes from "./Layout.module.css";
import { Navbar } from "./Navbar/Navbar";
import { Sections } from "./Sections/Sections";

const api = process.env.REACT_APP_BASE_URL;
const frontEnd = process.env.REACT_APP_CLIENT_URL;

export default function Layout({ children }) {
  const { stateAuth, dispatchAuth } = useContext(Auth);
  const { stateUser, dispatchUser } = useContext(UserInfo);
  const token = stateAuth.token;
  const adminInfo = stateUser.info;
  // console.log(adminInfo);

  const navigate = useNavigate();

  const handleLogout = async () => {
    const logout = await axios.get(`${api}/login/logout`, {
      headers: {
        "Access-Control-Allow-Origin": frontEnd,
      },
      withCredentials: true,
    });
    if (logout.data) {
      dispatchAuth({ type: "remove" });
      dispatchUser({ type: "remove" });
      localStorage.removeItem("localToken");
      navigate("/login");
    } else {
      alert("Logout failed!");
    }
  };
  return (
    <>
      <div className={classes.layout}>
        <Navbar adminInfo={adminInfo} logout={handleLogout} />
        <div className={classes.underNavbar}>
          <Sections role={adminInfo.role} />{" "}
          <div className={classes.sectionBody}>{children}</div>
        </div>
      </div>
    </>
  );
}
