import "./App.css";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import { setToken } from "./auth/auth";
import { useEffect, useReducer, useState } from "react";
import { Error } from "./components/Error/Error";
import axios from "axios";
import SubscribersListPage from "./pages/SubscriberList";
import DashboardSummeryPage from "./pages/DashboardSummery";
import DataEntryPanelPage from "./pages/DataEntryPanel";
import AdminSettingsPage from "./pages/AdminSettings";
import DatabaseSettingsPage from "./pages/DatabaseSettings";
import LoginPage from "./pages/Login";
import Layout from "./components/Layout/Layout";
import CompanyDatabasePage from "./pages/CompanyDatabase";
import CompanyPage from "./pages/Company";
import { authReducer, authState } from "./contexts/reducer/authReducer";
import { userReducer, userState } from "./contexts/reducer/userReducer";
import { api, frontEnd } from "./utils/api";
import { Auth, UserInfo } from "./contexts/allContext";
import ProtectedRoute from "./routes/ProtectedRoute";

function App() {
  const [stateAuth, dispatchAuth] = useReducer(authReducer, authState);
  const [stateUser, dispatchUser] = useReducer(userReducer, userState);

  // const navigate = useNavigate();
  // useEffect(() => {
  //   if (stateAuth.auth) {
  //     navigate("/admin-portal/dashboard-summary");
  //   } else {
  //     navigate("/login");
  //   }
  // }, [navigate, stateAuth]);

  return (
    <div className="App">
      <Auth.Provider value={{ stateAuth, dispatchAuth }}>
        <UserInfo.Provider value={{ stateUser, dispatchUser }}>
          <Routes>
            <Route path="/login" element={<LoginPage />} />
            <Route path="/*" element={<ProtectedRoute />}>
              <Route
                path="admin-portal/dashboard-summary"
                element={<DashboardSummeryPage />}
              />
              <Route
                path="admin-portal/subscriber-list"
                element={<SubscribersListPage />}
              />
              <Route
                path="admin-portal/data-entry-panel"
                element={<DataEntryPanelPage />}
              />
              <Route
                path="admin-portal/data-entry-panel/company-database"
                element={<CompanyDatabasePage />}
              />
              <Route
                path="admin-portal/database-settings"
                element={<DatabaseSettingsPage />}
              />
              <Route
                path="admin-portal/admin-settings"
                element={<AdminSettingsPage />}
              />
              <Route path="CompanyData/*" element={<CompanyPage />}></Route>
              <Route path="admin-portal/*" element={<Error />} />
            </Route>
            <Route exact path="/" element={<Error />} />
          </Routes>
        </UserInfo.Provider>
      </Auth.Provider>
    </div>
  );
}

export default App;
