import React, { useEffect, useState } from "react";
import { SelectDataEntry } from "../../AddDashboardData/SelectDataEntry/SelectDataEntry";
import classes from "./ShareholderInformation.module.css";

export default function ShareholderInformation(props) {
  const {
    shareholderInfo,
    setShareholderInfo,
    inputPartnerInvestorShare,
    setInputPartnerInvestorShare,
  } = props;
  const [partnerInvestorOptions, setPartnerInvestor] = useState([]);

  useEffect(() => {
    const partner_investor = [
      { value: "Sponsor-Directors", label: "Sponsor-Directors" },
      { value: "Institutional Investor", label: "Institutional Investor" },
      { value: "General Public", label: "General Public" },
      { value: "Foreign Investor", label: "Foreign Investor" },
      { value: "Govt.", label: "Govt." },
    ];
    setPartnerInvestor(partner_investor);
  }, []);

  const handleInputChange = (e, index) => {
    const list = [...inputPartnerInvestorShare];
    e
      ? (list[index][e.target.name] = e.target.value)
      : (list[index][e.target.name] = "");
    setInputPartnerInvestorShare(list);
  };

  const handleInputChange_a = (e, index) => {
    const list = [...inputPartnerInvestorShare];
    e ? (list[index]["partner"] = e.value) : (list[index]["partner"] = "");
    setInputPartnerInvestorShare(list);
  };

  // const handleInputChange_b = (e, index) => {
  //   const list = [...inputPartnerInvestorShare];
  //   list[index]["partner"] = e;
  //   setInputPartnerInvestorShare(list);
  // };

  const handleAddClick = () => {
    setInputPartnerInvestorShare([
      ...inputPartnerInvestorShare,
      { partner: "", shareholding: "" },
    ]);
  };

  const handleRemoveClick = (index) => {
    const list = [...inputPartnerInvestorShare];
    list.splice(index, 1);
    setInputPartnerInvestorShare(list);
  };

  const handleSendClick = () => {
    console.log("inputPartnerInvestorShare", inputPartnerInvestorShare);
  };

  return (
    <>
      <div className={classes.AllData}>
        <h5>Shareholder Information</h5>
        <div className={classes.InputFieldDiv}>
          <div className={classes.InputFieldDivLeft}>
            <label>Authorised Capital (Cr. BDT)</label>
            <input
              type="number"
              placeholder="Authorised Capital"
              value={shareholderInfo.authorised_capital}
              onChange={(e) =>
                e
                  ? setShareholderInfo((prev) => {
                      return { ...prev, authorised_capital: e.target.value };
                    })
                  : setShareholderInfo((prev) => {
                      return { ...prev, authorised_capital: null };
                    })
              }
            />
            <label>Paid Up Capital (Cr. BDT)</label>
            <input
              type="number"
              placeholder="Paid Up Capital"
              value={shareholderInfo.paidup_capital}
              onChange={(e) =>
                e
                  ? setShareholderInfo((prev) => {
                      return { ...prev, paidup_capital: e.target.value };
                    })
                  : setShareholderInfo((prev) => {
                      return { ...prev, paidup_capital: null };
                    })
              }
            />
            <label>Number of Securities (Million)</label>
            <input
              type="number"
              placeholder="Number of Securities"
              value={shareholderInfo.number_of_securities}
              onChange={(e) =>
                e
                  ? setShareholderInfo((prev) => {
                      return { ...prev, number_of_securities: e.target.value };
                    })
                  : setShareholderInfo((prev) => {
                      return { ...prev, number_of_securities: null };
                    })
              }
            />
          </div>
          <div className={classes.InputFieldDivRight}>
            {inputPartnerInvestorShare.map((data, index) => (
              <div key={index}>
                <div>
                  <label>Partner / Investor</label>
                  <div>
                    <SelectDataEntry
                      search_by={"-- Select or Create Partner/Investor --"}
                      options={partnerInvestorOptions}
                      selectOnChange={(e) => handleInputChange_a(e, index)}
                      handleInputChange={(e) => {
                        if (e) {
                          handleInputChange_a(e, index);
                        }
                      }}
                    />
                  </div>
                </div>
                <div>
                  <label>Shareholding (%)</label>
                  <input
                    type="number"
                    name="shareholding"
                    placeholder="Shareholding"
                    value={data.shareholding}
                    onChange={(e) => handleInputChange(e, index)}
                  />
                  (%)
                </div>
                <div>
                  <button
                    type="button"
                    onClick={() => handleRemoveClick(index)}
                  >
                    Remove
                  </button>
                </div>
              </div>
            ))}
            <button
              type="button"
              disabled={inputPartnerInvestorShare.length > 4 ? true : false}
              onClick={handleAddClick}
            >
              + Add
            </button>

            <button type="button" onClick={handleSendClick}>
              Send
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
