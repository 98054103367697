import { Navigate, useLocation } from "react-router-dom";

export const setToken = (token) => {
  return localStorage.setItem("localToken", token);
};

export const getToken = () => {
  return localStorage.getItem("localToken");
};

// export const Check = async () => {
//   const checkToken = await axios.get("/login/auth");
//   const check_Token = await checkToken.data.msg;
//   let location = useLocation();
//   if (check_Token === "no_token") {
//     localStorage.removeItem("localToken");
//     return <Navigate to="/login" state={{ from: location }} />;
//   }
// };

export function RequireToken({ children }) {
  let location = useLocation();
  let token = getToken();
  // console.log(token);

  if (token !== "found_token") {
    localStorage.removeItem("localToken");
    return <Navigate to="/login" state={{ from: location }} />;
  }
  return children;
}
