import classes from "./Sections.module.css";
import dashboard_summary_icon from "../../../asset/images/s1.png";
import subscriberList_icon from "../../../asset/images/s2.png";
import dataEntryPanel_icon from "../../../asset/images/s3.png";
import dataList_icon from "../../../asset/images/s7.png";
import adminSettings_icon from "../../../asset/images/s5.png";

import dashboard_summary_icon1 from "../../../asset/images/s11.png";
import subscriberList_icon2 from "../../../asset/images/s22.png";
import dataEntryPanel_icon3 from "../../../asset/images/s33.png";
import dataList_icon7 from "../../../asset/images/s77.png";
import adminSettings_icon4 from "../../../asset/images/s55.png";
import { NavLink } from "react-router-dom";
import { useState } from "react";

export function Sections(props) {
  const { role } = props;
  const [open, setOpen] = useState(false);

  const activeFunc = (active, icon1, icon2, name) => {
    const icon = active ? icon1 : icon2;
    return (
      <>
        <img src={icon} alt="" /> {name}
        {/* {name === "Data Entry Panel" ? <> &#8964;</> : ""} */}
      </>
    );
  };

  return (
    <>
      <div className={classes.sections}>
        <h6>Menu</h6>
        <NavLink
          className={({ isActive }) =>
            isActive ? classes.navLink_active : classes.navLink
          }
          to={"/admin-portal/dashboard-summary"}
          children={({ isActive }) =>
            activeFunc(
              isActive,
              dashboard_summary_icon,
              dashboard_summary_icon1,
              "Dashboard Summary"
            )
          }
        ></NavLink>
        <NavLink
          className={({ isActive }) =>
            isActive ? classes.navLink_active : classes.navLink
          }
          to={"/admin-portal/subscriber-list"}
          children={({ isActive }) =>
            activeFunc(
              isActive,
              subscriberList_icon,
              subscriberList_icon2,
              "Subscriber List"
            )
          }
        ></NavLink>
        <NavLink
          className={({ isActive }) =>
            isActive ? `${classes.navLink_active}` : `${classes.navLink}`
          }
          to={"/admin-portal/data-entry-panel"}
          children={({ isActive }) =>
            activeFunc(
              isActive,
              dataEntryPanel_icon,
              dataEntryPanel_icon3,
              "Data Entry Panel"
            )
          }
          onClick={() => (!open ? setOpen(true) : setOpen(false))}
        ></NavLink>
        <div
          className={
            open ? `${classes.subLink} ${classes.open}` : classes.subLink
          }
        >
          <NavLink
            className={({ isActive }) =>
              isActive ? classes.navSubLink_active : classes.navSubLink
            }
            to={"/admin-portal/data-entry-panel"}
          >
            |- Data Terminal
          </NavLink>
          <NavLink
            className={({ isActive }) =>
              isActive ? classes.navSubLink_active : classes.navSubLink
            }
            to={"/admin-portal/data-entry-panel/company-database"}
          >
            |- Company Database
          </NavLink>
        </div>

        <NavLink
          className={({ isActive }) =>
            isActive ? classes.navLink_active : classes.navLink
          }
          to={"/admin-portal/database-settings"}
          children={({ isActive }) =>
            activeFunc(
              isActive,
              dataList_icon,
              dataList_icon7,
              "Database Settings"
            )
          }
        ></NavLink>
        {(role === "SuperAdmin" || role === "Admin") && (
          <NavLink
            className={({ isActive }) =>
              isActive ? classes.navLink_active : classes.navLink
            }
            to={"/admin-portal/admin-settings"}
            children={({ isActive }) =>
              activeFunc(
                isActive,
                adminSettings_icon,
                adminSettings_icon4,
                "Admin Settings"
              )
            }
          ></NavLink>
        )}
      </div>
    </>
  );
}
