import React from "react";
import classes from "./ConfirmAlert.module.css";

export default function ConfirmAlert(props) {
  return (
    <div className={classes.wrapper}>
      <div>Are you confirm?</div>
      <div>
        <button
          onClick={() => {
            props.handleDelete(props.data.email);
            props.setPopUp(false);
            props.setAction(0);
          }}
        >
          Yes
        </button>
        <button
          onClick={() => {
            props.setPopUp(false);
            props.setAction(0);
          }}
        >
          No
        </button>
      </div>
    </div>
  );
}
