import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import classes from "./Login.module.css";
import { Auth, UserInfo } from "../../contexts/allContext";
import { api, frontEnd } from "../../utils/api";

export function Login() {
  const { stateAuth, dispatchAuth } = useContext(Auth);
  const { stateUser, dispatchUser } = useContext(UserInfo);
  const token = stateAuth.token;
  const adminInfo = stateUser.info;
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [alertMessage, setAlertMessage] = useState("");

  useEffect(() => {
    if (stateAuth.auth) {
      navigate("/admin-portal/dashboard-summary");
    } else {
      navigate("/login");
    }
  }, [navigate, stateAuth]);

  const handleLogin = async (e) => {
    e.preventDefault();

    let logFetch = await axios.post(
      `${api}/login/login`,
      { email: email, password: password },
      {
        headers: {
          "Access-Control-Allow-Origin": frontEnd,
        },
        withCredentials: true,
      }
    );

    let log = await logFetch.data;
    if (log.access_token) {
      dispatchAuth({ type: "token", payload: log.access_token });
      navigate("/admin-portal/dashboard-summary");
    } else {
      setAlertMessage(log.message);
      setTimeout(function () {
        setAlertMessage("");
      }, 3000);
    }
  };
  return (
    <>
      <div className={classes.login_background}></div>
      <div className={classes.login_container}>
        <div>
          <h4>ADMIN PORTAL</h4>
          <p>
            <span style={{ color: "darkblue" }}>BIZDATA</span>
            <span style={{ color: "var(--home-light)" }}>INSIGHTS</span>
          </p>
        </div>

        <p className={classes.alertMessage}>{alertMessage}</p>
        <form onSubmit={(e) => handleLogin(e)}>
          <input
            className={classes.email}
            type="email"
            name="email"
            placeholder="Enter your email"
            required
            id="email"
            onChange={(e) => setEmail(e.target.value)}
          />

          <input
            className={classes.pass}
            type="password"
            name="password"
            placeholder="Password"
            required
            onChange={(e) => setPassword(e.target.value)}
            id="password"
          />
          <button
            className={classes.btn_login}
            type="submit"
            // onClick={() => login()}
          >
            Login
          </button>
        </form>
      </div>
    </>
  );
}
