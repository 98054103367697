import {
  UserDefStorage,
  UserGetStorage,
  UserSetStorage,
} from "../storage/storage";

export const authState = UserDefStorage("auth", { auth: false, token: "" });

export const authReducer = (state, action) => {
  switch (action.type) {
    case "auth":
      UserSetStorage("auth", { auth: true, token: state.token });
      return UserGetStorage("auth");
    case "token":
      UserSetStorage("auth", { auth: true, token: action.payload });
      return UserGetStorage("auth");
    case "remove":
      UserSetStorage("auth", { auth: false, token: "" });
      return UserGetStorage("auth");
    default:
      return state;
  }
};
