import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { AdminSettings } from "../components/AdminSettings/AdminSettings";
import Layout from "../components/Layout/Layout";
import { Auth, UserInfo } from "../contexts/allContext";
import { api } from "../utils/api";

export default function AdminSettingsPage() {
  const { stateAuth, dispatchAuth } = useContext(Auth);
  const { stateUser, dispatchUser } = useContext(UserInfo);
  const token = stateAuth.token;
  const loggedUserInfo = stateUser.info;

  const [admin, setAdmin] = useState("");
  const [superAdmin, setSuperAdmin] = useState("");
  const [operator, setOperator] = useState("");

  const [activeModel, setActiveModel] = useState(false);
  const [activeDeleteModel, setActiveDeleteModel] = useState(false);

  const navigate = useNavigate();

  const getInfo = async () => {
    const only_admin = await axios.get(`${api}/Admin/all_admin`);
    const admin = await only_admin.data;
    setAdmin(admin);

    const data_entry_operator = await axios.get(
      `${api}/DataOperator/operator/get_all_operator`
    );
    const operator = await data_entry_operator.data;
    setOperator(operator);

    const super_admin = await axios.get(
      `${api}/SuperAdmin/admin/get_add_superadmin`
    );
    const superAdmin = await super_admin.data;
    setSuperAdmin(superAdmin);
  };

  useEffect(() => {
    getInfo();
  }, [navigate]);

  const handleModel = () => {
    setActiveModel(!activeModel);
    getInfo();
  };

  const handleDeleteModel = () => {
    setActiveDeleteModel(!activeDeleteModel);
    getInfo();
  };
  return (
    <Layout>
      <AdminSettings
        loggedUserInfo={loggedUserInfo}
        admin={admin}
        superAdmin={superAdmin}
        operator={operator}
        activeModel={activeModel}
        setActiveModel={setActiveModel}
        activeDeleteModel={activeDeleteModel}
        setActiveDeleteModel={setActiveDeleteModel}
        handleModel={handleModel}
        handleDeleteModel={handleDeleteModel}
      />
    </Layout>
  );
}
