import { Fragment, useState } from "react";
import classes from "./Navbar.module.css";
import menu from "../../../asset/images/nav/menu.png";
import logout_icon from "../../../asset/images/logout.png";
import admin_icon from "../../../asset/images/admin_logo/admin_icon.png";
import shzzd_icon from "../../../asset/images/admin_logo/Shazzad.jpg";
import mustafa_icon from "../../../asset/images/admin_logo/Mustafa.jpg";
import arif_icon from "../../../asset/images/admin_logo/Arif.jpg";
import fahim_icon from "../../../asset/images/admin_logo/Fahim.jpg";
import kaniz_icon from "../../../asset/images/admin_logo/Kaniz.jpg";
import dashbaords_icon from "../../../asset/images/s11.png";
import subsriberList_icon from "../../../asset/images/s22.png";
import dataEntry_icon from "../../../asset/images/s33.png";
import databaseSettings_icon from "../../../asset/images/s77.png";
import adminSettings_icon from "../../../asset/images/s55.png";
import { useNavigate } from "react-router";

export function Navbar(props) {
  const { adminInfo, logout } = props;
  const [active, setActive] = useState(false);
  const [dropActive, setDropActive] = useState(false);
  const [activeProfile, setActiveProfile] = useState(false);
  const navigate = useNavigate();

  return (
    <>
      <div className={classes.nav}>
        <div>
          <div className={classes.dropDownMenuClick}>
            <button type="button" onClick={() => setActive(!active)}>
              <img src={menu} alt="" />
            </button>
            {active && (
              <div
                className={classes.overlay}
                onClick={(e) => setActive(false)}
              ></div>
            )}
            <div
              className={
                active
                  ? classes.dropDownContentMenuClick
                  : classes.dropDownContentMenuInactive
              }
            >
              <button
                onClick={(e) => {
                  navigate("/admin-portal/dashboard-summary");
                  setActive(false);
                }}
              >
                <img src={dashbaords_icon} alt="" />
                Dashboard Summary
              </button>
              <button
                onClick={(e) => {
                  navigate("/admin-portal/subscriber-list");
                  setActive(false);
                }}
              >
                <img src={subsriberList_icon} alt="" />
                Subscriber List
              </button>
              <button onClick={(e) => setDropActive(!dropActive)}>
                <img src={dataEntry_icon} alt="" />
                Data Entry Panel <span style={{ float: "right" }}>▾</span>
              </button>
              <div
                className={
                  dropActive ? `${classes.btnA} ${classes.open}` : classes.btnA
                }
              >
                <button
                  onClick={(e) => {
                    navigate("/admin-portal/data-entry-panel");
                    setActive(false);
                  }}
                >
                  Data Terminal
                </button>
                <button
                  onClick={(e) => {
                    navigate("/admin-portal/data-entry-panel/company-database");
                    setActive(false);
                  }}
                >
                  Company Database
                </button>
              </div>
              {/* <div className={classes.dropRightMenu}>
                <button type="button">
                  Data Entry Panel <span id={classes.downrightSymbol}>›</span>
                </button>
                <div
                  onClick={(e) => setActive(!dropActive)}
                  className={classes.dropRightContentMenu}
                >
                  <button navigate="/admin-portal/data-entry-panel">
                    Data Terminal
                  </button>
                  <button navigate="/admin-portal/data-entry-panel/company-database">
                    Company Database
                  </button>
                </div>
              </div> */}

              <button
                onClick={(e) => {
                  navigate("/admin-portal/database-settings");
                  setActive(false);
                }}
              >
                <img src={databaseSettings_icon} alt="" />
                Database Settings
              </button>
              <button
                onClick={(e) => {
                  navigate("/admin-portal/admin-settings");
                  setActive(false);
                }}
              >
                <img src={adminSettings_icon} alt="" />
                Admin settings
              </button>

              <button
                type="button"
                className={classes.logoutOnMenu}
                onClick={logout}
              >
                <img src={logout_icon} alt="" />
                Logout
              </button>
            </div>
          </div>
          <div>
            <p>Admin Portal</p>
            <p>
              <span style={{ color: "darkblue" }}>By BIZDATA</span>
              <span style={{ color: "var(--home-light)" }}>INSIGHTS</span>
            </p>
          </div>
        </div>
        <div className={classes.dropDownProfileClick}>
          <button
            type="button"
            onClick={() => setActiveProfile(!activeProfile)}
          >
            {adminInfo.email === "shazzadurrahman787@gmail.com" ? (
              <img src={shzzd_icon} alt="" />
            ) : adminInfo.email === "mmh@bizdatainsights.com" ? (
              <img src={mustafa_icon} alt="" />
            ) : adminInfo.email === "arifuzzaman.badhon1@gmail.com" ? (
              <img src={arif_icon} alt="" />
            ) : adminInfo.email === "ahmedfahim1298@gmail.com" ? (
              <img src={fahim_icon} alt="" />
            ) : adminInfo.email === "kanizfatema2262@gmail.com" ? (
              <img src={kaniz_icon} alt="" />
            ) : (
              <img src={admin_icon} alt="" />
            )}

            <div>
              <p>{adminInfo.name}</p>
              <p>{adminInfo.role}</p>
            </div>
          </button>
          {activeProfile && (
            <div
              className={classes.overlay}
              onClick={(e) => setActiveProfile(false)}
            ></div>
          )}
          <div
            className={
              activeProfile
                ? classes.dropDownContentProfileClick
                : classes.dropDownContentProfileInactive
            }
          >
            <button
              type="button"
              className={classes.logoutOnMenu}
              onClick={logout}
            >
              <img src={logout_icon} alt="" />
              Logout
            </button>
          </div>

          {/* <button type="button" className={classes.logout} onClick={logout}>
            <img src={logout_icon} alt="" />
            Logout
          </button> */}
        </div>
      </div>
    </>
  );
}
